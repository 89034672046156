import axios from 'axios';

export const addressApi = axios.create({
  baseURL: 'https://viacep.com.br',
});

interface IAddressAPIResponse {
  cep: string;
  logradouro: string;
  bairro: string;
  complemento: string;
  localidade: string;
  ibge: string;
  uf: string;
  erro?: boolean;
}

interface Address {
  cep: string;
  street: string;
  neighborhood: string;
  city: string;
  state: string;
  complement?: string;
  number: string;
}

const mapResponseToAddress = (response: IAddressAPIResponse): Address => {
  return {
    cep: response.cep || '',
    street: response.logradouro || '',
    neighborhood: response.bairro || '',
    complement: response.complemento || undefined,
    city: response.localidade || '',
    number: '',
    state: response.uf || '',
  };
};

export const getAddressInformation = async (cep: string): Promise<Address> => {
  const response = await addressApi.get<IAddressAPIResponse>(
    `/ws/${cep.replace(/-/g, '')}/json`,
  );

  if (response?.data?.erro) {
    throw new Error('CEP não encontrado!');
  }

  return mapResponseToAddress(response.data);
};
