import Select from 'react-select';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: #fbfbfb;
`;

export const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2em;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
`;

export const HeaderContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  margin-top: 2rem;

  font-size: 24px;
  font-weight: 600;
  color: var(--black);
`;

export const ContainerBuscas = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  margin-top: 1.25rem;

  @media screen and (max-width: 1100px) {
    justify-content: center;

    gap: 1rem;
  }
`;

export const SearchContainer = styled.form`
  width: 100%;
  max-width: 20rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border: #dcdcdc 1px solid;
  border-radius: 5px;
  background: #fff;

  padding: 0 0.5rem;
`;

export const SearchInput = styled.input`
  border: none;
  height: 40px;
  width: 100%;
  border-radius: 5px;
`;

export const SearchButton = styled.button`
  border: none;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #dcdcdc;
`;

export const ContainerTables = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Tr = styled.tr``;

export const Th = styled.th`
  text-align: start;
  position: sticky;
  top: 0;

  background: #fcfcfc;

  @media screen and (max-width: 850px) {
    font-size: 0.7rem;
  }

  &:last-child {
    background: transparent;
    width: 5rem;
  }
`;

export const Td = styled.td`
  padding: 0.25rem 0px;
  color: #707070;
  border-bottom: 1px solid #dcdcdc;

  td:first-child {
    font-weight: bold;
    color: #1a1a1a;
  }

  &:last-child {
    width: 5rem;
  }

  @media screen and (max-width: 850px) {
    font-size: 0.8rem;
  }
`;

export const EmptyTableContent = styled.div`
  width: 100%;
  flex: 1;
  text-align: center;
  padding: 1rem;
`;

export const JobLink = styled.a`
  color: #707070;
  text-decoration: none;
  font-size: inherit;
  cursor: pointer;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ButtonRemove = styled.button`
  margin-left: 1.5rem;

  background-color: #fff;
  border: none;
  display: flex;
  padding: 5px;
  transition: 300ms;

  :hover {
    transition: 300ms;
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.1);
  }
`;

export const ContainerValues = styled.div`
  margin-top: 30px;

  overflow-y: auto;

  padding-right: 1rem;

  &::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--pink);
    border-radius: 10px;
    height: 120px;
  }
`;

export const DeleteCandidates = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

export const Message = styled.p`
  font-weight: bold;
  font-size: 1.1rem;
`;

export const Pages = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: end;
`;

export const customStyles = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,

    color: state.isSelected ? 'white' : 'black',
    background: state.isSelected ? '#FD1C50D1' : 'white',

    ':hover': {
      backgroundColor: '#fd1c5033',
    },
  }),
};

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const SelectBasic = styled(Select)`
  min-width: 20rem;
  width: max-content;
  font-family: 'Noto Sans', sans-serif;
  font-size: 0.875rem;

  .react-select__menu-list {
    font-size: 0.875rem;

    &::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      background: #e9e9e9;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--pink);
      border-radius: 10px;
      height: 120px;
    }
  }

  .react-select__control {
    min-height: 2.625rem;

    padding-left: 0.25rem;

    border-radius: 5px;

    color: var(--lotion);
    border: 1px solid #dcdcdc;
    box-shadow: none;
  }

  .react-select,
  .react-select__control--is-focused.react-select__control--menu-is-open {
    border-color: var(--pink);
    box-shadow: var(--pink);
  }
`;
