import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import LogoImg from '../../assets/Cadastro/logo.svg';
import { MaskedInput } from '../../components/MaskedInput/MaskedInput';
import api from '../../services/api';
import { Paginated } from '../../types/Paginated';
import { ISystemInfo } from '../../types/SystemInfo';
import { maskCnpj, maskPhone } from '../../utils/masks';
import { messageError, messageSuccess } from '../../utils/message';
import { SignupForm, SignupSchema } from '../../validators/Signup';
import {
  SubmitButton,
  CheckboxContainer,
  Container,
  Content,
  Form,
  FormTitle,
  Imagem,
  ImageText,
  ImageTextWrapper,
  ImageTitle,
  Input,
  Logo,
  FormRow,
  FormFields,
  LoginLinkWrapper,
  LoginLink,
  ErrorMessage,
  Wrapper,
} from './_signup';

export const Signup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupForm>({
    resolver: yupResolver(SignupSchema),
  });

  const navigate = useNavigate();

  const [companyText, setCompanyText] = useState('');
  const [companyTitle, setCompanyTitle] = useState('');

  const [termsAccepted, setTermsAccepted] = useState(false);

  const getSystemInfos = async () => {
    try {
      const { data } = await api.get<Paginated<ISystemInfo>>('/system-info');

      const apiCompanyTitle = data.results.find(
        systemInfo => systemInfo.name === 'company_title',
      );

      const apiCompanyText = data.results.find(
        systemInfo => systemInfo.name === 'company_text',
      );

      setCompanyTitle(apiCompanyTitle?.value || '');
      setCompanyText(apiCompanyText?.value || '');
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const onSubmit: SubmitHandler<SignupForm> = async form => {
    if (!termsAccepted) {
      messageError('Você precisa aceitar os termos de uso');
      return;
    }

    const formData = new FormData();

    const { company, email, password, password_confirm } = form;

    formData.append('email', email);
    formData.append('password', password);
    formData.append('password_confirm', password_confirm);
    formData.append('company[name]', company.name);
    formData.append('company[cnpj]', company.cnpj);
    formData.append('company[phone]', company.phone);
    formData.append('role', 'company');
    formData.append('terms_accepted', 'true');

    try {
      await api.post('/user', formData);

      messageSuccess('Cadastro realizado com sucesso!');
      navigate('/');
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getSystemInfos();
  }, []);

  return (
    <Container>
      <Logo>
        <img src={LogoImg} alt="Logo empresa" />
      </Logo>

      <Imagem>
        <ImageTextWrapper>
          <ImageTitle>{companyTitle}</ImageTitle>
          <ImageText>{companyText}</ImageText>
        </ImageTextWrapper>
      </Imagem>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormTitle>Cadastre sua empresa e anuncie suas vagas!</FormTitle>
          <FormFields>
            <Input placeholder="Razão social" {...register('company.name')} />
            {errors?.company?.name?.message && (
              <ErrorMessage>{errors.company.name.message}</ErrorMessage>
            )}
            <Input placeholder="E-mail" {...register('email')} />
            {errors?.email?.message && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
            <MaskedInput
              placeholder="CNPJ"
              maskFunction={maskCnpj}
              style={{ marginTop: '0.25rem' }}
              {...register('company.cnpj', {
                setValueAs: value => value.replace(/\D/g, ''),
              })}
            />
            {errors?.company?.cnpj?.message && (
              <ErrorMessage>{errors.company.cnpj.message}</ErrorMessage>
            )}
            <MaskedInput
              placeholder="Telefone da empresa"
              maskFunction={maskPhone}
              maxLength={15}
              style={{ marginTop: '0.25rem' }}
              {...register('company.phone', {
                setValueAs: value => value.replace(/\D/g, ''),
              })}
            />
            {errors?.company?.phone?.message && (
              <ErrorMessage>{errors.company.phone.message}</ErrorMessage>
            )}
            <FormRow>
              <Wrapper>
                <Input
                  placeholder="Senha"
                  type="password"
                  maxLength={20}
                  {...register('password')}
                />
                {errors?.password?.message && (
                  <ErrorMessage>{errors.password.message}</ErrorMessage>
                )}
              </Wrapper>
              <Wrapper>
                <Input
                  placeholder="Confirmar senha"
                  type="password"
                  maxLength={20}
                  {...register('password_confirm')}
                />
                {errors?.password_confirm?.message && (
                  <ErrorMessage>{errors.password_confirm.message}</ErrorMessage>
                )}
              </Wrapper>
            </FormRow>
            <CheckboxContainer>
              <Checkbox
                inputProps={{ 'aria-label': 'Checkbox demo' }}
                color="default"
                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 }, padding: 0 }}
                checked={termsAccepted}
                onChange={e => setTermsAccepted(e.target.checked)}
              />
              <p>
                Li e aceito os <span>termos de uso </span> e{' '}
                <span>política de privacidade.</span>
              </p>
            </CheckboxContainer>
            <SubmitButton>Cadastrar</SubmitButton>
          </FormFields>
          <LoginLinkWrapper>
            Já tem conta? <LoginLink to="/">Faça o login</LoginLink>
          </LoginLinkWrapper>
        </Form>
      </Content>
    </Container>
  );
};
