/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-shadow */
import React, { createContext, ReactNode, useContext, useReducer } from 'react';

export enum currentActions {
  setCurrentStepForm,
  setCurrentStepRegistration,
}
type State = {
  currentStepForm: number;
  currentStepRegistration: number;
};
type Action = {
  type: currentActions;
  payload: any;
};
type ContextType = {
  state: State;
  dispatch: (action: Action) => void;
};
type currentStepProps = {
  children: ReactNode;
};
const initialData: State = {
  currentStepForm: 0,
  currentStepRegistration: 0,
};

const CurrentContext = createContext<ContextType | undefined>(undefined);

const currentReducer = (state: State, action: Action) => {
  switch (action.type) {
    case currentActions.setCurrentStepForm:
      return { ...state, currentStepForm: action.payload };
    case currentActions.setCurrentStepRegistration:
      return { ...state, currentStepRegistration: action.payload };
    default:
      return state;
  }
};

export const CurrentProvider = ({ children }: currentStepProps) => {
  const [state, dispatch] = useReducer(currentReducer, initialData);
  const value = { state, dispatch };
  return (
    <CurrentContext.Provider value={value}>{children}</CurrentContext.Provider>
  );
};

export const useChangePage = () => {
  const context = useContext(CurrentContext);
  if (context === undefined)
    throw new Error('useChangePage precisa ser usado dentro do FormProvider');
  return context;
};
