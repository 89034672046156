// React
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// React Icons
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { AiFillEye } from 'react-icons/ai';
import { BiSearchAlt2 } from 'react-icons/bi';
import { FaArrowLeft, FaFileDownload, FaFilter } from 'react-icons/fa';

// Import Component
import { Navbar } from '../../../../../components/Navbar/Navbar';
import { Profile } from '../../../../../components/Profile/Profile';
import { InformacaoCandidato } from './InformacaoCandidato/InformacaoCandidato';

// Style
import FilterDropdown, {
  FilterDropdownValues,
} from '../../../../../components/FilterDropdown/FilterDropdown';
import { useAuth } from '../../../../../hooks/useAuth';
import useDebounceWithCallback from '../../../../../hooks/useDebounceWithCallback';
import api from '../../../../../services/api';
import { IAplication } from '../../../../../types/Aplication';
import { IJob } from '../../../../../types/Job';
import { ModeratorPermissions } from '../../../../../types/ModeratorPermissions';
import { maskPhone } from '../../../../../utils/masks';
import { messageError, messageSuccess } from '../../../../../utils/message';
import LinkHeader from '../../LinkHeader/LinkHeader';
import { ModalRefused } from './ModalRafused/ModalRefused';
import {
  ButtonAprovado,
  ButtonBack,
  ButtonFilter,
  ButtonRemove,
  ButtonReprovado,
  ButtonSearch,
  Container,
  ContainerBuscas,
  ContainerFilterMain,
  ContainerFlex,
  ContainerResult,
  ContainerSearch,
  ContainerTables,
  DeleteCandidates,
  DownloadLink,
  Header,
  MainArea,
  Message,
  Pages,
  SearchBar,
  SectionRight,
  SectionTitle,
  Td,
  Text,
  Th,
  Tr,
  Values,
} from './_visualizarselecionados';

const defaultFilters: FilterDropdownValues = {
  degrees: [],
  experience: [],
};

const limit = 10;

export const VisualizarSelecionados = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;

  const { user } = useAuth();

  const [openFilter, setOpenFilter] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [search, setSearch] = useState('');
  const [candidates, setCandidates] = useState<IAplication[]>([]);
  const [job, setJob] = useState<IJob>();
  const [candidate, setCandidate] = useState<IAplication>({} as IAplication);
  const [idDelete, setIdDelete] = useState('');
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(1);

  const [dropdownFilters, setDropdownFilters] =
    useState<FilterDropdownValues>(defaultFilters);

  const debouncedSearch = useDebounceWithCallback(
    search,
    () => setPage(1),
    600,
  );

  const handleOpenModal = (val: IAplication) => {
    setOpenModal(!openModal);
    setCandidate(val);
  };

  const handleNavigateBack = () => {
    navigate('/Selecionados');
  };

  const handleOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  const getCandidates = async () => {
    try {
      const response = await api.get(`/job/application/jobApplication/${id}`, {
        params: {
          search,
          'status[0]': 3,
          'status[1]': 4,
          'status[2]': 2,
          limit,
          page,
          'states[]': dropdownFilters.state || undefined,
          'cities[]': dropdownFilters.city || undefined,
          experience:
            dropdownFilters.experience.length > 0
              ? dropdownFilters.experience
              : undefined,
          degrees:
            dropdownFilters.degrees.length > 0
              ? dropdownFilters.degrees
              : undefined,
          area: dropdownFilters.area || undefined,
        },
      });
      setCandidates(response.data.results);
      setJob(response.data.job);
      setTotalPages(Math.ceil(response.data.total / limit));

      if (search) {
        setPage(1);
        setCandidates(response.data.results);
        setJob(response.data.job);
      }
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getCandidates();
  }, [page, debouncedSearch, dropdownFilters]);

  const handleDropdownFilterChange = (filters: FilterDropdownValues) => {
    setDropdownFilters(filters);
    setPage(1);
  };

  const aproveCandidate = async (idAprove: string) => {
    try {
      await api.put(`/job/application/${idAprove}`, {
        status_id: 4,
      });
      getCandidates();
      messageSuccess('Candidato aprovado');
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const handleOpenModalRefused = (val: string) => {
    setIdDelete(val);
    setOpenModalDelete(!openModalDelete);
  };

  const canManageCandidates =
    user.role === 2 ||
    !!user?.moderator?.permission.includes(ModeratorPermissions.CANDIDATES);

  const renderButtons = (statusId: number, canId: string) => {
    if (statusId === 4) {
      return (
        <ButtonAprovado
          active={statusId}
          onClick={() => aproveCandidate(canId)}
          disabled
        >
          Aprovado
        </ButtonAprovado>
      );
    }

    if (statusId === 2) {
      return (
        <ButtonReprovado
          active={statusId}
          onClick={() => handleOpenModalRefused(canId)}
          disabled
        >
          Reprovado
        </ButtonReprovado>
      );
    }

    return (
      <>
        <ButtonAprovado
          active={statusId}
          onClick={() => aproveCandidate(canId)}
        >
          Aprovar
        </ButtonAprovado>
        <ButtonReprovado
          active={statusId}
          onClick={() => handleOpenModalRefused(canId)}
        >
          Reprovar
        </ButtonReprovado>
      </>
    );
  };

  return (
    <Container>
      <Navbar />
      <MainArea>
        <Header>
          <Profile />
        </Header>
        <LinkHeader />

        <ContainerBuscas>
          <ContainerSearch>
            <ButtonSearch>
              <BiSearchAlt2 fontSize={25} color="#DCDCDC" />
            </ButtonSearch>
            <SearchBar
              type="text"
              placeholder="Buscar"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />

            <ContainerFilterMain>
              <ButtonFilter onClick={handleOpenFilter}>
                <FaFilter />
                FILTRAR
              </ButtonFilter>
              {openFilter && (
                <FilterDropdown
                  filters={dropdownFilters}
                  onChangeFilters={handleDropdownFilterChange}
                />
              )}
            </ContainerFilterMain>
          </ContainerSearch>
        </ContainerBuscas>
        <ContainerResult>
          <SectionTitle>
            <ContainerFlex>
              <ButtonBack onClick={handleNavigateBack}>
                <FaArrowLeft
                  fontSize={25}
                  style={{
                    backgroundColor: '#fbfbfb',
                  }}
                />
              </ButtonBack>

              <Text>#{job?.id || ''}</Text>
              <Text>{job?.title || ''}</Text>
            </ContainerFlex>
          </SectionTitle>

          <SectionRight>
            <Text>
              <Values>{candidates.length}</Values>Candidaturas
            </Text>
          </SectionRight>
        </ContainerResult>
        {candidates.length === 0 ? (
          <DeleteCandidates>
            <Message>Nenhum candidato selecionado</Message>
          </DeleteCandidates>
        ) : (
          <div>
            <ContainerTables>
              <Tr>
                <Th>ID</Th>
                <Th>Nome</Th>
                <Th>E-mail</Th>
                <Th>Última Experiência</Th>
                <Th>Contato</Th>
              </Tr>

              {candidates.map(application => (
                <Tr>
                  <Td>{`#${application.id}`}</Td>
                  <Td>{application.person.name}</Td>
                  <Td>{application.person.user.email}</Td>
                  <Td>
                    {!application.person.job_history?.[0]?.area
                      ? 'Não informado'
                      : application.person.job_history?.[0]?.area}
                  </Td>
                  <Td>
                    {maskPhone(application.person.phone) || 'Não informado'}
                  </Td>

                  <Td>
                    {canManageCandidates &&
                      renderButtons(application.status_id, application.id)}
                  </Td>

                  <Td>
                    <ButtonRemove>
                      <AiFillEye
                        fontSize={26}
                        color="#707070"
                        onClick={() => handleOpenModal(application)}
                      />
                    </ButtonRemove>
                  </Td>
                  <Td>
                    <DownloadLink
                      href={application.person?.resume_url}
                      target="_blank"
                      download
                    >
                      <FaFileDownload fontSize={24} color="#707070" />
                    </DownloadLink>
                  </Td>
                </Tr>
              ))}
              {openModal && (
                <InformacaoCandidato
                  setOpenModal={setOpenModal}
                  candidate={candidate}
                />
              )}
              <ModalRefused
                openModalDelete={openModalDelete}
                setOpenModalDelete={setOpenModalDelete}
                idDelete={idDelete}
                getCandidates={getCandidates}
              />
            </ContainerTables>
          </div>
        )}
        <Pages>
          <Stack spacing={1}>
            <Pagination
              page={page}
              count={totalPages}
              onChange={(e, pag) => {
                setPage(pag);
              }}
              shape="rounded"
              size="small"
            />
          </Stack>
        </Pages>
      </MainArea>
    </Container>
  );
};
