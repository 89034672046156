import { Pagination, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { BiSearchAlt2 } from 'react-icons/bi';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '../../components/Navbar/Navbar';
import { Profile } from '../../components/Profile/Profile';
import { IJob } from '../../types/Job';

import useDebounce from '../../hooks/useDebounce';
import api from '../../services/api';
import { Paginated } from '../../types/Paginated';
import handleError from '../../utils/message';
import {
  ButtonRemove,
  Container,
  ContainerBuscas,
  ContainerTables,
  ContainerValues,
  DeleteCandidates,
  Header,
  HeaderContainer,
  MainArea,
  Message,
  Pages,
  SearchButton,
  SearchContainer,
  SearchInput,
  SelectBasic,
  Td,
  Th,
  Title,
  Tr,
  customStyles,
} from './styles';

interface IJobType {
  id: string;
  name: string;
}

interface Option {
  value: string | number;
  label: string;
}

const limit = 12;

const Similares = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [jobs, setJobs] = useState<IJob[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [jobTypeOptions, setJobTypeOptions] = useState<Option[]>([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState<Option[]>([]);

  const debouncedSearch = useDebounce(search, 600);

  const getJobTypes = async () => {
    try {
      const { data } = await api.get<Paginated<IJobType>>('/job/option/type', {
        params: {
          limit: 0,
        },
      });

      setJobTypeOptions(
        data.results.map(jobType => ({
          label: jobType.name,
          value: jobType.id,
        })),
      );
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getJobTypes();
  }, []);

  const getJobs = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get<Paginated<IJob>>('/job/jobs', {
        params: {
          page,
          limit,
          search: debouncedSearch || undefined,
          types: selectedJobTypes.length
            ? selectedJobTypes.map(option => option.value)
            : undefined,
        },
      });

      setJobs(data.results);
      setTotalPages(Math.ceil(data.total / limit));
    } catch (error: any) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getJobs();
  }, [page, debouncedSearch, selectedJobTypes]);

  return (
    <Container>
      <Navbar />
      <MainArea>
        <HeaderContainer>
          <Title>Similares</Title>
          <Header>
            <Profile />
          </Header>
        </HeaderContainer>

        <ContainerBuscas>
          <SearchContainer>
            <SearchButton>
              <BiSearchAlt2 fontSize={25} color="#DCDCDC" />
            </SearchButton>
            <SearchInput
              type="text"
              placeholder="Pesquisa por título"
              onChange={e => {
                setSearch(e.target.value);
              }}
            />
          </SearchContainer>
          <SelectBasic
            classNamePrefix="react-select"
            options={jobTypeOptions}
            placeholder="Selecione os cargos"
            noOptionsMessage={() => 'Nenhum cargo encontrado'}
            value={selectedJobTypes}
            styles={customStyles}
            isMulti
            onChange={options => {
              setSelectedJobTypes(options as Option[]);
              if (page > 1) {
                setPage(1);
                setTotalPages(1);
              }
            }}
          />
        </ContainerBuscas>
        {jobs.length === 0 && !isLoading ? (
          <DeleteCandidates>
            <Message>Nenhuma Vaga</Message>
          </DeleteCandidates>
        ) : (
          <ContainerValues>
            <ContainerTables>
              <Tr>
                <Th>ID</Th>
                <Th>Título</Th>
                <Th>Área de atuação</Th>
                <Th />
              </Tr>

              {jobs.map((itens: IJob) => (
                <Tr key={itens.id}>
                  <Td>#{itens.id}</Td>
                  <Td>{itens.title || ''}</Td>
                  <Td>{itens.area?.name || ''}</Td>
                  <Td>
                    <ButtonRemove
                      onClick={() => navigate(`/Similares/${itens.id}`)}
                      title="Adicionar vagas similares"
                    >
                      <MdAdd fontSize={24} color="#707070" />
                    </ButtonRemove>
                  </Td>
                </Tr>
              ))}
            </ContainerTables>
          </ContainerValues>
        )}
        <Pages>
          <Stack spacing={1}>
            <Pagination
              page={page}
              count={totalPages}
              onChange={(e, pag) => {
                setPage(pag);
              }}
              shape="rounded"
              size="small"
            />
          </Stack>
        </Pages>
      </MainArea>
    </Container>
  );
};

export default Similares;
