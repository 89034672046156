/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BiSearchAlt2 } from 'react-icons/bi';
import {
  FaArrowLeft,
  FaFileDownload,
  FaFilter,
  FaTrashAlt,
} from 'react-icons/fa';
import { FiMove } from 'react-icons/fi';
import { GrFormClose } from 'react-icons/gr';

import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

// API
import { AiFillEye } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { messageError, messageSuccess } from '../../../../../../utils/message';

// Components
import { Navbar } from '../../../../../../components/Navbar/Navbar';
import { Profile } from '../../../../../../components/Profile/Profile';
import { IAplication } from '../../../../../../types/Aplication';

import ConfirmModal from '../../../../../../components/ConfirmModal/ConfirmModal';
import FilterDropdown, {
  FilterDropdownValues,
} from '../../../../../../components/FilterDropdown/FilterDropdown';
import useDebounceWithCallback from '../../../../../../hooks/useDebounceWithCallback';
import api from '../../../../../../services/api';
import { IJob } from '../../../../../../types/Job';
import { maskPhone } from '../../../../../../utils/masks';
import LinkHeader from '../../../LinkHeader/LinkHeader';
import { InformacaoCandidato } from '../../../Selecionados/components/InformacaoCandidato/InformacaoCandidato';
import {
  ButtonBack,
  ButtonFilter,
  ButtonFilters,
  ButtonFunction,
  ButtonRemove,
  ButtonSearch,
  Container,
  ContainerBuscas,
  ContainerFilterMain,
  ContainerFlex,
  ContainerMover,
  ContainerProps,
  ContainerResult,
  ContainerSearch,
  ContainerSelect,
  DeleteButton,
  DeleteCandidates,
  DisplayFlex,
  FilterText,
  Header,
  MainArea,
  Message,
  Pages,
  SearchBar,
  SectionRight,
  SectionTitle,
  Table,
  TableContainer,
  Td,
  Text,
  Th,
  Tr,
} from './_visualizarvagas';
import { useAuth } from '../../../../../../hooks/useAuth';
import { ModeratorPermissions } from '../../../../../../types/ModeratorPermissions';

const limit = 10;

const defaultConfirmModal = {
  isOpen: false,
  onConfirm: () => {},
  title: '',
  onClose: () => {},
  onCancel: () => {},
  confirmText: '',
  cancelText: '',
};

const defaultFilters: FilterDropdownValues = {
  degrees: [],
  experience: [],
};

export const VisualizarVagas = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();

  const [search, setSearch] = useState('');
  const [job, setJob] = useState<IJob>();
  const [candidates, setCandidates] = useState<IAplication[]>([]);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [handleConfirmModal, setHandleConfirmModal] =
    useState(defaultConfirmModal);

  const [selectedCandidate, setSelectedCandidate] = useState<IAplication>();
  const [showCandidateModal, setShowCandidateModal] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(1);

  const [dropdownFilters, setDropdownFilters] =
    useState<FilterDropdownValues>(defaultFilters);

  const debouncedSearch = useDebounceWithCallback(
    search,
    () => setPage(1),
    600,
  );

  useEffect(() => {
    getCandidates();
  }, [page, debouncedSearch, dropdownFilters]);

  const getCandidates = async () => {
    try {
      const response = await api.get(`/job/application/jobApplication/${id}`, {
        params: {
          search,
          'status[0]': 1,
          limit,
          page,
          'states[]': dropdownFilters.state || undefined,
          'cities[]': dropdownFilters.city || undefined,
          experience:
            dropdownFilters.experience.length > 0
              ? dropdownFilters.experience
              : undefined,
          degrees:
            dropdownFilters.degrees.length > 0
              ? dropdownFilters.degrees
              : undefined,
          area: dropdownFilters.area || undefined,
        },
      });

      setTotalPages(Math.ceil(response.data.total / limit));
      setCandidates(response.data.results);
      setJob(response.data.job);
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const handleDropdownFilterChange = (filters: FilterDropdownValues) => {
    setDropdownFilters(filters);
    setPage(1);
  };

  const toggleSelected = (vacancyId: string) => {
    setSelectedIds(prev => {
      if (prev.includes(vacancyId)) {
        return prev.filter(item => item !== vacancyId);
      }

      return [...prev, vacancyId];
    });
  };

  const deleteCandidates = async () => {
    try {
      await api.delete('/job/application', {
        data: {
          ids: selectedIds,
        },
      });
      setSelectedIds([]);
      getCandidates();
      toast.success('Candidatos excluídos com sucesso!');
      handleCloseConfirmModal();
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const moveCandidates = async () => {
    try {
      await api.put(`/job/application/many`, {
        ids: selectedIds,
        status_id: 3,
      });
      setSelectedIds([]);
      getCandidates();
      messageSuccess('Candidatos movidos com sucesso!');
      handleCloseConfirmModal();
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const handleCloseConfirmModal = () => {
    setHandleConfirmModal(defaultConfirmModal);
  };

  const handleSelectCandidates = () => {
    if (!selectedIds.length) {
      messageError('Selecione pelo menos uma candidatura');
      return;
    }

    setHandleConfirmModal(prev => ({
      ...prev,
      title: 'Tem certeza que deseja selecionar os candidatos?',
      confirmText: 'Selecionar',
      isOpen: true,
      cancelText: 'Cancelar',
      onConfirm: moveCandidates,
      onCancel: handleCloseConfirmModal,
    }));
  };

  const handleDeleteCandidates = () => {
    if (!selectedIds.length) {
      messageError('Selecione pelo menos uma candidatura');
      return;
    }

    setHandleConfirmModal(prev => ({
      ...prev,
      title: 'Tem certeza que deseja excluir os candidatos?',
      confirmText: 'Excluir',
      isOpen: true,
      cancelText: 'Cancelar',
      onConfirm: deleteCandidates,
      onCancel: handleCloseConfirmModal,
    }));
  };

  const canManageCandidates =
    user.role === 2 ||
    !!user?.moderator?.permission.includes(ModeratorPermissions.CANDIDATES);

  return (
    <Container>
      <Navbar />
      <MainArea>
        <Header>
          <Profile />
        </Header>
        <LinkHeader />

        <DisplayFlex>
          <ContainerBuscas>
            <ContainerSearch>
              <ButtonSearch>
                <BiSearchAlt2 fontSize={25} color="#DCDCDC" />
              </ButtonSearch>
              <SearchBar
                type="text"
                placeholder="Buscar"
                value={search}
                onChange={e => {
                  setSearch(e.target.value);
                }}
              />

              <ContainerFilterMain>
                <ButtonFilter onClick={() => setOpenFilter(prev => !prev)}>
                  <FaFilter />
                  FILTRAR
                </ButtonFilter>
                {openFilter && (
                  <FilterDropdown
                    onChangeFilters={handleDropdownFilterChange}
                    filters={dropdownFilters}
                  />
                )}
              </ContainerFilterMain>
            </ContainerSearch>

            {canManageCandidates && (
              <ContainerSelect>
                <ContainerMover>
                  <ButtonFunction onClick={handleSelectCandidates}>
                    <FiMove fontSize={20} color="#A8A8A8" />
                    Mover para selecionados
                  </ButtonFunction>
                </ContainerMover>
                <ButtonFunction onClick={handleDeleteCandidates}>
                  <FaTrashAlt fontSize={20} color="#A8A8A8" />
                  Excluir
                </ButtonFunction>
              </ContainerSelect>
            )}
          </ContainerBuscas>
          <ContainerProps>
            {dropdownFilters.state && (
              <ButtonFilters>
                <FilterText>{dropdownFilters.state}</FilterText>
                <DeleteButton
                  onClick={() =>
                    setDropdownFilters(prev => ({
                      ...prev,
                      state: undefined,
                    }))
                  }
                >
                  <GrFormClose />
                </DeleteButton>
              </ButtonFilters>
            )}

            {dropdownFilters.city && (
              <ButtonFilters>
                <FilterText>{dropdownFilters.city}</FilterText>
                <DeleteButton
                  onClick={() =>
                    setDropdownFilters(prev => ({
                      ...prev,
                      city: undefined,
                    }))
                  }
                >
                  <GrFormClose />
                </DeleteButton>
              </ButtonFilters>
            )}
          </ContainerProps>
        </DisplayFlex>
        <ContainerResult>
          <SectionTitle>
            <ContainerFlex>
              <ButtonBack onClick={() => navigate('/VagasCadastradas')}>
                <FaArrowLeft fontSize={25} />
              </ButtonBack>

              <Text>{`#${job?.id || ''}`}</Text>
            </ContainerFlex>
            <Text>{job?.title || ''}</Text>
          </SectionTitle>

          <SectionRight>
            <Text>
              <span>{candidates.length}</span> Candidaturas
            </Text>
          </SectionRight>
        </ContainerResult>
        {candidates.length === 0 ? (
          <DeleteCandidates>
            <Message>Nenhum candidato nesta vaga</Message>
          </DeleteCandidates>
        ) : (
          <TableContainer>
            <Table>
              <Tr>
                <Th>
                  <Checkbox
                    checked
                    onChange={e => {
                      if (selectedIds.length === candidates.length) {
                        setSelectedIds([]);
                      } else {
                        setSelectedIds(
                          candidates.map(application => application.id),
                        );
                      }
                    }}
                    id="select-all"
                    color="default"
                    size="small"
                    style={{
                      color:
                        selectedIds.length === candidates.length
                          ? '#fd1c50'
                          : '#acacac',
                    }}
                  />
                </Th>
                <Th>ID</Th>
                <Th>Nome</Th>
                <Th>E-mail</Th>
                <Th>Última Experiência</Th>
                <Th>Contato</Th>
                <Th />
                <Th />
              </Tr>

              {candidates.map(application => (
                <Tr key={application.id}>
                  <Td>
                    <Checkbox
                      checked={selectedIds.includes(application.id)}
                      onChange={() => {
                        toggleSelected(application.id);
                      }}
                      color="default"
                      size="small"
                      style={{
                        color: '#fd1c50',
                      }}
                    />
                  </Td>
                  <Td>{`#${application.id}`}</Td>
                  <Td>{application?.person?.name}</Td>
                  <Td>{application?.person?.user?.email}</Td>
                  <Td>
                    {!application.person.job_history?.[0]?.area
                      ? 'Não informado'
                      : application.person.job_history?.[0]?.area}
                  </Td>
                  <Td>
                    {maskPhone(application.person.phone) || 'Não informado'}
                  </Td>
                  <Td>
                    <ButtonRemove as="button">
                      <AiFillEye
                        fontSize={26}
                        color="#707070"
                        onClick={() => {
                          setSelectedCandidate(application);
                          setShowCandidateModal(true);
                        }}
                      />
                    </ButtonRemove>
                  </Td>
                  <Td>
                    <ButtonRemove
                      href={application.person?.resume_url}
                      target="_blank"
                      download
                    >
                      <FaFileDownload fontSize={24} color="#707070" />
                    </ButtonRemove>
                  </Td>
                </Tr>
              ))}
            </Table>
          </TableContainer>
        )}
        <Pages>
          <Stack spacing={1}>
            <Pagination
              page={page}
              count={totalPages}
              onChange={(e, pag) => {
                setPage(pag);
              }}
              shape="rounded"
              size="small"
            />
          </Stack>
        </Pages>
      </MainArea>
      {handleConfirmModal.isOpen && <ConfirmModal {...handleConfirmModal} />}
      {selectedCandidate && showCandidateModal && (
        <InformacaoCandidato
          candidate={selectedCandidate}
          setOpenModal={setShowCandidateModal}
        />
      )}
    </Container>
  );
};
