import styled from 'styled-components';

export const ContainerModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(120, 121, 121, 0.5);
  /* Sobrepor o modal */
  z-index: 9;
`;

export const ModalStyle = styled.div`
  padding: 50px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 9px rgba(0, 0, 0, 0.1);

  height: 80vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--pink);
    border-radius: 10px;
    height: 120px;
  }
`;

export const ContainerInfo = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled.p`
  color: #000;
  font-size: 1.1rem;
`;

export const ButtonClose = styled.button`
  background-color: #fff;
  display: flex;
  align-items: center;
  border: none;
`;

export const SectionValues = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  svg {
    min-width: 1.5625rem;
  }
`;

export const ContainerNamesValues = styled.div``;

export const Value = styled.p`
  color: #000000;
  font-size: 1.1rem;
`;

export const SetValue = styled.p`
  color: #8b8b8b;
  font-size: 0.9rem;
  margin-left: 40px;
`;

export const List = styled.div`
  color: #8b8b8b;
  font-size: 0.9rem;
  margin-left: 40px;

  display: grid;
  flex-wrap: wrap;
  gap: 1rem;

  p {
    width: auto;

    padding: 8px 15px;
    border: 1px solid #fd1c50;

    border-radius: 50px;
  }
`;

export const ContainerFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 380px;
`;

export const TitleSection = styled.p`
  margin: 20px 0 10px 0;
  font-weight: bold;
  font-size: 1.1rem;
`;

export const Skills = styled.p`
  color: #707070;
  font-size: 0.8rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;
