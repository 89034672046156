/* eslint-disable prettier/prettier */
// React
import { Dispatch, SetStateAction } from 'react';

// React icons
import {
  FaBuilding,
  FaGraduationCap,
  FaTransgender,
  FaUserAlt,
  FaUserGraduate,
} from 'react-icons/fa';
import { IoIosClose } from 'react-icons/io';
import { MdLocationOn } from 'react-icons/md';
import { RiSuitcaseFill } from 'react-icons/ri';
import { TbWorld } from 'react-icons/tb';

// Import Component
import React from 'react';
import { IAplication } from '../../../../../../types/Aplication';

// API

import {
  ButtonClose,
  ContainerFlex,
  ContainerInfo,
  ContainerModal,
  ContainerNamesValues,
  List,
  ModalStyle,
  Row,
  SectionValues,
  SetValue,
  Skills,
  Title,
  TitleSection,
  Value,
} from './_informacaocandidato';

interface ValoresModal {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  candidate: IAplication;
}

export const InformacaoCandidato = ({
  setOpenModal,
  candidate,
}: ValoresModal) => {
  function handleCloseModal() {
    setOpenModal(false);
  }

  return (
    <ContainerModal>
      <ModalStyle>
        <ContainerInfo>
          <Title>Informações do Candidato</Title>
          <ButtonClose>
            <IoIosClose
              color="#fd1c50"
              fontSize={30}
              onClick={handleCloseModal}
            />
          </ButtonClose>
        </ContainerInfo>
        <div>
          <SectionValues>
            <ContainerNamesValues>
              <Row>
                <FaUserAlt color="#fd1c50" fontSize={15} />
                <Value>Nome</Value>
              </Row>
              <SetValue>{candidate?.person?.name}</SetValue>
            </ContainerNamesValues>
          </SectionValues>

          <SectionValues>
            <ContainerNamesValues>
              <Row>
                <FaTransgender color="#fd1c50" fontSize={18} />
                <Value>Gênero</Value>
              </Row>

              <SetValue>
                {candidate?.person?.gender?.name || 'Não informado'}
              </SetValue>
            </ContainerNamesValues>
          </SectionValues>

          <SectionValues>

            <ContainerNamesValues>
              <Row>
                <MdLocationOn color="#fd1c50" fontSize={20} />
                <Value>Cidade</Value>
              </Row>

              <SetValue>
                {candidate?.person?.address?.city || 'Não informado'}
              </SetValue>
            </ContainerNamesValues>
          </SectionValues>

          {candidate?.person?.academic?.map(academic => (
            <React.Fragment key={academic?.id}>
              <ContainerFlex>
                <SectionValues>

                  <ContainerNamesValues>
                    <Row>
                      <FaGraduationCap color="#fd1c50" fontSize={16} />
                      <Value>Área de estudo</Value>
                    </Row>
                    <SetValue>
                      {!academic?.area ? 'Não Informado' : academic?.area}
                    </SetValue>
                  </ContainerNamesValues>
                </SectionValues>

                <SectionValues>
                  <ContainerNamesValues>
                    <Row>
                      <FaUserGraduate color="#fd1c50" fontSize={16} />
                      <Value>Grau acadêmico</Value>
                    </Row>
                    <SetValue>
                      {!academic?.degree?.name
                        ? 'Não Informado'
                        : academic?.degree?.name}
                    </SetValue>
                  </ContainerNamesValues>
                </SectionValues>
              </ContainerFlex>
            </React.Fragment>
          ))}
          <SectionValues>

            <ContainerNamesValues>
              <Row>
                <TbWorld color="#fd1c50" fontSize={18} />

                <Value>Idioma</Value>
              </Row>

              <SetValue>
                {candidate?.person?.language_items?.length > 0 ?
                  candidate?.person?.language_items.map((itens: any) => (
                    <p>{itens?.language} - {itens?.level}</p>
                  )) :
                  'Não informado'}
              </SetValue>
            </ContainerNamesValues>
          </SectionValues>

          <TitleSection>Experiências</TitleSection>
          <ContainerFlex>
            <SectionValues>
              <ContainerNamesValues>
                <Row>
                  <RiSuitcaseFill color="#fd1c50" fontSize={18} />
                  <Value>Cargo</Value>
                </Row>
                <SetValue>
                  {!candidate?.person?.job_history?.[0]?.area
                    ? 'Não informado'
                    : candidate?.person?.job_history?.[0]?.area}
                </SetValue>
              </ContainerNamesValues>
            </SectionValues>

            <SectionValues>
              <ContainerNamesValues>
                <Row>
                  <FaBuilding color="#fd1c50" fontSize={16} />
                  <Value>Empresa</Value>
                </Row>
                <SetValue>
                  {!candidate?.person?.job_history?.[0]?.company_name
                    ? 'Não Informado'
                    : candidate?.person?.job_history?.[0]?.company_name}
                </SetValue>
              </ContainerNamesValues>
            </SectionValues>
          </ContainerFlex>
          <TitleSection>Competências</TitleSection>
          <Skills>
            {candidate?.person?.competencies?.length === 0
              ? 'Não Informado'
              : candidate?.person?.competencies.map((itens: any) => (
                <p>{itens}</p>
              ))}
          </Skills>
        </div>
      </ModalStyle>
    </ContainerModal>
  );
};
