import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;

  background: #fbfbfb;
`;

export const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  flex: 1;
`;

export const Header = styled.header`
  display: flex;
  justify-content: end;
  margin-bottom: 0.625rem;
`;

export const Title = styled.div`
  margin-top: 2rem;

  font-size: 1.5rem;
  font-weight: 600;
  color: var(--black);
  white-space: nowrap;
`;

export const FilterSection = styled.section`
  margin-top: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const SearchForm = styled.form`
  width: 100%;
  max-width: 25rem;
`;

export const CreateButton = styled.button`
  border: none;
  background: var(--pink);
  color: white;

  width: 100%;
  max-width: 13.5rem;
  height: 2.5rem;

  font-weight: 500;
  font-size: 1rem;
  white-space: nowrap;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.95);
  }
`;

export const TableWrapper = styled.section`
  margin-top: 1rem;

  width: 100%;

  overflow: auto;

  padding-bottom: 2rem;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeading = styled.th`
  font-size: 1rem;
  font-weight: 600;
  color: #1a1a1a;

  // padding: 0.75rem;

  padding: 0.125rem 0.5rem;
  // border-bottom: 1px solid #cacaca;

  text-align: left;
`;

export const TableData = styled.td`
  font-size: 1rem;
  font-weight: 400;
  color: #1a1a1a;

  padding: 0.5rem 0.5rem;

  border-bottom: 1px solid #dcdcdc;

  text-align: left;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const IconButton = styled.button`
  border: none;

  position: relative;

  color: #707070;
  font-size: 1.375rem;

  background-color: #fff;
  width: 2.125rem;
  height: 2.125rem;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.25rem;
  transition: 0.3s;

  /* &::after {
    content: attr(title);
    position: absolute;
    z-index: 2;
    top: -1.25rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #444654;
    color: #d1d5db;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;

    white-space: nowrap;

    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.1);
  } */

  &:hover {
    background: #eeeeee;

    /* ::after {
      opacity: 1;
      visibility: visible;
    } */
  }
`;

export const PaginationWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
`;

export const Pages = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: end;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10rem;
`;
