/* eslint-disable react/no-unstable-nested-components */
import { Navigate, Outlet, OutletProps } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

interface Props extends OutletProps {
  roles?: number[];
}

const PrivateRoute = ({ roles = [], ...props }: Props) => {
  const { isAuthenticated, user } = useAuth();

  const canUserAccess =
    roles.length > 0 ? roles.includes(user?.role) : isAuthenticated();

  return canUserAccess ? <Outlet {...props} /> : <Navigate to="/" />;
};

export default PrivateRoute;
