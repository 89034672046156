import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { NavigateRoutes } from './routes/routes';
import GlobalStyle from './styles/globalStyle';
import AuthProvider from './hooks/useAuth';
import { CurrentProvider } from './hooks/dataContext';

export const App = () => {
  return (
    <div className="App">
      <CurrentProvider>
        <AuthProvider>
          <NavigateRoutes />
        </AuthProvider>
      </CurrentProvider>
      <GlobalStyle />
      <ToastContainer />
    </div>
  );
};
