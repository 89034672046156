import { useEffect, useState } from 'react';

import imageUser from '../../../assets/Header/user.png';
import NotificationIconPoint from '../../../assets/ProfileHeader/notificationspoint.png';

import { useAuth } from '../../../hooks/useAuth';
import api from '../../../services/api';
import { INotifications } from '../../../types/Notifications';
import { messageError } from '../../../utils/message';

import {
  ButtonIcon,
  CompanyLabel,
  CompanyName,
  ContainerMain,
  Content,
  EmptyNotificationMessage,
  Icon,
  IconPoint,
  NotificationItem,
  NotificationItemTitle,
  NotificationsContainer,
  NotificationsTitle,
  SectionPerfil,
  Title,
  UserImage,
} from '../ProfileStyles';

const userRoleNames: Record<number, string> = {
  2: 'Empresa',
  4: 'Moderador',
};

export const Dropdown = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const [notifications, setNotifications] = useState<INotifications[]>([]);

  const getNotifications = async () => {
    try {
      const response = await api.get('/notifications');
      setNotifications(response.data);
      setHasUnread(
        response.data?.some(
          (notification: INotifications) => !notification.is_read,
        ) || false,
      );
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const handleNotificationClick = (notification: INotifications) => {
    if (notification.notification.link) {
      window.open(notification.notification.link, '_blank', 'noreferrer');
    }
  };

  const handlerNotification = () => {
    if (!openNotification) {
      getNotifications();
      api.put('/notifications/all');
    }
    setOpenNotification(!openNotification);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const { user } = useAuth();

  return (
    <ContainerMain>
      <SectionPerfil>
        <UserImage src={user?.avatar_url || imageUser} alt="" />
      </SectionPerfil>

      <Content>
        <Title>
          <CompanyLabel>{userRoleNames[user.role] || 'Empresa'}</CompanyLabel>
          <CompanyName>{user?.company?.name || user?.username}</CompanyName>
        </Title>
        <ButtonIcon onClick={handlerNotification}>
          {hasUnread ? <IconPoint src={NotificationIconPoint} /> : <Icon />}
        </ButtonIcon>
        <NotificationsContainer isOpen={!!openNotification}>
          <NotificationsTitle>Notificações</NotificationsTitle>
          {notifications.length === 0 && (
            <EmptyNotificationMessage>
              Você não possui notificações
            </EmptyNotificationMessage>
          )}
          {notifications.map((notification: INotifications) => (
            <NotificationItem
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
            >
              <NotificationItemTitle>
                {notification.notification?.title}
              </NotificationItemTitle>
              {notification.notification.message}
            </NotificationItem>
          ))}
        </NotificationsContainer>
      </Content>
    </ContainerMain>
  );
};
