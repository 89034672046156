import styled from 'styled-components';

interface ButtonSelect {
  active: boolean;
}

interface ButtonLinks {
  activeLink: boolean;
}

export const Container = styled.div`
  //border: 1px solid red;
  width: 100%;
  height: 100vh;
  display: flex;
  /* align-items: center; */
  background: #fbfbfb;
  color: var(--black);
`;

export const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2em;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
`;

export const ContainerLinks = styled.div`
  margin-bottom: 10px;
  display: flex;
  @media screen and (max-width: 1000px) {
    margin: 0 auto;
  }
`;

export const ButtonLinks = styled.button<ButtonLinks>`
  background-color: transparent;
  color: ${Props => (Props.activeLink ? '#1a1a1a' : '#707070')};
  margin: 0 50px 0 0;

  border-bottom: ${Props =>
    Props.activeLink ? '#FD1C50 solid 2px' : '#707070'};
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0px;
`;

export const ContainerSearch = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 20px;
`;

export const ButtonSearch = styled.button`
  display: flex;
  align-items: center;
  /* Position para o icone */
  position: absolute;
  border: none;
  background-color: #fff;
  margin: 0 10px;
`;

export const SearchBar = styled.input`
  width: 400px;
  height: 40px;
  text-indent: 40px;
  letter-spacing: 1px;
  border-radius: 5px;
  border: #dcdcdc 1px solid;
`;

export const ContainerSelecionados = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 15px;

  margin-top: 1.25rem;
  &:first-child {
    margin-top: 0.75rem;
  }
`;

export const SectionVagas = styled.div`
  display: flex;
  justify-content: start;
`;

export const IdVagas = styled.p`
  font-weight: bold;
  margin-right: 30px;
`;

export const Vagas = styled.p``;

export const SectionCandidaturas = styled.div`
  display: flex;
  align-items: center;
`;

export const Candidaturas = styled.p`
  display: flex;
  margin-right: 10px;
`;

export const CandidaturasItens = styled.p``;

export const ButtonView = styled.button`
  background-color: #fff;
  border: none;
  display: flex;
  padding: 5px;
  transition: 300ms;

  :hover {
    transition: 300ms;
    box-shadow: 0px 3px 15px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const ContainerScroll = styled.div`
  overflow-y: auto;
  padding: 0 0.5rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  margin-top: 0.75rem;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--pink);
    border-radius: 10px;
    height: 120px;
  }
`;

export const DeleteCandidates = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

export const Message = styled.p`
  font-weight: bold;
  font-size: 1.1rem;
`;

export const Pages = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: end;
`;
