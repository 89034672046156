import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

// Icons
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

// Image
import facebook from '../../assets/Login/facebook.png';
import instagram from '../../assets/Login/instagram.png';
import linkedin from '../../assets/Login/linkedin.png';
import telegram from '../../assets/Login/telegram.png';
import twitter from '../../assets/Login/twitter.png';
import youtube from '../../assets/Login/youtube.png';

import { LoginValues } from '../../types/LoginValues';
import { LoginSchema } from '../../validators/LoginSchema';

import { Checkbox } from '../../components/CheckBox';

// useAuth
import { useAuth } from '../../hooks/useAuth';

// Messaga Error
import { messageError } from '../../utils/message';

import api from '../../services/api';
import { Paginated } from '../../types/Paginated';
import { ISystemInfo } from '../../types/SystemInfo';
import {
  Button,
  ButtonEyeLogin,
  Container,
  ErrorMessage,
  ErrorMessagePassword,
  Fields,
  Form,
  Icones,
  Imagem,
  Input,
  LinkSocial,
  LoginPageLogo,
  Logo,
  NavigationAnchor,
  PasswordInputWrapper,
  RedesSociais,
  Section,
} from './loginStyles';

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<LoginValues>({
    resolver: yupResolver(LoginSchema),
  });
  const [ability, setAbility] = useState('password');
  const [changeIcon, setChangeIcon] = useState(false);

  const [companyText, setCompanyText] = useState('');
  const [companyTitle, setCompanyTitle] = useState('');

  const getSystemInfos = async () => {
    try {
      const { data } = await api.get<Paginated<ISystemInfo>>('/system-info');

      const apiCompanyTitle = data.results.find(
        systemInfo => systemInfo.name === 'company_title',
      );

      const apiCompanyText = data.results.find(
        systemInfo => systemInfo.name === 'company_text',
      );

      setCompanyTitle(apiCompanyTitle?.value || '');
      setCompanyText(apiCompanyText?.value || '');
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getSystemInfos();
  }, []);

  const viewPassword = () => {
    setChangeIcon(!changeIcon);
    if (ability === 'password') {
      setAbility('text');
    } else {
      return setAbility('password');
    }
  };

  const { login } = useAuth();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<LoginValues> = async data => {
    try {
      const response = await login({ ...data, remember_me: rememberMe });
      reset();

      if (response.user.role === 2) {
        navigate('/Inicio');
      } else {
        navigate('/VagasCadastradas');
      }
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const [rememberMe, setRememberMe] = useState(false);

  return (
    <Container>
      <Imagem>
        <div className="textElement">
          <h1>{companyTitle}</h1>
          <span>{companyText}</span>
        </div>
      </Imagem>

      <div className="alignment">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Logo>
            <LoginPageLogo />
          </Logo>
          <p id="mensagem">Acesso apenas para empresas cadastradas</p>
          <Fields>
            <div>
              <Input
                type="email"
                id="email"
                placeholder="E-mail"
                {...register('email')}
              />
              {!!errors?.email?.message && (
                <ErrorMessage>{errors.email.message}</ErrorMessage>
              )}
            </div>
            <div>
              <PasswordInputWrapper>
                <Input
                  type={ability}
                  id="password"
                  placeholder="Senha"
                  {...register('password')}
                />
                <ButtonEyeLogin onClick={viewPassword} type="button">
                  {changeIcon ? (
                    <AiFillEye color="#707070" />
                  ) : (
                    <AiFillEyeInvisible color="#707070" />
                  )}
                </ButtonEyeLogin>
              </PasswordInputWrapper>
              {!!errors?.password?.message && (
                <ErrorMessagePassword>
                  {errors.password.message}
                </ErrorMessagePassword>
              )}
            </div>
          </Fields>
          <Section>
            <Checkbox
              label="Manter conectado"
              containerClassName="section__checkbox-container"
              className="section__checkbox-input"
              checkboxSize="0.875rem"
              checked={rememberMe}
              onClick={() => setRememberMe(!rememberMe)}
            />
            <NavigationAnchor>
              <span id="forgotPassword">
                <Link className="LinkPasswordRecovery" to="/EsqueceuSenha">
                  Esqueci minha senha
                </Link>
              </span>
            </NavigationAnchor>
          </Section>

          <Button>
            <button type="submit" disabled={!isDirty}>
              Entrar
            </button>
          </Button>
          <div className="conta">
            <NavigationAnchor>
              <span>Não tem conta? </span>
              <Link className="Link" to="/Cadastro">
                Cadastre-se
              </Link>
            </NavigationAnchor>
          </div>
        </Form>

        <RedesSociais>
          <div>
            <p id="redesSociais">Redes Sociais</p>
          </div>

          <Icones>
            <LinkSocial
              href="https://twitter.com/clickpetroleoeg"
              target="_blank"
            >
              <img src={twitter} alt="Link para o Twitter" />
            </LinkSocial>

            <LinkSocial
              href="https://www.facebook.com/cpgclickpetroleoegas/"
              target="_blank"
            >
              <img src={facebook} alt="Link para o Facebook" />
            </LinkSocial>

            <LinkSocial
              href="https://www.linkedin.com/company/cpgclickpetroleoegas/"
              target="_blank"
            >
              <img src={linkedin} alt="Link para o Linkedin" />
            </LinkSocial>

            <LinkSocial
              href="https://www.youtube.com/@ClickPetroleoeGas"
              target="_blank"
            >
              <img src={youtube} alt="Link para o Youtube" />
            </LinkSocial>

            <LinkSocial
              href="https://www.instagram.com/clickpetroleoegas/"
              target="_blank"
            >
              <img src={instagram} alt="Link para Instagram" />
            </LinkSocial>

            <LinkSocial
              href="https://telegram.me/s/clickpetroleoegas"
              target="_blank"
            >
              <img src={telegram} alt="Link para o Telegram" />
            </LinkSocial>
          </Icones>
        </RedesSociais>
      </div>
    </Container>
  );
};
