/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { FaTrash } from 'react-icons/fa';
import { Navbar } from '../../components/Navbar/Navbar';
import { Profile } from '../../components/Profile/Profile';
import { useChangePage } from '../../hooks/dataContext';
import api from '../../services/api';
import { RegistrationForm } from '../../types/JobRegistrationForm';
import handleError from '../../utils/message';
import { RegistrationSchema } from '../../validators/VacancyRegistrationSchema';
import { FormFinish } from './components/FormFinalização/FormFinalizacao';
import { FormVaga } from './components/FormVaga/FormVaga';
import {
  SelectBasic,
  customStyles,
} from './components/FormVaga/FormVagaStyles';

import { ResultTemplate, Template } from '../../types/Template';
import { maskPhone } from '../../utils/masks';
import {
  Circle,
  Container,
  FormContent,
  Header,
  HeaderSection,
  Line,
  MainArea,
  SaveTemplateButton,
  Step,
  StepSection,
  Title,
  TitlePage,
} from './CadastroVagasStyles';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';

const steps = ['vaga', 'finalizacao'];

export const CadastroVagas = () => {
  const { state } = useChangePage();

  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [templateId, setTemplateId] = useState<any>();

  const [showModal, setShowModal] = useState(false);

  const methods = useForm<RegistrationForm>({
    mode: 'all',
    resolver: yupResolver(RegistrationSchema),
  });

  const { handleSubmit, reset } = methods;

  const fetchTemplate = async () => {
    try {
      const { data } = await api.get<ResultTemplate>('/job/template');

      setTemplates(data.results);
    } catch (error: any) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchTemplate();
  }, []);

  const onSaveTemplate = async () => {
    const data = methods.getValues();

    const { selected_message } = data;

    try {
      await api.post('/job/template', {
        ...data,
        options: {
          types: data.options.types,
          seniority_id: data.options.seniority_id || undefined,
          area_id: data.options.area_id || undefined,
          scholarity_id: data.options.scholarity_id || undefined,
          benefits: data.options.benefits || undefined,
        },
        abilities: data.abilities || undefined,
        city: data.city || undefined,
        remote: data.remote || undefined,
        state: data.state || undefined,
        work_journey: data.work_journey || undefined,
        start_date: undefined,
        finish_date: undefined,
        selected_message: selected_message || undefined,
        link_whatsapp: data.link_whatsapp
          ? `https://wa.me/55${data.link_whatsapp.replace(/\D/g, '')}`
          : undefined,
        description: data.description || undefined,
        finish_message: data.finish_message || undefined,
        title: data.title || undefined,
        salary: data.salary || undefined,
      });

      fetchTemplate();

      toast.success('Template salvo com sucesso!');
    } catch (error: any) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = (val: any) => {
    const { value } = val;
    const data = templates.find((temp: Template) => temp.id === value);
    setTemplateId(val);

    if (data) {
      reset({
        options: {
          seniority_id: data.seniority_id,
          types: data.types.map(type => type.id),
          scholarity_id: data.scholarity_id,
          area_id: data.area_id,
          benefits: data.benefits,
        },
        remote: data.remote,
        state: data.state,
        city: data.city,
        abilities: data.abilities,
        description: data.description,
        routine: data.routine,
        finish_message: data.finish_message,
        selected_message: data.selected_message,
        link_email: data.link_email,
        link_external: data.link_external,
        link_whatsapp: data.link_whatsapp
          ? `${maskPhone(data.link_whatsapp.replace('https://wa.me/55', ''))}`
          : '',
        work_journey: data.work_journey,
        title: data.title,
        salary: data.salary,
      });
    }
  };

  const handleDeleteTemplate = async () => {
    try {
      await api.delete(`/job/template`, {
        data: {
          ids: [Number(templateId.value)],
        }
      });

      toast.success('Template deletado com sucesso!');
      fetchTemplate();
    } catch (error: any) {
      handleError(error);
    } finally {
      setShowModal(false);
    }
  };

  return (
    <Container>
      <Navbar />

      <MainArea>
        <TitlePage>
          <Title>Cadastro de vagas</Title>
          <Header>
            <Profile />
          </Header>
        </TitlePage>

        <HeaderSection>
          <StepSection>
            <Step active={steps[state.currentStepRegistration] === 'vaga'}>
              <button type="button" id="step">
                <Circle
                  active={steps[state.currentStepRegistration] === 'vaga'}
                >
                  1
                </Circle>
                Vaga
              </button>
            </Step>

            <Step
              active={steps[state.currentStepRegistration] === 'finalizacao'}
            >
              <button type="button" id="step">
                <Circle
                  active={
                    steps[state.currentStepRegistration] === 'finalizacao'
                  }
                >
                  2
                </Circle>
                Finalização
              </button>
            </Step>
          </StepSection>

          <Line>
            <SelectBasic
              className="basic-select"
              classNamePrefix="react-select"
              placeholder="Selecione"
              styles={customStyles}
              options={
                templates.map((temp: Template) => ({
                  value: temp.id,
                  label: temp.title || temp.city,
                })) || []
              }
              onChange={(val: any) => resetForm(val)}
              value={templateId}
            />
            <SaveTemplateButton
              type="button"
              onClick={handleSubmit(onSaveTemplate)}
              disabled={loading}
            >
              Salvar template
            </SaveTemplateButton>

            {templateId && (
              <FaTrash
                color="#8c8c8c"
                size={24}
                onClick={() => setShowModal(true)}
                style={{ cursor: 'pointer' }}
              />
            )}
          </Line>
        </HeaderSection>

        <FormProvider {...methods}>
          <FormContent>
            {steps[state.currentStepRegistration] === 'vaga' && (
              <div className="vaga">
                <FormVaga />
              </div>
            )}

            {steps[state.currentStepRegistration] === 'finalizacao' && (
              <div className="vaga">
                <FormFinish />
              </div>
            )}
          </FormContent>
        </FormProvider>
      </MainArea>

      {showModal && (
        <ConfirmModal
          key={templateId}
          onCancel={() => setShowModal(false)}
          onConfirm={handleDeleteTemplate}
          cancelText='Não'
          confirmText='Sim, deletar'
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title='Tem certeza que deseja deletar esse template?'
        />
      )}
    </Container>
  );
};
