import React from 'react';
import { Container, Input, Label } from './styles';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  containerClassName?: string;
  labelClassName?: string;
  checkboxSize?: string;
}

export const Checkbox = ({
  label,
  name,
  containerClassName,
  labelClassName,
  checkboxSize = '1rem',
  ...rest
}: CheckboxProps) => {
  return (
    <Container htmlFor={name} className={containerClassName}>
      <Input
        checkboxSize={checkboxSize}
        type="checkbox"
        id={name}
        name={name}
        {...rest}
      />
      <Label className={labelClassName}>{label}</Label>
    </Container>
  );
};
