import styled from 'styled-components';
import { ReactComponent as NotificationIcon } from '../../assets/ProfileHeader/notifications.svg';

export const Container = styled.div`
  margin-top: 1rem;
  background: transparent;
  width: 100%;
`;
export const Content = styled.div`
  margin-left: 1.25rem;

  position: relative;

  width: fit-content;
  min-width: 13.5rem;
  height: 3.75rem;

  padding: 0.625rem 1rem;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1.25rem;

  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0.5rem;
`;
export const Title = styled.div`
  padding-right: 1.5rem;

  border-right: 1px solid #dcdcdc;
`;

export const Icon = styled(NotificationIcon)``;

export const IconPoint = styled.img`
  background: #efefef;
  border-radius: 50%;
  padding: 0.375rem;
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
`;

export const ContainerMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
`;

export const SectionPerfil = styled.div`
  display: flex;
  align-items: center;
`;

export const UserImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50px;

  object-fit: cover;
`;

export const ContainerButton = styled.div``;

export const DropPerfil = styled.button`
  background: transparent;

  margin-left: 20px;
  border: none;
  color: #707070;
  display: flex;
  align-items: center;
  cursor: auto;
`;

export const ButtonDown = styled.button`
  background: transparent;
  border: none;
  color: #707070;
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const Drop = styled.div`
  position: absolute;
  margin-left: 20px;
  background-color: #fff;
  padding: 15px 20px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 99;
`;

export const ButtonPerfil = styled.button`
  background-color: #fff;
  border: none;
  color: #707070;
  border-radius: 0;
  transition: 300ms;

  :hover {
    transition: 300ms;
    border-bottom: 1px solid #707070;
    border-radius: 0;
  }
`;

export const ButtonIcon = styled.button`
  background-color: transparent;
  align-items: center;
  display: flex;
  border: none;
`;

export const DropUser = styled.div`
  position: relative;
  width: 210px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px;
  overflow-y: auto;
  scrollbar-color: #fd1c50 #ffff;
  height: 300px;
  z-index: 999;
`;

export const NotificationsTitle = styled.p`
  // margin-bottom: 10px;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
`;

export const NotificationItem = styled.button`
  background-color: transparent;
  color: #000;
  border: none;
  border-radius: 0;
  text-align: start;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #cbcbcb;
  // margin-bottom: 30px;

  width: 100%;
  padding: 0.5rem;
`;

export const NotificationItemTitle = styled.p`
  font-size: 0.8rem;
  font-weight: 600;
`;

export const ButtonClose = styled.button`
  display: flex;
  align-items: center;
  background-color: #fff;
  border: none;
  color: red;
  font-size: 1.2rem;
`;

export const ContainerName = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface NotificationsContainerProps {
  isOpen: boolean;
}

export const NotificationsContainer = styled.div<NotificationsContainerProps>`
  position: absolute;
  top: 103%;
  right: 0;
  left: 0;
  z-index: 2;

  min-height: ${({ isOpen }) => (isOpen ? '15rem' : '0')};
  max-height: ${({ isOpen }) => (isOpen ? '15rem' : '0')};
  overflow: ${({ isOpen }) => (isOpen ? 'auto' : 'hidden')};

  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;

  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;

  transition: all 0.3s;
`;

export const CompanyLabel = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  color: #1a1a1a;
`;

export const CompanyName = styled.p`
  color: #1a1a1a88;
  font-size: 0.875rem;
`;

export const NotificationLink = styled.a`
  text-decoration: underline;
  color: #2779f6;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const EmptyNotificationMessage = styled.p`
  font-size: 0.875rem;
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  color: #1a1a1a88;
`;
