import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormSimilar from '../../components/FormSimilar/FormSimilar';
import { Navbar } from '../../components/Navbar/Navbar';
import { Profile } from '../../components/Profile/Profile';
import api from '../../services/api';
import { IJob } from '../../types/Job';
import handleError from '../../utils/message';
import { Container, Header, HeaderContainer, MainArea, Title } from './styles';

const SimilaresEdit = () => {
  const [job, setJob] = useState<IJob>();

  const { id } = useParams();

  const getJob = async () => {
    try {
      const response = await api.get<IJob>(`/job/${id}`);

      setJob(response.data);
    } catch (error: any) {
      handleError(error);
    }
  };

  useEffect(() => {
    getJob();
  }, [id]);

  return (
    <Container>
      <Navbar />
      <MainArea>
        <HeaderContainer>
          <Title>Similares</Title>
          <Header>
            <Profile />
          </Header>
        </HeaderContainer>

        {job && (
          <>
            <p style={{ marginBottom: '1rem' }}>
              Selecione vagas similares à {job?.title}
            </p>
            <FormSimilar
              currentJobId={Number(id)}
              initialRelatedIds={job.related.map(j => j.id)}
            />
          </>
        )}
      </MainArea>
    </Container>
  );
};

export default SimilaresEdit;
