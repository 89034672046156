import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  background: #fbfbfb;

  align-items: stretch;
`;

export const MainArea = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 0 2em;
  height: 100vh;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
`;

export const HeaderContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  margin-top: 2rem;

  font-size: 24px;
  font-weight: 600;
  color: var(--black);
`;
