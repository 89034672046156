/* eslint-disable react/no-unused-prop-types */
import {
  Button,
  ButtonsContainer,
  CancelButton,
  Container,
  Content,
  Title,
} from './styles';

export interface ConfirmModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  onClose?: () => void;

  title?: string;
  confirmText?: string;
  cancelText?: string;
  isOpen?: boolean;
}

const ConfirmModal = ({
  onCancel,
  onConfirm,
  cancelText = 'Cancelar',
  confirmText = 'Confirmar',
  onClose = onCancel,
  title = 'Tem certeza?',
}: ConfirmModalProps) => {
  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <ButtonsContainer>
          <CancelButton type="button" onClick={onCancel}>
            {cancelText}
          </CancelButton>
          <Button type="button" onClick={onConfirm}>
            {confirmText}
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};

export default ConfirmModal;
