import { Pagination, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import { BiSearchAlt2 } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';
import api from '../../services/api';
import { IJob } from '../../types/Job';
import { Paginated } from '../../types/Paginated';
import handleError, { handleSuccess } from '../../utils/message';
import {
  Container,
  EmptyTableContent,
  Pages,
  SaveButton,
  SearchButton,
  SearchContainer,
  SearchHeader,
  SearchInput,
  SelectBasic,
  Table,
  TableData,
  TableHead,
  TableHeading,
  TableWrapper,
  customStyles,
} from './_formSimilar';

interface Props {
  initialRelatedIds: number[];
  currentJobId: number;
}

interface IJobType {
  id: string;
  name: string;
}

interface Option {
  value: string | number;
  label: string;
}

const limit = 12;

const FormSimilar = ({ currentJobId, initialRelatedIds }: Props) => {
  const [selectedJobIds, setSelectedJobIds] =
    useState<number[]>(initialRelatedIds);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [jobs, setJobs] = useState<IJob[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [jobTypeOptions, setJobTypeOptions] = useState<Option[]>([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState<Option[]>([]);

  const debouncedSearch = useDebounce(search, 600);

  const getJobTypes = async () => {
    try {
      const { data } = await api.get<Paginated<IJobType>>('/job/option/type', {
        params: {
          limit: 0,
        },
      });

      setJobTypeOptions(
        data.results.map(jobType => ({
          label: jobType.name,
          value: jobType.id,
        })),
      );
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getJobTypes();
  }, []);

  const getJobs = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get<Paginated<IJob>>('/job/jobs', {
        params: {
          page,
          limit,
          search: debouncedSearch || undefined,
          types: selectedJobTypes.length
            ? selectedJobTypes.map(option => option.value)
            : undefined,
        },
      });

      setJobs(data.results.filter(job => job.id !== currentJobId));
      setTotalPages(Math.ceil(data.total / limit));
    } catch (error: any) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getJobs();
  }, [page, debouncedSearch, selectedJobTypes]);

  const handleSave = async () => {
    try {
      await api.put(`/job/related/${currentJobId}`, {
        ids: selectedJobIds,
      });
      handleSuccess('Similares salvos com sucesso!');
      navigate(-1);
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleToggleSelected = (jobId: number) => {
    setSelectedJobIds(prev => {
      if (prev.includes(jobId)) {
        return prev.filter(id => id !== jobId);
      }
      return [...prev, jobId];
    });
  };

  return (
    <Container>
      <SearchHeader>
        <SearchContainer onSubmit={e => e.preventDefault()}>
          <SearchButton>
            <BiSearchAlt2 size={24} />
          </SearchButton>
          <SearchInput
            placeholder="Pesquisar"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </SearchContainer>
        <SelectBasic
          classNamePrefix="react-select"
          options={jobTypeOptions}
          placeholder="Selecione os cargos"
          noOptionsMessage={() => 'Nenhum cargo encontrado'}
          value={selectedJobTypes}
          styles={customStyles}
          isMulti
          onChange={options => {
            setSelectedJobTypes(options as Option[]);
            if (page > 1) {
              setPage(1);
              setTotalPages(1);
            }
          }}
        />
      </SearchHeader>
      <TableWrapper>
        <Table>
          <TableHead>
            <tr>
              <TableHeading />
              <TableHeading>ID</TableHeading>
              <TableHeading>Título</TableHeading>
              <TableHeading>Área de atuação</TableHeading>
            </tr>
          </TableHead>
          <tbody>
            {jobs.length === 0 && !isLoading && (
              <tr>
                <td colSpan={4}>
                  <EmptyTableContent>Nenhuma vaga</EmptyTableContent>
                </td>
              </tr>
            )}
            {jobs.map(job => (
              <tr key={job.id}>
                <TableData>
                  <Checkbox
                    checked={selectedJobIds.includes(job.id)}
                    onChange={() => {
                      handleToggleSelected(job.id);
                    }}
                    color="default"
                    size="small"
                    style={{
                      color: '#fd1c50',
                      padding: '0px',
                    }}
                    disableRipple
                  />
                </TableData>
                <TableData>#{job.id}</TableData>
                <TableData>{job.title}</TableData>
                <TableData>{job?.area?.name || ''}</TableData>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pages
          style={{
            position: 'sticky',
            bottom: 0,
            background: '#fbfbfb',
            zIndex: 1,
            padding: '1rem 0',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: '1rem',
          }}
        >
          <Stack spacing={1}>
            <Pagination
              page={page}
              count={totalPages}
              onChange={(e, pag) => {
                setPage(pag);
              }}
              shape="rounded"
              size="small"
            />
          </Stack>
          <SaveButton type="button" onClick={handleSave}>
            Salvar
          </SaveButton>
        </Pages>
      </TableWrapper>
    </Container>
  );
};

export default FormSimilar;
