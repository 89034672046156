import styled from 'styled-components';

interface Props {
  active: boolean;
}

export const Container = styled.main`
  width: 15rem;
  height: 100vh;

  display: flex;

  text-align: center;

  background: #ffffff;
`;

export const Content = styled.div`
  width: 100%;

  .alignment {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70vh;
  }
`;

export const Logo = styled.img`
  margin-bottom: 3.75rem;
  margin-top: 1rem;
  width: 7.125rem;
  height: 4.1875rem;
  object-fit: cover;
`;

export const LogoResponsive = styled.img`
  margin-bottom: 3.75rem;
  margin-top: 5rem;
  width: 7.125rem;
  height: 4.1875rem;
  object-fit: cover;
`;

export const Nav = styled.div<Props>`
  display: flex;
  align-items: center;

  text-align: center;
  height: 52px;
  cursor: pointer;

  border-left-style: solid;
  border-left-color: ${props => (props.active ? 'var(--pink)' : 'transparent')};

  /* box-shadow: 0px 1px 2px
    ${props => (props.active ? '#00000029' : 'transparent')}; */

  &:hover {
    opacity: 0.8;
  }

  & + & {
    margin-top: 0.6rem;
  }

  @media (max-width: 951px) {
    padding: 0 1rem;
  }
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 16px;
`;

export const Title = styled.div`
  margin-left: 10px;
  align-items: center;

  text-align: left;

  font-size: 1.2rem;

  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: var(--medium-gray);
`;

export const Button = styled.button`
  width: fit-content;
  margin-left: 1rem;
  margin-top: 1.25rem;

  font-size: 1rem;

  padding: 0.5rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  border: none;
  border-radius: 8px;
  opacity: 1;

  background: var(--lotion);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: var(--medium-gray);

  &:hover {
    box-shadow: 0px 3px 6px #fd1c506b;
  }

  @media (min-height: 1366px) and (max-width: 1024px) {
    margin-bottom: auto;
  }
`;

export const ButtonMenu = styled.button`
  padding: 10px;
  border: none;
  background: transparent;
  color: var(--medium-gray);
  font-size: 2.5rem;
  position: absolute;
  margin-bottom: 40px;
`;

export const ContainerMain = styled.div`
  position: absolute;
  background-color: #fff;
  height: 99vh;
  padding: 0px 20px;
  z-index: 999;
`;
