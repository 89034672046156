// React
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// React Icons
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { AiFillEye } from 'react-icons/ai';
import { BiSearchAlt2 } from 'react-icons/bi';

//  API
import api from '../../../../services/api';
import { IJob } from '../../../../types/Job';
import { messageError } from '../../../../utils/message';

// Import Component
import { Navbar } from '../../../../components/Navbar/Navbar';
import { Profile } from '../../../../components/Profile/Profile';

// Style
import LinkHeader from '../LinkHeader/LinkHeader';
import {
  ButtonSearch,
  ButtonView,
  Candidaturas,
  CandidaturasItens,
  Container,
  ContainerScroll,
  ContainerSearch,
  ContainerSelecionados,
  DeleteCandidates,
  Header,
  IdVagas,
  MainArea,
  Message,
  Pages,
  SearchBar,
  SectionCandidaturas,
  SectionVagas,
  Vagas,
} from './_selecionados';

export const Selecionados = () => {
  // CurrentPage
  const navigate = useNavigate();

  const [buscar, setBuscar] = useState('');
  const [applications, setApplications] = useState<IJob[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  const getVacancies = async () => {
    try {
      const response = await api.get('/job/jobs', {
        params: {
          'status_array[0]': 3,
          'status_array[1]': 4,
          'status_array[2]': 2,
          search: buscar || undefined,
          limit,
          page,
        },
      });
      setApplications(response.data.results);
      setTotalPages(Math.ceil(response.data.total / limit));

      // if (buscar) {
      //   setPage(1);
      //   setApplications(response.data.results);
      // }
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };
  useEffect(() => {
    getVacancies();
  }, [page, buscar]);

  return (
    <Container>
      <Navbar />
      <MainArea>
        <Header>
          <Profile />
        </Header>
        <LinkHeader />

        <ContainerSearch>
          <ButtonSearch>
            <BiSearchAlt2 fontSize={25} color="#DCDCDC" />
          </ButtonSearch>
          <SearchBar
            type="text"
            placeholder="Buscar"
            value={buscar}
            onChange={e => setBuscar(e.target.value)}
          />
        </ContainerSearch>
        {applications.length === 0 ? (
          <DeleteCandidates>
            <Message>Nenhuma Vaga</Message>
          </DeleteCandidates>
        ) : (
          <ContainerScroll>
            {applications.map((itens: IJob) => (
              <ContainerSelecionados>
                <SectionVagas>
                  <IdVagas>{`#${itens.id}`}</IdVagas>
                  <Vagas>{itens.title || ''}</Vagas>
                </SectionVagas>
                <SectionCandidaturas>
                  <Candidaturas>
                    <CandidaturasItens>
                      {itens.application_count}&nbsp;
                    </CandidaturasItens>
                    Candidaturas
                  </Candidaturas>

                  <ButtonView
                    onClick={() =>
                      navigate(
                        `/Selecionados/VisualizarSelecionados/${itens.id}`,
                      )
                    }
                  >
                    <AiFillEye fontSize={24} color="#707070" />
                  </ButtonView>
                </SectionCandidaturas>
              </ContainerSelecionados>
            ))}
          </ContainerScroll>
        )}
        <Pages>
          <Stack spacing={1}>
            <Pagination
              page={page}
              count={totalPages}
              onChange={(e, pag) => {
                setPage(pag);
              }}
              shape="rounded"
              size="small"
            />
          </Stack>
        </Pages>
      </MainArea>
    </Container>
  );
};
