import * as yup from 'yup';

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email('E-mail invalido')
    .trim()
    .required('Preencha os campos necessários'),
  password: yup
    .string()
    .min(8, 'Digite uma senha válida')
    .required('Preencha os campos necessários'),
});
