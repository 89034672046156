import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const InputLabel = styled.label`
  margin-bottom: 0.375rem;
  margin-left: 0.125rem;

  font-size: 1rem;
  font-weight: 600;
  color: var(--black);
`;

export const StyledInput = styled.input`
  border: none;

  font-family: Noto Sans, sans-serif;

  min-width: 0px;
  width: 100%;
  height: 2.625rem;
  border-radius: 8px;

  padding: 0 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  color: var(--black);
  background-color: #ffffff;
  border: 1px solid #dcdcdc;

  transition: border-color 0.2s;

  &::placeholder {
    color: #acacac;
  }

  &:focus {
    outline: none;
    border-color: #acacac;
  }
`;

export const ErrorMessage = styled.span`
  margin-top: 0.125rem;
  margin-left: 0.125rem;
  color: red;
  font-size: 0.75rem;
`;
