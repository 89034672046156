import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  inset: 0;

  z-index: 2;

  background: #20202099;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  position: relative;

  width: 100%;
  max-width: 27rem;

  padding: 2rem;

  min-height: 12rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.5rem;
  text-align: center;

  background: #fff;
  border-radius: 1.5rem;
`;

export const Title = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  color: #1a1a1a;
`;

export const ButtonsContainer = styled.div`
  margin-top: auto;

  width: 90%;

  display: flex;
  align-items: center;
  gap: 2.5rem;
`;

export const CancelButton = styled.button`
  border: none;

  background: #ececec;
  color: #1a1a1a;

  width: 100%;
  height: 3.125rem;
  font-weight: 500;
  font-size: 1rem;

  transition: filter 0.3s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Button = styled.button`
  border: none;

  width: 100%;
  height: 3.125rem;
  font-weight: 500;
  font-size: 1rem;
  background: var(--pink);
  color: #ffffff;

  transition: filter 0.3s;

  &:hover {
    filter: brightness(0.9);
  }
`;
