import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root{
    --pink:#FD1C50;
    --lotion:#FAFAFA;
    --black:#1A1A1A;
    --dark-gray:#1A1A1ACC;
    --medium-gray:#1A1A1A7F;
    --gray:#1A1A1A4D;
    --light-gray: #1A1A1A80;
  }

  html {
        @media (max-width: 1080px) {
            font-size: 93.75%;
        }
        @media (max-width: 720px) {
            font-size: 87.5%;
        }
    }

  *{
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }
    *:focus{
        outline: none;
    }
    body{
        -webkit-font-smoothing: antialiased;
        word-break: break-word;
    }
    body, input, textarea, button {
        font-family: 'Noto Sans', sans-serif;
    }

    button{
        cursor: pointer;
        font-size: 1rem;
    }
    [disabled]{
        opacity: 0.6;
        cursor: not-allowed;
    }
    @keyframes rendering{
        from{
            opacity:0
        }
        to{
            opacity:1
        }
      }


  button {
    cursor: pointer;
    color: #ffff;
    background: #FD1C50;
    border-radius: 8px;
    border: 1px solid #DCDCDC;

    &:disabled {
      background: #DCDCDCB2;
      color: #1A1A1A80;
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
`;
