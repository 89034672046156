import styled from 'styled-components';

interface Props {
  active: boolean;
}

export const Container = styled.div`
  //border: 1px solid red;
  height: 100vh;
  display: flex;

  /* align-items: center; */
  background: #fbfbfb;
  color: var(--black);
`;

export const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  flex: 1;

  overflow: auto;
`;

export const Header = styled.div`
  display: flex;

  justify-content: end;
  margin-bottom: 10px;
`;

export const TitlePage = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.div`
  margin-left: 2rem;
  margin-top: 2rem;

  font-size: 24px;
  font-weight: 600;
  color: var(--black);
`;

export const Circle = styled.div<Props>`
  width: 35px;
  height: 35px;
  background-color: ${props => (props.active ? 'var(--pink)' : '#dcdcdc')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  border-radius: 50%;
  margin: 0 1rem 0 2rem;

  @media screen and (max-width: 1300px) {
    height: 25px;
    width: 25px;
    font-size: 1rem;
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Step = styled.div<Props>`
  #step {
    width: 280px;
    height: 55px;

    display: flex;
    align-items: center;

    background: #ffffff;

    border: 1px solid #dcdcdc;
    border-radius: 2px;

    outline-color: var(--pink);

    font-size: 1.13rem;
    font-weight: 600;
    color: var(--black);

    border-color: ${props => (props.active ? 'var(--pink)' : 'transparent')};
    box-shadow: 0px 3px 6px
      ${props => (props.active ? '#00000029' : 'transparent')};

    @media screen and (max-width: 1366px) {
      width: 250px;
    }

    @media screen and (max-width: 1300px) {
      width: 220px;
      height: 45px;
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 1200px) {
    margin-bottom: 30px;
  }
`;

export const HeaderSection = styled.div`
  width: 90%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-left: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  .basic-select {
    width: 200px;
  }
`;

export const StepSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: 850px) {
    justify-content: center;
  }
`;

export const Icon = styled.img`
  padding: 8px 35px;
`;
export const FormContent = styled.div`
  width: 90%;
  margin-top: 1.5rem;
  margin-left: 2rem;
`;
export const Button = styled.div`
  display: flex;
  justify-content: flex-end;

  padding-top: 0.8rem;
  padding-right: 5.8rem;
  padding-bottom: 0.8rem;

  margin-top: 1rem;

  background: #fbfbfb;

  button {
    width: 147px;
    height: 48px;

    display: flex;
    align-items: center;

    justify-content: center;

    background-color: #ffffff;

    border: 1px solid #dcdcdc;
    border-radius: 8px;

    & + button {
      margin-left: 1rem;
    }
  }
  #voltar {
    padding-left: 16px;
    opacity: 65%;
    font-size: 1rem;
    font-weight: 600;
    color: var(--medium-gray);
  }
  #proximo {
    padding-right: 16px;

    font-size: 1rem;
    font-weight: 600;
    color: var(--medium-gray);
  }

  #salvar {
    font-size: 1rem;
    font-weight: 600;
    color: var(--medium-gray);
  }

  #cadastrar {
    background-color: #fd1c50;
    color: white;
    font-size: 1rem;
    width: 160px;
    height: 40px;
  }
`;

export const SaveTemplateButton = styled.button`
  border: none;

  width: 160px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #fd1c50;
  color: white;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.95);
  }
`;

export const IconButton = styled.img`
  width: 16px;
  height: 16px;
`;
