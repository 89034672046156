// React
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Icons React
import { AiOutlineLock } from 'react-icons/ai';

// Components
import { Navbar } from '../../components/Navbar/Navbar';
import { Profile } from '../../components/Profile/Profile';
import { IPerfilUser } from '../../types/PerfilUser';

// Image
import imageUser from '../../assets/Header/userPerfil.png';

// Style
import { useAuth } from '../../hooks/useAuth';
import api from '../../services/api';
import { getAddressInformation } from '../../services/viacep';
import { maskCep, maskCnpj, maskPhone } from '../../utils/masks';
import { messageError, messageSuccess } from '../../utils/message';
import {
  ButtonSave,
  Container,
  ContainerImage,
  ContainerSave,
  ErrorText,
  Field,
  FieldIconWrapper,
  FieldInput,
  FieldLabel,
  Form,
  FormSection,
  Header,
  ImageInput,
  ImageLabel,
  ImageUser,
  InputTextArea,
  Label,
  MainArea,
  SectionColumn,
  SectionFlex,
  Title,
  TitleSection,
  TypeImage,
} from './_editarperfil';

export const EditarPerfil = () => {
  const { user, setUser } = useAuth();
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = useForm<IPerfilUser>({
    defaultValues: {
      companyCnpj: maskCnpj(user?.company?.cnpj),
    },
  });

  const [image, setImage] = useState<File>();
  const imageUrl: string = image ? URL.createObjectURL(image) : '';

  const onSubmit: SubmitHandler<IPerfilUser> = async data => {
    const {
      corporateName,
      new_password,
      old_password,
      confirm_password,
      companyCnpj,
      companyDescription,
      companyPhone,
      addressNeighborhood,
      companyCep,
      companyNumber,
      addressComplement,
      addressCity,
      addressStreet,
      addressState,
      responsibleEmail,
      responsibleName,
      responsiblePhone,
      external_link,
    } = data;

    const formData = new FormData();
    if (image) formData.append('avatar', image || '');
    if (external_link)
      formData.append('company[external_link]', external_link || '');
    formData.append('company[name]', corporateName);
    if (new_password) formData.append('password', new_password);
    if (old_password) formData.append('old_password', old_password);
    if (confirm_password) formData.append('confirm_password', confirm_password);
    if (companyDescription)
      formData.append('company[description]', companyDescription);

    if (companyCnpj)
      formData.append('company[cnpj]', companyCnpj.replace(/\D/g, ''));
    if (companyPhone)
      formData.append('company[phone]', companyPhone.replace(/\D/g, ''));

    if (addressNeighborhood)
      formData.append('address[neighborhood]', addressNeighborhood);
    if (companyCep) formData.append('address[cep]', companyCep);
    if (companyNumber) formData.append('address[number]', companyNumber);
    if (addressComplement)
      formData.append('address[complement]', addressComplement);
    if (addressCity) formData.append('address[city]', addressCity);
    if (addressStreet) formData.append('address[street]', addressStreet);
    if (addressState) formData.append('address[state]', addressState);

    if (companyCep) formData.append('address[country]', 'Brasil');

    if (responsibleEmail)
      formData.append('company[responsible][email]', responsibleEmail);
    if (responsibleName)
      formData.append('company[responsible][name]', responsibleName);
    if (responsiblePhone)
      formData.append(
        'company[responsible][phone]',
        responsiblePhone.replace(/\D/g, ''),
      );

    try {
      const response = await api.put(`/user`, formData);
      messageSuccess('Alterado com Sucesso!');
      setUser(response.data);

      localStorage.setItem(
        '@ClickPetroleoEmpresas: user',
        JSON.stringify(response.data),
      );
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const handlePhone = (event: any) => {
    const input = event.target;
    input.value = maskPhone(input.value);
  };

  const handleCep = (event: any) => {
    const input = event.target;
    input.value = maskCep(input.value);
  };

  const handleCepBlur = async () => {
    const cep = getValues('companyCep');

    const cepDigits = cep.replace(/\D/g, '');

    if (cepDigits.length !== 8) {
      return;
    }

    if (!dirtyFields?.companyCep) {
      return;
    }

    try {
      const address = await getAddressInformation(cepDigits);

      reset({
        addressCity: address.city,
        addressComplement: address?.complement || '',
        addressNeighborhood: address.neighborhood,
        addressState: address.state,
        addressStreet: address.street,
        companyCep: address.cep.replace('-', ''),
        companyNumber: address.number,
      });
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <Container>
      <Navbar />
      <MainArea>
        <Header>
          <Profile />
        </Header>
        <TitleSection>Informações Pessoais</TitleSection>

        <Form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          id="edit-profile-form"
        >
          <FormSection>
            <SectionColumn>
              <Field>
                <FieldLabel>Nome de exibição</FieldLabel>
                <FieldInput
                  type="text"
                  {...register('corporateName')}
                  defaultValue={user?.company?.name}
                />
                {errors.username && (
                  <ErrorText>{errors.username.message}</ErrorText>
                )}
              </Field>

              <Field>
                <FieldLabel>E-mail</FieldLabel>
                <FieldInput type="email" disabled defaultValue={user.email} />
                <FieldIconWrapper>
                  <AiOutlineLock />
                </FieldIconWrapper>
              </Field>

              <Field>
                <FieldLabel>Telefone da empresa</FieldLabel>
                <FieldInput
                  defaultValue={maskPhone(user?.company?.phone || '')}
                  onInput={handlePhone}
                  maxLength={15}
                  {...register('companyPhone')}
                />
              </Field>
            </SectionColumn>

            <ContainerImage
              style={{
                gridColumn: 'auto / span 2',
              }}
            >
              <SectionFlex>
                <ImageLabel>Foto de perfil</ImageLabel>
                <ImageUser src={imageUrl || user?.avatar_url || imageUser} />
                <TypeImage>JPG,PNG</TypeImage>
              </SectionFlex>
              <Label htmlFor="files">Carregar</Label>
              <ImageInput
                type="file"
                accept="image/*"
                id="files"
                {...register('avatar')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { files } = e.target;

                  if (!files || files.length < 1) {
                    return;
                  }

                  const selectedFile = files[0];
                  setImage(selectedFile);
                }}
              />
            </ContainerImage>
          </FormSection>

          <FormSection>
            <Field>
              <FieldLabel>Nome do responsável</FieldLabel>
              <FieldInput
                type="text"
                defaultValue={user?.company?.responsible?.name}
                {...register('responsibleName')}
              />
            </Field>
            <Field>
              <FieldLabel htmlFor="responsibleEmail">
                E-mail do responsável
              </FieldLabel>
              <FieldInput
                type="email"
                id="responsibleEmail"
                defaultValue={user?.company?.responsible?.email}
                {...register('responsibleEmail')}
              />
            </Field>
          </FormSection>

          <FormSection>
            <Field>
              <FieldLabel>Telefone do responsável</FieldLabel>
              <FieldInput
                type="text"
                defaultValue={maskPhone(user?.company?.responsible?.phone)}
                onInput={handlePhone}
                maxLength={15}
                {...register('responsiblePhone')}
              />
            </Field>

            <Field>
              <FieldLabel>Link externo do site</FieldLabel>
              <FieldInput
                type="text"
                defaultValue={user?.company?.external_link}
                {...register('external_link')}
              />
            </Field>
          </FormSection>

          <FormSection>
            <SectionColumn>
              <Field>
                <FieldLabel>CNPJ</FieldLabel>
                <FieldInput
                  type="text"
                  readOnly
                  {...register('companyCnpj')}
                  style={{
                    opacity: 0.6,
                    cursor: 'not-allowed',
                  }}
                />
                <FieldIconWrapper>
                  <AiOutlineLock />
                </FieldIconWrapper>
              </Field>
              <Field>
                <FieldLabel>CEP</FieldLabel>
                <FieldInput
                  type="text"
                  maxLength={9}
                  defaultValue={user?.company?.address?.cep}
                  onInput={handleCep}
                  {...register('companyCep', {
                    onBlur: handleCepBlur,
                  })}
                />
              </Field>
            </SectionColumn>
            <SectionColumn>
              <Field>
                <FieldLabel>Logradouro</FieldLabel>
                <FieldInput
                  type="text"
                  defaultValue={user?.company?.address?.street}
                  {...register('addressStreet')}
                />
              </Field>
              <Field>
                <FieldLabel>Número</FieldLabel>
                <FieldInput
                  type="text"
                  defaultValue={user?.company?.address?.number}
                  {...register('companyNumber')}
                />
              </Field>
            </SectionColumn>

            <SectionColumn>
              <Field>
                <FieldLabel>Complemento</FieldLabel>
                <FieldInput
                  type="text"
                  defaultValue={user?.company?.address?.complement}
                  {...register('addressComplement')}
                />
              </Field>
              <Field>
                <FieldLabel>Cidade</FieldLabel>
                <FieldInput
                  type="text"
                  defaultValue={user?.company?.address?.city}
                  {...register('addressCity')}
                />
              </Field>
            </SectionColumn>
          </FormSection>
          <FormSection>
            <SectionColumn>
              <Field>
                <FieldLabel>Estado</FieldLabel>
                <FieldInput
                  type="text"
                  defaultValue={user?.company?.address?.state}
                  {...register('addressState')}
                />
              </Field>
              <Field>
                <FieldLabel>Bairro</FieldLabel>
                <FieldInput
                  type="text"
                  defaultValue={user?.company?.address?.neighborhood}
                  {...register('addressNeighborhood')}
                />
              </Field>
            </SectionColumn>
            <Field
              style={{
                gridColumn: 'auto / span 2',
              }}
            >
              <FieldLabel>Descrição da empresa</FieldLabel>
              <InputTextArea
                defaultValue={user?.company?.description}
                {...register('companyDescription')}
              />
            </Field>
          </FormSection>

          <Title>Modificar senha</Title>
          <FormSection>
            <SectionColumn>
              <Field>
                <FieldLabel htmlFor="new_password">Nova Senha</FieldLabel>
                <FieldInput
                  placeholder="Nova Senha"
                  type="password"
                  id="new_password"
                  autoComplete="new-password"
                  {...register('new_password')}
                />
              </Field>

              <Field>
                <FieldLabel htmlFor="confirm_password">
                  Confirmar Senha
                </FieldLabel>
                <FieldInput
                  placeholder="Confirmar Senha"
                  type="password"
                  id="confirm_password"
                  autoComplete="new-password"
                  {...register('confirm_password')}
                />
              </Field>
            </SectionColumn>

            <Field>
              <FieldLabel htmlFor="old_password">Senha atual</FieldLabel>
              <FieldInput
                type="password"
                placeholder="Senha atual"
                id="old_password"
                autoComplete="new-password"
                {...register('old_password')}
              />
            </Field>

            <div />
          </FormSection>
          <ContainerSave>
            <ButtonSave type="submit">Salvar</ButtonSave>
          </ContainerSave>
        </Form>
      </MainArea>
    </Container>
  );
};
