import styled from 'styled-components';

export const StyledInput = styled.input`
  width: 100%;

  height: 2.875rem;
  border-radius: 0.5rem;
  border: 1px solid #1a1a1a4c;
  margin-bottom: 0.3125rem;
  padding: 0 0.75rem;

  transition: 0.2s ease-in-out;

  &:focus {
    border-color: #acacac;
  }
`;
