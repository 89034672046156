import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }


  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(AiOutlineLoading3Quarters)`
  animation: ${rotate} 1s linear infinite;
`;
