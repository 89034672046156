import { IoClose } from 'react-icons/io5';
import {
  CloseButton,
  Container,
  Content,
  Header,
  PermissionName,
  PermissionToggle,
  PermissionsItem,
  PermissionsList,
  SaveButton,
  Title,
  ToggleLabel,
} from './_permissionsModal';

interface Props {
  onClose: () => void;
}

const PermissionsModal = ({ onClose }: Props) => {
  return (
    <Container>
      <Content>
        <Header>
          <Title>Alterar permissões</Title>
          <CloseButton type="button" onClick={onClose}>
            <IoClose />
          </CloseButton>
        </Header>
        <PermissionsList>
          <PermissionsItem>
            <PermissionName>Cadastrar vagas</PermissionName>
            <ToggleLabel isActive>
              ativo
              <PermissionToggle type="checkbox" />
            </ToggleLabel>
          </PermissionsItem>
          <PermissionsItem>
            <PermissionName>Vagas similares</PermissionName>
            <ToggleLabel isActive={false}>
              inativo
              <PermissionToggle type="checkbox" />
            </ToggleLabel>
          </PermissionsItem>
          <PermissionsItem>
            <PermissionName>Candidatos</PermissionName>
            <ToggleLabel isActive>
              ativo
              <PermissionToggle type="checkbox" />
            </ToggleLabel>
          </PermissionsItem>
        </PermissionsList>
        <SaveButton>Salvar alterações</SaveButton>
      </Content>
    </Container>
  );
};

export default PermissionsModal;
