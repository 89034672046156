import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { AiOutlineArrowDown } from 'react-icons/ai';
import api from '../../services/api';
import handleError from '../../utils/message';
import {
  ButtonAdd,
  ContainerButton,
  ContainerCheck,
  ContainerFilter,
  ContainerInput,
  Form,
  Input,
  OpenFilter,
  SectionInput,
  TitleFilter,
  Value,
} from './_filterDropdown';

export interface FilterDropdownValues {
  city?: string;
  state?: string;
  degrees: string[];
  experience: string[];
  area?: string;
}

interface Props {
  onChangeFilters: (filters: FilterDropdownValues) => void;
  filters: FilterDropdownValues;
}

interface IScholarity {
  id: string;
  name: string;
}

const optionsExperience = [
  { label: '1 - 2 Anos', value: '1-2' },
  { label: '2 - 3 Anos', value: '2-3' },
  { label: '3 - 4 Anos', value: '3-4' },
  { label: '4 - 5 Anos', value: '4-5' },
];

const FilterDropdown = ({ filters, onChangeFilters }: Props) => {
  const [openCity, setOpenCity] = useState(false);
  const [openScholarity, setOpenScholarity] = useState(false);
  const [openExperience, setOpenExperience] = useState(false);
  const [openAreaExp, setOpenAreaExp] = useState(false);
  const [searchCities, setSearchCities] = useState('');
  const [searchStates, setSearchStates] = useState('');
  const [area, setArea] = useState('');
  const [scholarities, setScholarities] = useState<IScholarity[]>([]);

  const toggleScholarities = async (val: string) => {
    const { degrees = [] } = filters;

    if (degrees.includes(val)) {
      onChangeFilters({
        ...filters,
        degrees: degrees.filter(value => value !== val),
      });
    } else {
      onChangeFilters({
        ...filters,
        degrees: [...degrees, val],
      });
    }
  };

  const toggleExperience = async (val: string) => {
    const { experience = [] } = filters;

    if (experience.includes(val)) {
      onChangeFilters({
        ...filters,
        experience: experience.filter(value => value !== val),
      });
    } else {
      onChangeFilters({
        ...filters,
        experience: [...experience, val],
      });
    }
  };

  const getScholarities = async () => {
    try {
      const response = await api.get('/job/option/scholarity');
      setScholarities(response.data.results);
    } catch (error: any) {
      handleError(error);
    }
  };

  /* const getExperience = async () => {
    const formData = new FormData();
    experience.forEach((item, index) => {
      formData.append(`experience[${index}]`, item);
    });
    const formDataScholl = new FormData();
    scholaritiesList.forEach((item, index) => {
      formData.append(`degrees[${index}]`, item);
    });
    try {
      const response = await api.get(`/job/application/jobApplication/${id}`, {
        params: {
          ...Object.fromEntries(formData),
          ...Object.fromEntries(formDataScholl),
          area: areaTeste,
          'cities[]': searchCities,
          'states[]': searchStates,
          'status[0]': 4,
          'status[1]': 3,
        },
      });
      setCandidates(response.data.results);
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  }; */

  /* useEffect(() => {
    getExperience();
  }, [experience, areaTeste, searchCities, searchStates, scholaritiesList]); */

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    onChangeFilters({
      ...filters,
      city: searchCities || undefined,
      state: searchStates || undefined,
      area: area || undefined,
    });
  };

  useEffect(() => {
    getScholarities();
  }, []);

  return (
    <ContainerFilter>
      <TitleFilter>Filtros</TitleFilter>
      <OpenFilter onClick={() => setOpenCity(prev => !prev)}>
        Estado e Cidade <AiOutlineArrowDown />
      </OpenFilter>
      {openCity && (
        <Form onSubmit={handleSubmit}>
          <SectionInput>
            <ContainerInput>
              <Input
                placeholder="Estado"
                value={searchStates}
                onChange={e => setSearchStates(e.target.value)}
              />
              <Input
                placeholder="Cidade"
                value={searchCities}
                onChange={e => setSearchCities(e.target.value)}
              />
            </ContainerInput>
            <ContainerButton>
              <ButtonAdd>Adicionar</ButtonAdd>
            </ContainerButton>
          </SectionInput>
        </Form>
      )}
      <OpenFilter onClick={() => setOpenScholarity(prev => !prev)}>
        Nível de escolaridade <AiOutlineArrowDown />
      </OpenFilter>
      {openScholarity &&
        scholarities.map(itens => (
          <SectionInput>
            <ContainerCheck>
              <Checkbox
                onClick={() => toggleScholarities(itens.id)}
                checked={filters.degrees.includes(itens.id)}
                size="small"
                sx={{
                  color: '#fd1c50',
                  '&.Mui-checked': {
                    color: '#fd1c50',
                  },
                }}
              />
              <Value>{itens.name}</Value>
            </ContainerCheck>
          </SectionInput>
        ))}
      <OpenFilter onClick={() => setOpenExperience(prev => !prev)}>
        Tempo de experiência <AiOutlineArrowDown />
      </OpenFilter>
      {openExperience && (
        <SectionInput>
          {optionsExperience.map(itens => (
            <ContainerCheck>
              <Checkbox
                onClick={() => toggleExperience(itens.value)}
                checked={filters.experience.includes(itens.value)}
                size="small"
                sx={{
                  color: '#fd1c50',
                  '&.Mui-checked': {
                    color: '#fd1c50',
                  },
                }}
              />
              <Value>{itens.label}</Value>
            </ContainerCheck>
          ))}
        </SectionInput>
      )}
      <OpenFilter
        onClick={() => setOpenAreaExp(prev => !prev)}
        style={{
          border: 'none',
        }}
      >
        Áreas de experiência <AiOutlineArrowDown />
      </OpenFilter>
      {openAreaExp && (
        <SectionInput as="form" onSubmit={handleSubmit}>
          <ContainerInput>
            <Input
              placeholder="Digite"
              value={area}
              onChange={e => setArea(e.target.value)}
            />
          </ContainerInput>
          <ContainerButton>
            <ButtonAdd>Adicionar</ButtonAdd>
          </ContainerButton>
        </SectionInput>
      )}
    </ContainerFilter>
  );
};

export default FilterDropdown;
