/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaRegClipboard } from 'react-icons/fa';

import LogoImg from '../../assets/Navbar/logoPng.png';
import IconHomePink from '../../assets/Navbar/homePink.svg';
import IconHomeGray from '../../assets/Navbar/homeGray.svg';
import IconClipPink from '../../assets/Navbar/clipPink.svg';
import IconClipGray from '../../assets/Navbar/clipGray.svg';
import IconJobPink from '../../assets/Navbar/jobPink.svg';
import IconJobGray from '../../assets/Navbar/jobGray.svg';
import IconUserGray from '../../assets/Navbar/userGray.svg';
import IconUserPink from '../../assets/Navbar/user.svg';
import IconExit from '../../assets/Navbar/exit.svg';
import IconModeratorGray from '../../assets/Navbar/moderatorGray.svg';
import IconModeratorPink from '../../assets/Navbar/moderatorPink.svg';

import { useAuth } from '../../hooks/useAuth';

import {
  Button,
  Container,
  Content,
  Icon,
  Logo,
  LogoResponsive,
  Nav,
  Title,
  ButtonMenu,
  ContainerMain,
} from './NavbarStyles';
import { ModeratorPermissions } from '../../types/ModeratorPermissions';

export const Navbar = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const handleNavigation = (value: string) => {
    navigate(value);
  };

  const [currentPage, setCurrentPage] = useState<string>('');

  const getCurrentPage = () => {
    const [, currentNavigation] = pathname.split('/');
    setCurrentPage(currentNavigation);
  };

  useEffect(() => {
    getCurrentPage();
  }, []);

  const [showMenu, setShowMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderIcon = () => {
    if (
      currentPage === 'Candidaturas' ||
      currentPage === 'VagasCadastradas' ||
      currentPage === 'VisualizarVagas' ||
      currentPage === 'Selecionados' ||
      currentPage === 'VisualizarSelecionados'
    ) {
      return IconClipPink;
    }
    return IconClipGray;
  };

  const { role } = user;

  const permission = user?.moderator?.permission || [];

  return (
    <div>
      {windowWidth <= 950 ? (
        <div>
          {showMenu === true ? (
            <ContainerMain>
              <ButtonMenu onClick={() => setShowMenu(!showMenu)}>
                <AiOutlineCloseCircle />
              </ButtonMenu>
              <div>
                <div>
                  <LogoResponsive src={LogoImg} alt="Logo Click Petróleo" />
                </div>
                <div className="alignment">
                  <div>
                    {user.role === 2 && (
                      <Nav
                        onClick={() => handleNavigation('/Inicio')}
                        active={currentPage === 'Inicio'}
                      >
                        <Icon
                          src={
                            currentPage === 'Inicio'
                              ? IconHomePink
                              : IconHomeGray
                          }
                        />
                        <Title>Início</Title>
                      </Nav>
                    )}
                    {role === 2 ||
                      (permission.includes(
                        ModeratorPermissions.CREATE_JOBS,
                      ) && (
                        <Nav
                          onClick={() => handleNavigation('/VagasCadastradas')}
                          active={
                            currentPage === 'Candidaturas' ||
                            currentPage === 'VagasCadastradas' ||
                            currentPage === 'VisualizarVagas' ||
                            currentPage === 'Selecionados' ||
                            currentPage === 'VisualizarSelecionados'
                          }
                        >
                          <Icon src={renderIcon()} />
                          <Title>Vagas</Title>
                        </Nav>
                      ))}
                    {role === 2 ||
                      (permission.includes(
                        ModeratorPermissions.CREATE_JOBS,
                      ) && (
                        <Nav
                          onClick={() => handleNavigation('/CadastroVagas')}
                          active={currentPage === 'CadastroVagas'}
                        >
                          <Icon
                            src={
                              currentPage === 'CadastroVagas'
                                ? IconJobPink
                                : IconJobGray
                            }
                          />
                          <Title>Cadastrar Vagas</Title>
                        </Nav>
                      ))}
                    {(user.role === 2 ||
                      permission.includes(
                        ModeratorPermissions.SIMILAR_JOBS,
                      )) && (
                      <Nav
                        onClick={() => handleNavigation('/Similares')}
                        active={currentPage === 'Similares'}
                      >
                        {currentPage === 'Similares' ? (
                          <FaRegClipboard
                            color="#fd4973"
                            size={30}
                            style={{ marginLeft: '12px' }}
                          />
                        ) : (
                          <FaRegClipboard
                            color="#a3a3a3"
                            size={30}
                            style={{ marginLeft: '12px' }}
                          />
                        )}
                        <Title>Vagas Similares</Title>
                      </Nav>
                    )}
                    {user.role === 2 && (
                      <Nav
                        onClick={() => handleNavigation('/Moderadores')}
                        active={currentPage === 'Moderadores'}
                      >
                        <Icon
                          src={
                            currentPage === 'Moderadores'
                              ? IconModeratorPink
                              : IconModeratorGray
                          }
                        />
                        <Title>Moderadores</Title>
                      </Nav>
                    )}
                    <Nav
                      onClick={() => handleNavigation('/EditarPerfil')}
                      active={currentPage === 'EditarPerfil'}
                    >
                      <Icon
                        src={
                          currentPage === 'EditarPerfil'
                            ? IconUserPink
                            : IconUserGray
                        }
                      />
                      <Title>Editar perfil</Title>
                    </Nav>
                  </div>
                  <Button type="button" onClick={logout}>
                    <Icon
                      src={IconExit}
                      style={{
                        marginLeft: 0,
                      }}
                    />
                    Sair
                  </Button>
                </div>
              </div>
            </ContainerMain>
          ) : (
            <ButtonMenu onClick={() => setShowMenu(!showMenu)}>
              <GiHamburgerMenu />
            </ButtonMenu>
          )}
        </div>
      ) : (
        <Container>
          <Content>
            <div>
              <div>
                <Logo src={LogoImg} alt="Logo Click Petróleo" />
              </div>
              <div className="alignment">
                <div>
                  {user.role === 2 && (
                    <Nav
                      onClick={() => handleNavigation('/Inicio')}
                      active={currentPage === 'Inicio'}
                    >
                      <Icon
                        src={
                          currentPage === 'Inicio' ? IconHomePink : IconHomeGray
                        }
                      />
                      <Title>Início</Title>
                    </Nav>
                  )}
                  {(user.role === 2 ||
                    permission.includes(ModeratorPermissions.CREATE_JOBS)) && (
                    <Nav
                      onClick={() => handleNavigation('/VagasCadastradas')}
                      active={
                        currentPage === 'Candidaturas' ||
                        currentPage === 'VagasCadastradas' ||
                        currentPage === 'VisualizarVagas' ||
                        currentPage === 'Selecionados' ||
                        currentPage === 'VisualizarSelecionados'
                      }
                    >
                      <Icon src={renderIcon()} />
                      <Title>Vagas</Title>
                    </Nav>
                  )}
                  {(user.role === 2 ||
                    permission.includes(ModeratorPermissions.CREATE_JOBS)) && (
                    <Nav
                      onClick={() => handleNavigation('/CadastroVagas')}
                      active={currentPage === 'CadastroVagas'}
                    >
                      <Icon
                        src={
                          currentPage === 'CadastroVagas'
                            ? IconJobPink
                            : IconJobGray
                        }
                      />
                      <Title>Cadastrar Vagas</Title>
                    </Nav>
                  )}
                  {(user.role === 2 ||
                    permission.includes(ModeratorPermissions.SIMILAR_JOBS)) && (
                    <Nav
                      onClick={() => handleNavigation('/Similares')}
                      active={currentPage === 'Similares'}
                    >
                      {currentPage === 'Similares' ? (
                        <FaRegClipboard
                          color="#fd4973"
                          size={30}
                          style={{ marginLeft: '12px' }}
                        />
                      ) : (
                        <FaRegClipboard
                          color="#a3a3a3"
                          size={30}
                          style={{ marginLeft: '12px' }}
                        />
                      )}

                      <Title>Vagas Similares</Title>
                    </Nav>
                  )}
                  {user.role === 2 && (
                    <Nav
                      onClick={() => handleNavigation('/Moderadores')}
                      active={currentPage === 'Moderadores'}
                    >
                      <Icon
                        src={
                          currentPage === 'Moderadores'
                            ? IconModeratorPink
                            : IconModeratorGray
                        }
                      />
                      <Title>Moderadores</Title>
                    </Nav>
                  )}
                  <Nav
                    onClick={() => handleNavigation('/EditarPerfil')}
                    active={currentPage === 'EditarPerfil'}
                  >
                    <Icon
                      src={
                        currentPage === 'EditarPerfil'
                          ? IconUserPink
                          : IconUserGray
                      }
                    />
                    <Title>Editar perfil</Title>
                  </Nav>
                </div>
                <Button type="button" onClick={logout}>
                  <Icon
                    src={IconExit}
                    style={{
                      marginLeft: 0,
                    }}
                  />
                  Sair
                </Button>
              </div>
            </div>
          </Content>
        </Container>
      )}
    </div>
  );
};
