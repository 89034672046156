import { Container, Input, SearchIcon } from './styles';

export interface SearchInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  inputClassName?: string;
}

export const SearchInput = ({
  containerClassName,
  inputClassName,
  ...rest
}: SearchInputProps) => {
  return (
    <Container className={containerClassName}>
      <SearchIcon />
      <Input className={inputClassName} {...rest} />
    </Container>
  );
};
