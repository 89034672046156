// React
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// React Icons
import { AiFillEye } from 'react-icons/ai';
import { BiSearchAlt2 } from 'react-icons/bi';
import { FaTrashAlt } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';

// Material MUI
import Checkbox from '@mui/material/Checkbox';
import { green } from '@mui/material/colors';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

// API
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import api from '../../../../services/api';
import { messageError, messageSuccess } from '../../../../utils/message';

// Components
import { Navbar } from '../../../../components/Navbar/Navbar';
import { Profile } from '../../../../components/Profile/Profile';

import { IJob } from '../../../../types/Job';
import {
  ButtonLinks,
  ButtonRemove,
  ButtonSearch,
  ButtonSelect,
  ButtonTrash,
  Container,
  ContainerBuscas,
  ContainerLinks,
  ContainerSearch,
  ContainerTables,
  ContainerValues,
  DeleteCandidates,
  Header,
  JobLink,
  MainArea,
  Message,
  Pages,
  SearchBar,
  SectionSelect,
  Td,
  Th,
  Tr,
} from './_vagascadastradas';
import LinkHeader from '../LinkHeader/LinkHeader';

const ConfirmSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: green[700],
    '&:hover': {
      backgroundColor: alpha(green[800], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[800],
  },
}));

const limit = 10;

export const VagasCadastradas = () => {
  const navigate = useNavigate();

  const [buscar, setBuscar] = useState('');
  const [jobs, setJobs] = useState<IJob[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [candidatesArray, setCandidatesArray] = useState<number[]>([]);

  const getVacancies = async () => {
    try {
      const response = await api.get('/job/jobs', {
        params: {
          applicant_status: 1,
          limit,
          page,
          search: buscar || undefined,
        },
      });

      setJobs(response.data.results);
      setTotalPages(Math.ceil(response.data.total / limit));
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };
  useEffect(() => {
    getVacancies();
  }, [page, buscar]);

  const toggleSelected = (id: number) => {
    setCandidatesArray(prev => {
      if (prev.includes(id)) return prev.filter(item => item !== id);
      return [...prev, id];
    });
  };

  const handleDeleteSelected = async () => {
    try {
      await api.delete('/job', {
        data: {
          ids: candidatesArray,
        },
      });
      getVacancies();
      messageSuccess('Excluído com sucesso!');
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const updateJobs = async (id: number, is_finished: boolean) => {
    try {
      await api.put(`/job/${id}`, { is_finished });
      getVacancies();
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <Container>
      <Navbar />
      <MainArea>
        <Header>
          <Profile />
        </Header>
        <LinkHeader />

        <ContainerBuscas>
          <ContainerSearch>
            <ButtonSearch>
              <BiSearchAlt2 fontSize={25} color="#DCDCDC" />
            </ButtonSearch>
            <SearchBar
              type="text"
              placeholder="Por Cargo"
              onChange={e => {
                setBuscar(e.target.value);
              }}
            />
          </ContainerSearch>

          <SectionSelect>
            <ButtonTrash onClick={handleDeleteSelected}>
              <FaTrashAlt />
              Excluir
            </ButtonTrash>
          </SectionSelect>
        </ContainerBuscas>
        {jobs.length === 0 ? (
          <DeleteCandidates>
            <Message>Nenhuma Vaga</Message>
          </DeleteCandidates>
        ) : (
          <ContainerValues>
            <ContainerTables>
              <Tr>
                <Th>
                  <Checkbox
                    checked
                    onChange={e => {
                      if (candidatesArray.length === jobs.length) {
                        setCandidatesArray([]);
                      } else {
                        setCandidatesArray(jobs.map(job => job.id));
                      }
                    }}
                    id="select-all"
                    color="default"
                    size="small"
                    style={{
                      color:
                        candidatesArray.length === jobs.length
                          ? '#fd1c50'
                          : '#acacac',
                    }}
                  />
                </Th>
                <Th>ID</Th>
                <Th>Cargo</Th>
                <Th>Visualizações</Th>
                <Th>Candidaturas</Th>
                <Th>Data</Th>
                <Th>Status</Th>
                <Th />
                <Th />
              </Tr>

              {jobs.map((itens: IJob) => (
                <Tr>
                  <Td>
                    <Checkbox
                      checked={candidatesArray.includes(itens.id)}
                      onChange={() => toggleSelected(itens.id)}
                      color="default"
                      size="small"
                      style={{ color: '#fd1c50' }}
                    />
                  </Td>
                  <Td>#{itens.id}</Td>
                  <Td
                    style={{
                      width: '40%',
                      padding: '0 8px',
                    }}
                  >
                    <JobLink
                      to={`/VagasCadastradas/VisualizarVagas/${itens.id}`}
                    >
                      {itens.title || ''}
                    </JobLink>
                  </Td>
                  <Td>{itens.views}</Td>
                  <Td>{itens.application_count}</Td>
                  <Td>{format(parseISO(itens.start_date), 'dd/MM/yyyy')}</Td>
                  <Td>
                    <ConfirmSwitch
                      checked={!itens.is_finished}
                      onChange={() => updateJobs(itens.id, !itens.is_finished)}
                    />
                  </Td>
                  <Td>
                    <ButtonRemove>
                      <MdEdit
                        fontSize={24}
                        color="#707070"
                        onClick={() => navigate(`/EditarJobs/${itens.id}`)}
                      />
                    </ButtonRemove>
                  </Td>
                  <Td>
                    <ButtonRemove
                      onClick={() =>
                        navigate(
                          `/VagasCadastradas/VisualizarVagas/${itens.id}`,
                        )
                      }
                    >
                      <AiFillEye fontSize={24} color="#707070" />
                    </ButtonRemove>
                  </Td>
                </Tr>
              ))}
            </ContainerTables>
          </ContainerValues>
        )}
        <Pages>
          <Stack spacing={1}>
            <Pagination
              page={page}
              count={totalPages}
              onChange={(e, pag) => {
                setPage(pag);
              }}
              shape="rounded"
              size="small"
            />
          </Stack>
        </Pages>
      </MainArea>
    </Container>
  );
};
