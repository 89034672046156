import styled from 'styled-components';

export const Container = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;

  color: #1a1a1a;

  cursor: pointer;
`;

export const Input = styled.input<{
  checkboxSize: string;
}>`
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;

  &:before {
    box-sizing: border-box;

    font-family: 'FontAwesome';

    content: '\f00c';
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${props => props.checkboxSize};
    height: ${props => props.checkboxSize};
    margin-right: 0.5rem;
    font-size: 0.75rem;
    color: transparent !important;
    background-color: #fff;
    border: 2px solid #fd1c50;
    border-radius: 3px;
  }

  &:checked:before {
    color: #fff !important;
    background-color: #fd1c50;
    border: 2px solid #fd1c50;
  }

  &:focus:before {
    border: 2px solid #fd1c50;
  }
`;

export const Label = styled.span`
  font-size: inherit;
  font-weight: inherit;

  color: inherit;
`;
