import { forwardRef } from 'react';
import { StyledInput } from './_maskedInput';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  maskFunction: (value: string) => string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MaskedInput = forwardRef<HTMLInputElement, Props>(
  ({ onChange, maskFunction, ...rest }, ref) => {
    const handleMask = (event: React.ChangeEvent<HTMLInputElement>) => {
      const masked = maskFunction(event.target.value);
      event.target.value = masked;
      onChange(event);
    };

    return <StyledInput ref={ref} onChange={handleMask} {...rest} />;
  },
);
