import { ToggleInput, ToggleLabel } from './_toggle';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  showLabel?: boolean;
  activeLabel?: string;
  inactiveLabel?: string;
}

const Toggle = ({
  showLabel = true,
  activeLabel = 'ativo',
  inactiveLabel = 'inativo',
  ...rest
}: Props) => {
  return (
    <ToggleLabel isActive={!!rest.checked}>
      {showLabel && (rest.checked ? activeLabel : inactiveLabel)}
      <ToggleInput type={rest.type || 'checkbox'} {...rest} />
    </ToggleLabel>
  );
};

export default Toggle;
