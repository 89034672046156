import Select from 'react-select';
import Creatable from 'react-select/creatable';
import styled from 'styled-components';
import { ReactComponent as Calendar } from '../../../../assets/CadastroVendas/calendar.svg';
import { ReactComponent as Email } from '../../../../assets/CadastroVendas/emailIcon.svg';
import { ReactComponent as Link } from '../../../../assets/CadastroVendas/linkIcon.svg';
import { ReactComponent as Whatsapp } from '../../../../assets/CadastroVendas/whatsappIcon.svg';

import { ReactComponent as ArrowLeft } from '../../../../assets/Cadastro/arrowIcon.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/CadastroVendas/arrowRight.svg';

export const Teste = styled.div`
  display: block;

  #information {
    font-size: 0.8rem;
    color: var(--pink);
  }

  span {
    display: block;
    font-weight: 500;
  }
`;

export const Container = styled.div`
  //border: 1px solid red;
  height: 100vh;
  display: flex;
  /* align-items: center; */
  background: #fbfbfb;
  color: var(--black);
`;

export const OtherMethods = styled.div`
  width: 100%;

  display: flex;
  gap: 2rem;

  padding-bottom: 1rem;

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

export const MessagesArea = styled.div`
  display: flex;

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  justify-content: space-between;

  #selecionados {
    margin-right: 1.5rem;
  }
`;
export const WhatsappIcon = styled(Whatsapp)`
  width: auto;
  height: auto;
`;

export const LinkIcon = styled(Link)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.625rem;
`;

export const EmailIcon = styled(Email)`
  width: auto;
  height: auto;
`;

export const CalenderIcon = styled(Calendar)`
  width: auto;
  height: auto;
  padding-right: 5px;
`;

export const DateComponent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .endDateComponent {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 266px;
    height: 36px;
    border: solid 1px #dcdcdc;
    border-radius: 10px;
    cursor: pointer;
  }
`;
export const InputArea = styled.div`
  width: 100%;

  display: flex;
  margin-top: 10px;
  margin-right: 20px;

  .simpleInput {
    width: 17.1875rem;
    height: 2.5rem;
    border: solid 1px #dcdcdc;
    border-radius: 0.625rem;
    padding-left: 5px;
  }

  .largeInput {
    width: 28.875rem;
    height: 7rem;
    padding-left: 0.625rem;
    padding-top: 0.625rem;
  }

  span {
  }

  #information {
    color: var(--pink);
  }
`;

export const IconInputField = styled.div`
  width: 100%;
  max-width: 17.5rem;

  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: bold;
  color: #1a1a1a7f;
`;

export const IconInputContainer = styled.div`
  border: 1px solid #dcdcdc;

  background: #fff;

  width: 100%;

  display: flex;
  align-items: center;
  gap: 0.375rem;
  height: 2.5rem;
  border-radius: 0.625rem;
`;

export const IconImage = styled.img`
  position: relative;
  left: -1px;

  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
`;

export const IconInput = styled.input`
  border: none;

  width: 100%;
  background: #fff;
  font-size: 0.8rem;
`;

export const Textarea = styled.textarea`
  width: 100%;
  max-width: 28rem;
  height: 7rem;

  border: 1px solid #dcdcdc;
  border-radius: 0.5rem;

  opacity: 1;
  padding: 0.5rem;

  font-size: 0.8rem;

  outline: none;
  resize: none;
`;

export const SessionTitle = styled.h1`
  font-size: 18px;
  margin: 2em 0px;
`;
export const CheckboxSession = styled.div`
  span {
    padding-left: 5px;
    color: var(--medium-gray);
  }
`;
export const Message = styled.span`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 1em;
  color: var(--medium-gray);

  span {
    color: var(--pink);
  }
`;
export const OtherEmails = styled.span`
  color: var(--medium-gray);
  font-size: 14px;
  font-weight: 400;
`;
export const CalendarContainer = styled.div`
  box-shadow: 0px 2px 3px black;
  border-radius: 20px;
  background: white;
  overflow: hidden;
  .react-datepicker__month-container {
    width: 266px;
    height: 280px;
    background-color: #ffffff;
    border-radius: 15px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
  }
  .react-datepicker {
    margin-top: 5px;
    border: none;
  }
  .react-datepicker__current-month {
    display: flex;
    font-size: 16px;
    margin-left: 1em;
  }
  .react-datepicker__navigation--previous {
    left: 200px;
  }
  .react-datepicker__header {
    background-color: #ffffff;
    border-bottom: none;
  }
  .react-datepicker__day-names {
    width: 258px;
    height: 37px;
    font-size: 9px;
    color: #3e3e3e;
    margin-top: 1em;
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: var(--pink);
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: var(--pink);
    color: #fff;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #fd1c5030;
    color: #fff;
  }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: #fd1c5030;
  }
  .alignment {
    display: flex;
    justify-content: end;

    button {
      width: 57px;
      height: 31px;
      margin-right: 1.5em;
      margin-bottom: 8px;
    }
  }
`;
export const TextBoxComponent = styled.div``;

export const SpanError = styled.span`
  font-size: 0.8rem;
  color: var(--pink);
`;

export const Form = styled.form`
  overflow-x: scroll;
  height: 90vh;
  padding: 10px;
`;

export const Content = styled.div`
  display: flex;
`;

export const ContentTwo = styled.div`
  margin-left: 30px;
`;

export const Title = styled.div`
  font-weight: 600;
  span {
    color: var(--pink);
  }
`;

export const ArrowIconLeft = styled(ArrowLeft)`
  width: auto;
  height: auto;
`;

export const ArrowIconRight = styled(ArrowRight)`
  width: auto;
  height: auto;
`;

export const Input = styled.input`
  height: 40px;

  border: 1px solid #dcdcdc;
  border-radius: 8px;
  opacity: 1;

  text-indent: 5px;

  & + & {
    margin-top: 0.5rem;
  }
  outline: none;

  &:focus {
    border-color: var(--pink);
    box-shadow: var(--pink);
  }
`;

export const Section = styled.div`
  margin-top: 1.5rem;
  margin-left: 2rem;

  .multi-select {
    width: 380px;
  }

  .basic-select {
    width: 230px;
  }

  #salario {
    width: 70px;
  }

  #descricao {
    width: 462px;
    height: 113px;

    border: 1px solid #dcdcdc;
    border-radius: 8px;

    opacity: 1;
    padding: 10px;

    font-size: 0.8rem;

    outline: none;
  }

  #caracatere {
    display: flex;
    justify-content: flex-end;

    font-size: 0.7rem;
    font-weight: 500;
    color: #1a1a1a4d;
  }
`;
export const SectionWrap = styled.div`
  display: flex;
`;

export const customStyles = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,

    color: state.isSelected ? 'white' : 'black',
    background: state.isSelected ? '#FD1C50D1' : 'white',

    ':hover': {
      backgroundColor: '#fd1c5033',
    },
  }),
};

export const SelectBasic = styled(Select)`
  .react-select__menu-list {
    font-size: 14px;

    &::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      background: #e9e9e9;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--pink);
      border-radius: 10px;
      height: 120px;
    }
  }

  .react-select__control {
    min-height: 40px;
    border-radius: 8px;

    color: var(--lotion);
    border: 1px solid #dcdcdc;
    box-shadow: none;
  }

  .react-select,
  .react-select__control--is-focused.react-select__control--menu-is-open {
    border-color: var(--pink);
    box-shadow: var(--pink);
  }
`;

export const SelectCreate = styled(Creatable)`
  .react-select__menu-list {
    font-size: 14px;

    &::-webkit-scrollbar {
      width: 2px;
      height: 120px;
    }

    ::-webkit-scrollbar-track {
      background: #e9e9e9;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--pink);
      border-radius: 10px;
    }
  }

  .react-select__control {
    border-radius: 8px;
    min-height: 40px;
    color: var(--lotion);
    border: 1px solid #dcdcdc;
    box-shadow: none;
  }

  .react-select,
  .react-select__control--is-focused.react-select__control--menu-is-open {
    border-color: var(--pink);
    box-shadow: var(--pink);
  }
  .react-select__multi-value {
    background-color: #fd1c5033;
    border-radius: 8px;
  }

  .react-select__multi-value__remove {
    color: var(--black);
    &:focus {
      background-color: transparent;
    }
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: flex-end;

  padding-top: 0.8rem;

  background: #fbfbfb;

  button {
    width: 147px;
    height: 48px;

    display: flex;
    align-items: center;

    justify-content: center;

    background-color: #ffffff;

    border: 1px solid #dcdcdc;
    border-radius: 8px;

    & + button {
      margin-left: 1rem;
    }
  }
  #voltar {
    padding-left: 16px;

    font-size: 1rem;
    font-weight: 600;
    color: var(--medium-gray);
  }
  #proximo {
    padding-right: 16px;

    font-size: 1rem;
    font-weight: 600;
    color: var(--medium-gray);
  }
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

export const SelectEstado = styled.select`
  height: 40px;
  width: 170px;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  color: var(--medium-gray);
  padding: 5px;
  font-size: 1rem;
`;

export const OptionEstado = styled.option``;

export const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2em;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
`;

export const Hr = styled.hr`
  border: 1px solid #dcdcdc;
  width: 70%;
  margin: 30px auto;
`;

export const ButtonEdit = styled.button`
  border: 1px solid #787878;
  background-color: transparent;
  width: 160px;
  height: 50px;
  color: var(--medium-gray);
  margin: 20px 0;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: end;
  width: 96%;
`;

export const SelectUf = styled.select`
  height: 40px;
  font-size: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  opacity: 1;
  margin-top: 45px;
  margin-left: 30px;
  background-color: transparent;
  color: var(--medium-gray);
`;

export const OptionUf = styled.option`
  background-color: #fff;
`;

export const SelectCity = styled.select`
  height: 40px;
  font-size: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  opacity: 1;
  margin-top: 45px;
  margin-left: 30px;
  background-color: transparent;
  color: var(--medium-gray);
`;

export const OptionCity = styled.option`
  background-color: #fff;
`;
