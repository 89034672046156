// React
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// React Icons
import { BiSearchAlt2 } from 'react-icons/bi';
import { FaTrashAlt } from 'react-icons/fa';

// Material MUI
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

// Components
import { Navbar } from '../../../../components/Navbar/Navbar';
import { Profile } from '../../../../components/Profile/Profile';

// Api
import api from '../../../../services/api';
import { messageError } from '../../../../utils/message';

import { IAplication } from '../../../../types/Aplication';
import {
  ButtonDelete,
  ButtonLinks,
  ButtonSearch,
  ButtonSelect,
  Container,
  ContainerBuscas,
  ContainerLinks,
  ContainerScroll,
  ContainerSearch,
  ContainerTables,
  DeleteCandidates,
  Header,
  MainArea,
  Message,
  Pages,
  SearchBar,
  SectionSelect,
  Td,
  Th,
  TotalCadidaturas,
  Tr,
  Values,
} from './_candidaturas';
import { maskPhone } from '../../../../utils/masks';

const limit = 10;

const label = { inputProps: { 'aria-label': '' } };

export const Candidaturas = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const [currentPage, setCurrentPage] = useState('');
  const [buscar, setBuscar] = useState('');

  const [candidatesList, setCandidatesList] = useState<IAplication[]>([]);
  const [candidatesArray, setCandidatesArray] = useState<string[]>([]);
  const [candidatesTotal, setCandidatesTotal] = useState(0);

  const [selectAll, setSelectAll] = useState(false);
  const [select, setSelect] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getCurrentPage = () => {
    const [, currentNavigation] = pathname.split('/');
    setCurrentPage(currentNavigation);
  };

  useEffect(() => {
    getCurrentPage();
  }, []);

  const handleSelect = () => {
    setSelect(!select);
    setSelectAll(false);
  };

  const handleNavigation = (value: string) => {
    navigate(value);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelect(false);

    if (candidatesArray.length === candidatesList.length) {
      setCandidatesArray([]);
    } else {
      setCandidatesArray(candidatesList.map(candidate => candidate.id));
    }
  };

  const getCandidates = async () => {
    try {
      const response = await api.get(
        `/job/application${buscar && `?search=${buscar}`}`,
        { params: { limit, page } },
      );
      setCandidatesList(response.data.results);
      setTotalPages(Math.ceil(response.data.total / limit));
      setCandidatesTotal(response.data.total);
      // if (buscar) {
      //   setPage(1);
      //   setCandidatesList(response.data.results);
      // }
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const toggleSelected = (id: string) => {
    setCandidatesArray(prev => {
      if (prev.includes(id)) return prev.filter(item => item !== id);
      return [...prev, id];
    });
  };

  const handleDeleteSelected = async () => {
    if (candidatesArray.length === 0) {
      return;
    }

    try {
      await api.delete('/job/application', {
        data: {
          ids: candidatesArray,
        },
      });
      getCandidates();
      toast.success('Excluido com sucesso!');
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getCandidates();
  }, [page, buscar]);

  return (
    <Container>
      <Navbar />
      <MainArea>
        <Header>
          <Profile />
        </Header>
        <ContainerLinks>
          {/* <ButtonLinks activeLink={currentPage === 'Candidaturas'}>
            Candidaturas
          </ButtonLinks> */}
          <ButtonLinks
            onClick={() => handleNavigation('/VagasCadastradas')}
            activeLink={currentPage === '/VagasCadastradas'}
          >
            Vagas Cadastradas
          </ButtonLinks>
          <ButtonLinks
            activeLink={currentPage === '/Vagas'}
            onClick={() => handleNavigation('/Selecionados')}
          >
            Selecionados
          </ButtonLinks>
        </ContainerLinks>
        <ContainerBuscas>
          <ContainerSearch>
            <ButtonSearch>
              <BiSearchAlt2 fontSize={25} color="#DCDCDC" />
            </ButtonSearch>
            <SearchBar
              placeholder="Buscar"
              value={buscar}
              onChange={e => {
                setBuscar(e.target.value);
              }}
            />
          </ContainerSearch>

          <SectionSelect>
            <ButtonDelete onClick={handleDeleteSelected}>
              <FaTrashAlt />
              Excluir
            </ButtonDelete>
            <TotalCadidaturas>
              <Values>{candidatesTotal}</Values>Candidaturas
            </TotalCadidaturas>

            <ButtonSelect onClick={handleSelect} active={select === true}>
              Selecionar
            </ButtonSelect>
            <ButtonSelect onClick={handleSelectAll} active={selectAll === true}>
              Selecionar tudo
            </ButtonSelect>
          </SectionSelect>
        </ContainerBuscas>
        {candidatesList.length === 0 ? (
          <DeleteCandidates>
            <Message>Nenhuma candidatura</Message>
          </DeleteCandidates>
        ) : (
          <ContainerScroll>
            <ContainerTables>
              <Tr>
                <Th />
                <Th>ID</Th>
                <Th>Nome</Th>
                <Th>E-mail</Th>
                <Th>Última Experiência</Th>
                <Th>Contato</Th>
              </Tr>

              {candidatesList.map((itens: any) => (
                <Tr>
                  <div>
                    {select && (
                      <Checkbox
                        {...label}
                        checked={candidatesArray.includes(itens.id)}
                        onChange={() => {
                          toggleSelected(itens.id);
                        }}
                        color="default"
                        size="small"
                        style={{ color: '#fd1c50' }}
                      />
                    )}

                    {selectAll && (
                      <Checkbox
                        {...label}
                        checked={itens.isSelected}
                        onChange={() => {
                          toggleSelected(itens.id);
                        }}
                        defaultChecked
                        color="default"
                        size="small"
                        style={{ color: '#fd1c50' }}
                      />
                    )}
                  </div>
                  <Td>{`#${itens.id}`}</Td>
                  <Td>{itens.person.name}</Td>
                  <Td>{itens.person.user.email}</Td>
                  <Td>
                    {!itens.person.job_history?.[0]?.area
                      ? 'Não informado'
                      : itens.person.job_history?.[0]?.area}
                  </Td>
                  <Td>{maskPhone(itens.person.phone) || 'Não informado'}</Td>
                </Tr>
              ))}
            </ContainerTables>
          </ContainerScroll>
        )}
        <Pages>
          <Stack spacing={1}>
            <Pagination
              page={page}
              count={totalPages}
              onChange={(e, pag) => {
                setPage(pag);
              }}
              shape="rounded"
              size="small"
            />
          </Stack>
        </Pages>
      </MainArea>
    </Container>
  );
};
