import * as Yup from 'yup';

export type SignupForm = Yup.InferType<typeof SignupSchema>;

export const SignupSchema = Yup.object({
  email: Yup.string().required('E-mail obrigatório').email('E-mail inválido'),
  password: Yup.string()
    .required('Senha obrigatória')
    .min(8, 'Mínimo 8 caracteres')
    .max(20, 'Máximo 20 caracteres'),
  password_confirm: Yup.string()
    .required('Confirme a senha')
    .min(8, 'Mínimo 8 caracteres')
    .max(20, 'Máximo 20 caracteres')
    .oneOf([Yup.ref('password'), null], 'As senhas não conferem'),
  company: Yup.object({
    name: Yup.string().required('Razão social obrigatória'),
    phone: Yup.string()
      .required('Telefone obrigatório')
      .min(10, 'Telefone inválido')
      .max(11, 'Telefone inválido'),
    cnpj: Yup.string().required('CNPJ obrigatório').length(14, 'CNPJ inválido'),
  }).required(),
});
