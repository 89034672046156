import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface ButtonSelect {
  active: boolean;
}

interface ButtonLinks {
  activeLink: boolean;
}

export const Container = styled.div`
  //border: 1px solid red;
  width: 100%;
  height: 100vh;
  display: flex;
  /* align-items: center; */
  background: #fbfbfb;
  color: var(--black);
`;

export const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2em;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
`;

export const ContainerLinks = styled.div`
  margin-bottom: 10px;
  display: flex;
  @media screen and (max-width: 1000px) {
    margin: 0 auto;
  }
`;

export const ButtonLinks = styled.button<ButtonLinks>`
  background-color: transparent;
  color: ${Props => (Props.activeLink ? '#1a1a1a' : '#707070')};
  margin: 0 50px 0 0;

  border-bottom: ${Props =>
    Props.activeLink ? '#FD1C50 solid 2px' : '#707070'};
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0px;
`;

export const ContainerBuscas = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media screen and (max-width: 1100px) {
    justify-content: center;

    gap: 1rem;
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const ButtonSearch = styled.button`
  display: flex;
  align-items: center;
  /* Position para o icone */
  position: absolute;
  border: none;
  background-color: #fff;
  margin: 0 10px;
`;

export const SearchBar = styled.input`
  width: 400px;
  height: 40px;
  text-indent: 40px;
  letter-spacing: 1px;
  border-radius: 5px;
  border: #dcdcdc 1px solid;
`;

export const SectionSelect = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 1200px) {
    margin: 20px 0;
  }
`;

export const ButtonSelect = styled.button<ButtonSelect>`
  border: none;
  background-color: transparent;
  color: #000;
  color: ${Props => (Props.active ? '#000' : '#707070')};
  transition: 250ms;
  margin-right: 20px;

  :hover {
    transition: 250ms;
    color: #252525;
  }
`;

export const ContainerTables = styled.table`
  // margin-top: 30px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`;

export const Tr = styled.tr``;

export const Th = styled.th`
  position: sticky;
  top: 0;

  z-index: 1;

  background: #fbfbfb;

  text-align: start;

  padding: 0.5rem 0.25rem;

  border-bottom: 1px solid #cacaca;

  @media screen and (max-width: 850px) {
    font-size: 0.7rem;
  }
`;

export const Td = styled.td`
  padding: 0.25rem;
  color: #707070;
  border-bottom: 1px solid #dcdcdc;

  td:first-child {
    font-weight: bold;
    color: #1a1a1a;
  }

  @media screen and (max-width: 850px) {
    font-size: 0.8rem;
  }
`;

export const JobLink = styled(NavLink)`
  color: #707070;
  text-decoration: none;
  font-size: inherit;
  cursor: pointer;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ButtonRemove = styled.button`
  background-color: #fff;
  border: none;
  display: flex;
  padding: 5px;
  transition: 300ms;

  cursor: pointer;

  :hover {
    box-shadow: 0px 3px 15px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const ButtonTrash = styled.button`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: space-around;
  margin-right: 20px;

  padding: 0.25rem 0.5rem;

  border: 1px solid #dcdcdc;
  border-radius: 0.5rem;
  background-color: #fff;

  color: #707070;

  transition: 300ms;

  :hover {
    transition: 300ms;
    color: #626262;
    box-shadow: 1px 3px 5px 5px rgba(0, 0, 0, 0.12);
  }
`;

export const ContainerValues = styled.div`
  margin-top: 1.75rem;

  padding-right: 1rem;

  overflow: auto;

  position: relative;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--pink);
    border-radius: 10px;
    height: 120px;
  }
`;

export const DeleteCandidates = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

export const Message = styled.p`
  font-weight: bold;
  font-size: 1.1rem;
`;

export const Pages = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: end;
`;
