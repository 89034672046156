import styled from 'styled-components';

interface ButtonSelect {
  active: boolean;
}

export const Container = styled.div`
  //border: 1px solid red;
  width: 100%;
  height: 100vh;
  display: flex;
  /* align-items: center; */
  background: #fbfbfb;
  color: var(--black);
`;

export const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2em;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
`;

export const ContainerBuscas = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media screen and (max-width: 1000px) {
    margin: 20px;
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  @media screen and (max-width: 1000px) {
    margin: 20px;
    align-items: center;
    justify-items: center;
  }
`;

export const ButtonSearch = styled.button`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* Position para o icone */
  position: absolute;
  border: none;
  background-color: #fff;
  margin: 0 10px;
`;

export const SearchBar = styled.input`
  width: 280px;
  height: 40px;
  text-indent: 40px;
  letter-spacing: 1px;
  border-radius: 5px;
  border: #dcdcdc 1px solid;
`;

export const SectionSelect = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const ButtonSelect = styled.button<ButtonSelect>`
  border: none;
  margin-left: 20px;
  background-color: transparent;
  color: #000;
  color: ${Props => (Props.active ? '#000' : '#707070')};
  transition: 250ms;

  :hover {
    transition: 250ms;
    color: #252525;
  }
`;

export const TableContainer = styled.div`
  margin-top: 1.75rem;

  padding-right: 1rem;

  overflow: auto;

  position: relative;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--pink);
    border-radius: 10px;
    height: 120px;
  }
`;

export const Table = styled.table`
  width: 100%;

  border-collapse: separate;
  border-spacing: 0;
`;

export const Tr = styled.tr``;

export const Th = styled.th`
  position: sticky;
  top: 0;

  z-index: 1;

  background: #fbfbfb;

  text-align: start;

  padding: 0.5rem 0.25rem;

  border-bottom: 1px solid #cacaca;

  @media screen and (max-width: 850px) {
    font-size: 0.7rem;
  }
`;

export const Td = styled.td`
  padding: 0.25rem;
  color: #707070;
  border-bottom: 1px solid #dcdcdc;
  td:first-child {
    font-weight: bold;
    color: #1a1a1a;
  }
`;

export const ButtonRemove = styled.a`
  background-color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2.125rem;
  width: 2.125rem;

  border-radius: 8px;

  transition: 300ms;

  cursor: pointer;

  :hover {
    box-shadow: 0px 3px 15px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const ContainerResult = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-top: 20px;
  box-shadow: 1px 3px 5px 5px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
`;

export const SectionTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
`;

export const SectionRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Text = styled.p`
  margin-left: 10px;
`;

export const ButtonEncerrar = styled.button`
  border: none;
  background-color: #fff;

  color: #fd1c50;
`;

export const ButtonBack = styled.button`
  border: none;
  background: transparent;
  color: var(--black);
  display: flex;
`;

export const SwitchTd = styled.td``;

export const ButtonFilter = styled.button`
  background-color: #fff;
  color: #707070;
  border-radius: 5px;
  height: 40px;
  width: 100px;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.98);
  }
`;

export const ContainerFilter = styled.div`
  position: absolute;
  margin-left: 10px;
  background-color: #fff;
  padding: 10px;
  width: 250px;
  box-shadow: 0px 3px 15px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

export const ContainerFilterMain = styled.div``;

export const ContainerSelect = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const ButtonFunction = styled.button`
  border: 1px solid #dcdcdc;
  background-color: #fff;
  color: #707070;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: space-around;
  transition: 300ms;

  padding: 0.25rem 0.5rem;

  :hover {
    transition: 300ms;
    color: #626262;
    box-shadow: 1px 3px 5px 5px rgba(0, 0, 0, 0.12);
  }
`;

export const ButtonDownload = styled.button`
  border: 1px solid #dcdcdc;
  background-color: #fff;
  color: #707070;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 30px;
  width: 160px;
  transition: 300ms;

  :hover {
    transition: 300ms;
    color: #626262;
    box-shadow: 1px 3px 5px 5px rgba(0, 0, 0, 0.12);
  }
`;

export const ContainerMover = styled.div`
  position: relative;

  margin-left: 10px;
`;

export const ContainerProps = styled.div`
  margin-top: 0.625rem;
  max-width: 100%;
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
`;

export const ButtonFilters = styled.button`
  border-radius: 18px;
  border: 1px solid var(--pink);
  background-color: #fbeff2;
  color: var(--black);

  display: flex;
  align-items: center;
  gap: 0.5rem;

  height: 2rem;
  width: fit-content;
  padding: 0 0.375rem 0 1rem;
`;

export const FilterText = styled.p`
  color: var(--black);
  font-size: 1rem;
`;

export const DeleteButton = styled.button`
  border: none;
  background-color: #fbeff2;
  display: flex;
  align-items: center;
  color: var(--pink);
  font-size: 1.625rem;
  padding: 0;
`;

export const ContainerFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteCandidates = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

export const Message = styled.p`
  font-weight: bold;
  font-size: 1.1rem;
`;

export const ContentMain = styled.div``;

export const Pages = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: end;
`;

export const DisplayFlex = styled.div``;

export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
