import styled from 'styled-components';
import { IAplication } from '../../../../../types/Aplication';

interface ButtonLinks {
  activeLink: boolean;
}

interface ButtonAprovado {
  active: number;
}

export const Container = styled.div`
  //border: 1px solid red;
  width: 100%;
  height: 100vh;
  display: flex;
  /* align-items: center; */
  background: #fbfbfb;
  color: var(--black);
`;

export const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2em;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
`;

export const ContainerLinks = styled.div`
  margin-bottom: 10px;
  display: flex;
  @media screen and (max-width: 1000px) {
    margin: 10px auto;
  }
`;

export const ButtonLinks = styled.button<ButtonLinks>`
  background-color: transparent;
  color: ${Props => (Props.activeLink ? '#1a1a1a' : '#707070')};
  margin: 0 50px 0 0;

  border-bottom: ${Props =>
    Props.activeLink ? '#FD1C50 solid 2px' : '#707070'};
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0px;
`;

export const ContainerBuscas = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media screen and (max-width: 1000px) {
    justify-content: center;
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  @media screen and (max-width: 1000px) {
    margin: 20px;
    align-items: center;
    justify-items: center;
  }
`;

export const ButtonSearch = styled.button`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* Position para o icone */
  position: absolute;
  border: none;
  background-color: #fff;
  margin: 0 10px;
`;

export const SearchBar = styled.input`
  width: 280px;
  height: 40px;
  text-indent: 40px;
  letter-spacing: 1px;
  border-radius: 5px;
  border: #dcdcdc 1px solid;
`;

export const ContainerFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerFilterMain = styled.div``;

export const ButtonFilter = styled.button`
  background-color: #fff;
  border-radius: 5px;
  color: #707070;
  height: 40px;
  width: 100px;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.98);
  }
`;

export const ContainerTables = styled.table`
  margin-top: 30px;
  width: 100%;
  border-collapse: collapse;
`;

export const Tr = styled.tr``;

export const Th = styled.th`
  text-align: start;
  padding: 5px 0;
`;

export const Td = styled.td`
  padding: 5px;
  color: #707070;
  border-bottom: 1px solid #dcdcdc;

  td:first-child {
    font-weight: bold;
    color: #1a1a1a;
  }

  @media screen and (max-width: 1000px) {
    font-size: 0.8rem;
  }
`;

export const ButtonRemove = styled.button`
  background-color: #fff;
  border: none;
  display: flex;
  padding: 5px;
  transition: 300ms;

  :hover {
    transition: 300ms;
    box-shadow: 0px 3px 15px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const ContainerResult = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-top: 20px;
  box-shadow: 1px 3px 5px 5px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
`;

export const SectionTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
`;

export const ButtonBack = styled.button`
  border: none;
  background-color: #fbfbfb;
  color: #000;
  display: flex;
`;

export const Text = styled.p`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
`;

export const SectionRight = styled.div`
  display: flex;
`;

export const Values = styled.div`
  margin-right: 10px;
  display: flex;
`;

export const ButtonAprovado = styled.button<ButtonAprovado>`
  background-color: #fff;
  color: #707070;
  border-radius: 2px;
  padding: 5px 10px;
  border: 1px solid #707070;
  border: ${Props => (Props.active ? '1px solid #4AAA4A' : '')};
  background-color: ${Props => (Props.active ? '#E5EFE5' : '')};
  color: ${Props => (Props.active ? '#4AAA4A' : '')};
  width: 100px;
  margin: 5px 2px;

  &:disabled {
    background-color: #e5efe5;
    color: #4aaa4a;
  }
`;

export const DownloadLink = styled.a`
  background-color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 3px;
  transition: 300ms;
  border-radius: 0.5rem;

  :hover {
    transition: 300ms;
    box-shadow: 0px 3px 15px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const DeleteCandidates = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

export const Message = styled.p`
  font-weight: bold;
  font-size: 1.1rem;
`;

export const ButtonReprovado = styled.button<ButtonAprovado>`
  background-color: #fff;
  color: #707070;
  border-radius: 2px;
  padding: 5px 10px;
  border: 1px solid #707070;

  border: ${Props => (Props.active ? '1px solid #FD1C50' : '')};
  background-color: ${Props => (Props.active ? '#fecbcb' : '')};
  color: ${Props => (Props.active ? '#FD1C50' : '')};
  margin-left: 2px;

  white-space: nowrap;

  &:disabled {
    background-color: #fecbcb;
    color: #fd1c50;
  }
`;

export const Pages = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: end;
`;
