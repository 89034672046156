export enum ModeratorPermissions {
  CREATE_JOBS,
  SIMILAR_JOBS,
  CANDIDATES,
}

export const permissionLabels: Record<ModeratorPermissions, string> = {
  [ModeratorPermissions.CREATE_JOBS]: 'Cadastrar vagas',
  [ModeratorPermissions.SIMILAR_JOBS]: 'Vagas similares',
  [ModeratorPermissions.CANDIDATES]: 'Candidatos',
};

export const permissionOptions = Object.entries(permissionLabels).map(
  ([permission, label]) => ({
    value: Number(permission),
    label,
  }),
);
