import { useEffect, useState } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Pagination, Stack } from '@mui/material';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { Navbar } from '../../components/Navbar/Navbar';
import PermissionsModal from '../../components/PermissionsModal/PermissionsModal';
import { Profile } from '../../components/Profile/Profile';
import { SearchInput } from '../../components/SearchInput/SearchInput';
import { User } from '../../hooks/useAuth';
import api from '../../services/api';
import { Paginated } from '../../types/Paginated';
import handleError, { messageSuccess } from '../../utils/message';
import {
  ActionButtonsContainer,
  Container,
  CreateButton,
  FilterSection,
  Header,
  IconButton,
  LoadingWrapper,
  MainArea,
  Pages,
  SearchForm,
  Table,
  TableData,
  TableHeading,
  TableWrapper,
  Title,
} from './_moderadores';
import { Loading } from '../../components/Loading/Loading';

const limit = 10;

const Moderadores = () => {
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState('');
  const [moderators, setModerators] = useState<User[]>([]);
  const [page, setPage] = useState(1);
  const [maximumPage, setMaximumPage] = useState(1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const getModerators = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get<Paginated<User>>('/user/moderator');

      setModerators(data.results);
      setMaximumPage(Math.ceil(data.total / limit));
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getModerators();
  }, []);

  const confirmDeleteModerator = async () => {
    try {
      await api.delete(`/user/moderator/${deletingUserId}`);
      setDeletingUserId('');
      messageSuccess('Moderador excluído com sucesso!');
      getModerators();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Container>
      <Navbar />
      <MainArea>
        <Header>
          <Title>Moderadores</Title>
          <Profile />
        </Header>
        <FilterSection>
          <SearchForm>
            <SearchInput placeholder="Pesquisar moderadores" />
          </SearchForm>
          <CreateButton
            type="button"
            onClick={() => navigate('/Moderadores/Cadastrar')}
          >
            Cadastrar
          </CreateButton>
        </FilterSection>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <TableHeading>Nome</TableHeading>
                <TableHeading>E-mail</TableHeading>
                <TableHeading style={{ width: '15%' }}>Ações</TableHeading>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={3}>
                    <LoadingWrapper>
                      <Loading size={32} />
                    </LoadingWrapper>
                  </td>
                </tr>
              )}
              {moderators.map(moderator => (
                <tr>
                  <TableData>{moderator.username}</TableData>
                  <TableData>{moderator.email}</TableData>
                  <TableData>
                    <ActionButtonsContainer>
                      <IconButton
                        type="button"
                        onClick={() =>
                          navigate(`/Moderadores/Editar/${moderator.id}`)
                        }
                        title="Editar moderador"
                      >
                        <FiEdit />
                      </IconButton>
                      <IconButton
                        type="button"
                        onClick={() => setDeletingUserId(moderator.id)}
                        title="Excluir moderador"
                      >
                        <FiTrash />
                      </IconButton>
                    </ActionButtonsContainer>
                  </TableData>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
        <Pages>
          <Stack spacing={1}>
            <Pagination
              page={page}
              count={maximumPage}
              onChange={(e, pag) => {
                setPage(pag);
              }}
              shape="rounded"
              size="small"
            />
          </Stack>
        </Pages>
      </MainArea>
      {showPermissionsModal && (
        <PermissionsModal onClose={() => setShowPermissionsModal(false)} />
      )}
      {!!deletingUserId && (
        <ConfirmModal
          onCancel={() => setDeletingUserId('')}
          onConfirm={confirmDeleteModerator}
          onClose={() => setDeletingUserId('')}
          title="Tem certeza que deseja excluir este moderador?"
          confirmText="Excluir"
          cancelText="Cancelar"
        />
      )}
    </Container>
  );
};

export default Moderadores;
