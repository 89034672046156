import Checkbox from '@mui/material/Checkbox';
import { pink } from '@mui/material/colors';
import { useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';
import { endOfDay } from 'date-fns';
import Email from '../../../../assets/CadastroVendas/emailIcon.svg';
import Link from '../../../../assets/CadastroVendas/linkIcon.svg';
import WhatsApp from '../../../../assets/CadastroVendas/whatsappIcon.svg';

import { currentActions, useChangePage } from '../../../../hooks/dataContext';
import api from '../../../../services/api';
import { RegistrationForm } from '../../../../types/JobRegistrationForm';
import {
  defaultNotSelectedMessage,
  defaultSelectedMessage,
} from '../../../../utils/jobMessages';
import { maskPhone } from '../../../../utils/masks';
import { messageError } from '../../../../utils/message';
import { Button } from '../../CadastroVagasStyles';
import { ClosingDate } from '../DatePiker';
import { ArrowIconRight } from '../FormVaga/FormVagaStyles';
import {
  CheckboxSession,
  Container,
  DateComponent,
  IconImage,
  IconInput,
  IconInputContainer,
  IconInputField,
  InputArea,
  Label,
  Message,
  MessagesArea,
  OtherMethods,
  SessionTitle,
  SpanError,
  Textarea,
} from './FormFinalizao';

const steps = [
  {
    id: 'vaga',
  },
  {
    id: 'finalizacao',
  },
];

export const FormFinish = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useFormContext<RegistrationForm>();

  const [loading, setLoading] = useState(false);
  const [removerValor, setRemoverValor] = useState(true);

  const { state, dispatch } = useChangePage();

  const handlePrevious = () => {
    dispatch({
      type: currentActions.setCurrentStepRegistration,
      payload: state.currentStepRegistration - 1,
    });
  };

  const onSubmit: SubmitHandler<RegistrationForm> = async data => {
    setLoading(true);

    const {
      start_date,
      finish_date,
      selected_message,
      abilities,
      city,
      remote,
      state: ufState,
      work_journey,
      finish_message,
      link_whatsapp,
    } = data;

    try {
      await api.post('/job', {
        ...data,
        options: {
          types: data.options.types,
          seniority_id: data.options.seniority_id || undefined,
          area_id: data.options.area_id || undefined,
          scholarity_id: data.options.scholarity_id || undefined,
          benefits: data.options.benefits || undefined,
        },
        abilities: abilities || undefined,
        city: city || undefined,
        remote: remote || undefined,
        state: ufState || undefined,
        work_journey: work_journey || undefined,
        start_date: start_date ? start_date.toISOString() : undefined,
        finish_date: finish_date
          ? endOfDay(finish_date).toISOString()
          : undefined,
        selected_message: selected_message || undefined,
        finish_message: finish_message || undefined,
        link_whatsapp: link_whatsapp
          ? `https://wa.me/55${link_whatsapp.replace(/\D/g, '')}`
          : undefined,
      });

      reset();
      toast.success('Cadastrado com Sucesso!');
      handlePrevious();
      navigate('/VagasCadastradas');
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  const startDate = watch('start_date');
  const finishDate = watch('finish_date');

  const title = watch('title');

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DateComponent>
          <Message>
            Data de encerramento <span>*</span>
          </Message>

          <ClosingDate
            value={[startDate, finishDate]}
            onChange={dates => {
              const [inicialDate, finalDate] = dates;

              setValue('start_date', inicialDate);
              setValue('finish_date', finalDate);
            }}
          />
          {errors.start_date && (
            <SpanError>{errors.start_date.message}</SpanError>
          )}
        </DateComponent>
        <CheckboxSession>
          <SessionTitle>Métodos de candidatura interna</SessionTitle>
          <Checkbox
            onClick={() => setRemoverValor(!removerValor)}
            checked={removerValor}
            sx={{
              color: pink[800],
              '&.Mui-checked': {
                color: pink[600],
              },
            }}
          />

          <span>Padrão</span>
        </CheckboxSession>
        <SessionTitle>Outros métodos</SessionTitle>
        <OtherMethods
          style={{
            opacity: removerValor === true ? '0.5' : '1',
          }}
        >
          <IconInputField>
            <Label htmlFor="externalLink">Link externo de inscrição</Label>
            <IconInputContainer>
              <IconImage src={Link} />
              <IconInput
                disabled={removerValor}
                id="externalLink"
                {...register('link_external')}
              />
            </IconInputContainer>
            {errors.link_external && (
              <SpanError>{errors.link_external.message}</SpanError>
            )}
          </IconInputField>
          <IconInputField>
            <Label htmlFor="emailToSend">E-Mail para envio de currículo</Label>

            <IconInputContainer>
              <IconImage src={Email} />
              <IconInput
                id="emailToSend"
                disabled={removerValor}
                type="text"
                {...register('link_email')}
              />
            </IconInputContainer>
            {errors.link_email && (
              <SpanError id="information">
                {errors.link_email.message}
              </SpanError>
            )}
          </IconInputField>
          <IconInputField
            style={{
              maxWidth: '18.5rem',
            }}
          >
            <Label htmlFor="whatsappLink">
              Número de inscrição por WhatsApp
            </Label>
            <IconInputContainer>
              <IconImage src={WhatsApp} />
              <IconInput
                maxLength={15}
                disabled={removerValor}
                type="text"
                id="whatsappLink"
                {...register('link_whatsapp', {
                  onChange: e => {
                    e.target.value = maskPhone(e.target.value);
                  },
                })}
              />
            </IconInputContainer>
            {errors.link_whatsapp && (
              <SpanError>{errors.link_whatsapp.message}</SpanError>
            )}
          </IconInputField>
        </OtherMethods>
        <MessagesArea>
          <div>
            <div>
              <Message>Mensagem de encerramento</Message>
            </div>
            <InputArea>
              <Textarea
                className="largeInput"
                {...register('finish_message')}
                placeholder={defaultNotSelectedMessage()}
              />
            </InputArea>
            {errors.finish_message && (
              <SpanError>{errors.finish_message.message}</SpanError>
            )}
          </div>
          <div id="selecionados">
            <div>
              <Message>Mensagem para selecionados</Message>
            </div>
            <InputArea>
              <Textarea
                className="largeInput"
                {...register('selected_message')}
                placeholder={defaultSelectedMessage(title)}
              />
            </InputArea>
            {errors.selected_message && (
              <SpanError>{errors.selected_message.message}</SpanError>
            )}
          </div>
        </MessagesArea>
        <Button>
          {state.currentStepRegistration < steps.length + 1 && (
            <button type="button" onClick={handlePrevious}>
              <ArrowIconRight />
              <span id="voltar">Voltar</span>
            </button>
          )}

          <button type="submit" disabled={loading}>
            <span id="salvar">Salvar</span>
          </button>
        </Button>
      </form>
    </Container>
  );
};
