import { Container, StyledLink } from './_linkHeader';

const LinkHeader = () => {
  return (
    <Container>
      <StyledLink to="/VagasCadastradas" end={false}>
        Vagas Cadastradas
      </StyledLink>
      <StyledLink to="/Selecionados" end={false}>
        Selecionados
      </StyledLink>
    </Container>
  );
};

export default LinkHeader;
