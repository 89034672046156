import styled from 'styled-components';

export const Container = styled.div`
  //border: 1px solid red;
  width: 100%;
  height: 100vh;
  display: flex;
  /* align-items: center; */
  background: #fbfbfb;
  color: var(--black);
`;

export const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2em;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ContainerSearch = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (max-width: 1200px) {
    margin: 20px 0px;
  }
`;

export const ButtonSearch = styled.button`
  display: flex;
  align-items: center;
  /* Position para o icone */
  position: absolute;
  border: none;
  background-color: #fff;
  margin: 0 10px;
`;

export const SearchBar = styled.input`
  width: 400px;
  height: 40px;
  text-indent: 40px;
  letter-spacing: 1px;
  border-radius: 5px;
  border: #dcdcdc 1px solid;

  @media (max-width: 600px) {
    width: 350px;
  }
`;

export const ContainerTables = styled.table`
  border: #dcdcdc solid 1px;
  border-radius: 5px;
  width: 100%;
`;

export const SectionTable = styled.div`
  overflow-y: scroll;
  max-height: 250px;

  @media (max-width: 600px) {
    overflow-x: scroll;
    width: 90%;
  }
`;

export const Tr = styled.tr``;

export const Th = styled.th`
  text-align: start;
  padding: 10px 5px;
`;

export const Td = styled.td`
  padding: 4px 5px;
  color: #707070;

  :first-child {
    font-weight: bold;
    color: #000;
  }
`;

export const Title = styled.p`
  margin-bottom: 5px;
  margin-top: 20px;
`;

export const ButtonDownload = styled.a`
  border: none;
  background-color: transparent;
  color: #000;
  font-weight: bold;
  text-decoration: none;
`;

export const TdNivel = styled.td`
  color: #707070;
  display: flex;
  align-items: center;

  /* Progress Bar */
  ::after {
    content: '';
    width: 45px;
    height: 5px;
    background-color: #4aaa4a;
    border-radius: 5px;
    margin-left: 10px;
  }
`;
