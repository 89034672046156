/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { currentActions, useChangePage } from '../../../../hooks/dataContext';
import { RegistrationForm } from '../../../../types/JobRegistrationForm';
import { Button } from '../../CadastroVagasStyles';
import api from '../../../../services/api';
import handleError from '../../../../utils/message';
import {
  ArrowIconLeft,
  ArrowIconRight,
  Content,
  customStyles,
  Form,
  Input,
  Section,
  SectionWrap,
  SelectBasic,
  SpanError,
  Textarea,
  Title,
} from './FormVagaStyles';

export interface JobOptions {
  areas: Areas;
  types: Areas;
  seniorities: Areas;
  scholarities: Areas;
  abilities: Areas;
}

export interface Areas {
  results: Result[];
  total: number;
  page: number;
  limit: number;
}

export interface Result {
  id: string;
  name: string;
}

interface Option {
  value: string;
  label: string;
}

type IBGEUFResponse = {
  sigla: string;
  nome: string;
};
type IBGECITYResponse = {
  id: number;
  nome: string;
};

const steps = [
  {
    id: 'vaga',
  },
  {
    id: 'finalizacao',
  },
];

const options = [
  { value: 'Remoto', label: 'Remoto' },
  { value: 'Presencial', label: 'Presencial' },
  { value: 'Híbrido', label: 'Híbrido' },
];

const journey = [
  { value: 'FULL-TIME', label: 'Tempo integral' },
  { value: 'PART-TIME', label: 'Tempo Parcial' },
  { value: 'CONTRACTOR', label: 'Prestador de serviços' },
  { value: 'INTERN', label: 'Estágiario' },
  { value: 'OTHER', label: 'Outros' },
];

export const FormVaga = () => {
  const { state, dispatch } = useChangePage();

  const [jobOption, setJobOptions] = useState<JobOptions>();
  const [ufs, setUfs] = useState<Option[]>([]);
  const [cities, setCities] = useState<Option[]>([]);
  const [cargos, setCargos] = useState<Option[]>([]);

  const {
    control,
    register,
    formState: { errors },
    watch,
    setError,
    trigger,
  } = useFormContext<RegistrationForm>();

  const selectedUf = watch('state');
  const types = watch('options.types');

  const getUfs = async () => {
    try {
      const { data } = await axios.get<IBGEUFResponse[]>(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
      );

      const sortedData = data.sort((a, b) => (a.nome > b.nome ? 1 : -1));

      setUfs(
        sortedData.map(({ sigla, nome }) => ({ value: sigla, label: nome })),
      );
    } catch (error: any) {
      console.error(error);
    }
  };

  const getCities = async () => {
    try {
      const { data } = await axios.get<IBGECITYResponse[]>(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`,
      );

      setCities(data.map(({ nome }) => ({ value: nome, label: nome })));
    } catch (error: any) {
      console.error(error);
    }
  };

  const getJob = async () => {
    try {
      const response = await api.get('/job/option/any');
      setJobOptions(response.data);
      setCargos(
        response.data?.types.results.map((type: any) => ({
          value: type.id,
          label: type.name,
        })) || [],
      );
    } catch (error: any) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (selectedUf) {
      getCities();
    }
  }, [selectedUf]);

  useEffect(() => {
    getUfs();
    getJob();
  }, []);

  const handleNext = async () => {
    if (types.length === 0) {
      setError(
        'options.types',
        {
          message: 'Selecione pelo menos um cargo',
        },
        {
          shouldFocus: true,
        },
      );
    }

    const validTitle = await trigger('title', {
      shouldFocus: true,
    });

    if (!validTitle || types.length === 0) {
      return;
    }

    dispatch({
      type: currentActions.setCurrentStepRegistration,
      payload: state.currentStepRegistration + 1,
    });
  };

  const senioridade =
    jobOption?.seniorities.results.map(seniority => ({
      value: seniority.id,
      label: seniority.name,
    })) || [];

  const escolaridade =
    jobOption?.scholarities.results.map(scholaritie => ({
      value: scholaritie.id,
      label: scholaritie.name,
    })) || [];

  const areaAtuacao =
    jobOption?.areas.results.map(area => ({
      value: area.id,
      label: area.name,
    })) || [];

  return (
    <Form>
      <Content>
        <SectionWrap>
          <Section>
            <Title>
              Título<span>*</span>
            </Title>
            <Input
              type="text"
              {...register('title')}
              placeholder="Digite o título da vaga"
            />
            {errors?.title?.message && (
              <SpanError>{errors.title.message}</SpanError>
            )}
          </Section>

          <Controller
            name="options.types"
            defaultValue={[]}
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Section>
                <Title>
                  Cargo<span>*</span>
                </Title>
                <SelectBasic
                  className="hard-select"
                  classNamePrefix="react-select"
                  placeholder="Selecione ou Escreva"
                  isMulti
                  styles={customStyles}
                  options={cargos}
                  value={cargos.filter(c => value.includes(c.value))}
                  {...register('options.types')}
                  onChange={(val: any) =>
                    onChange(val.map((item: Option) => item.value))
                  }
                />
                {errors?.options?.types && (
                  <SpanError>{errors.options.types.message}</SpanError>
                )}
              </Section>
            )}
          />
          <Controller
            name="options.seniority_id"
            defaultValue=""
            control={control}
            render={({ field: { onChange, value } }) => (
              <Section>
                <Title>Senioridade</Title>
                <SelectBasic
                  className="basic-select"
                  classNamePrefix="react-select"
                  placeholder="Selecione"
                  styles={customStyles}
                  options={senioridade}
                  value={senioridade.find(c => c.value === value)}
                  onChange={(val: any) => onChange(val.value)}
                />
              </Section>
            )}
          />
          <Controller
            name="options.area_id"
            defaultValue=""
            control={control}
            render={({ field: { onChange, value } }) => (
              <Section>
                <Title>Área de atuação</Title>
                <SelectBasic
                  {...register('options.area_id')}
                  className="basic-select"
                  classNamePrefix="react-select"
                  placeholder="Selecione ou Escreva "
                  styles={customStyles}
                  options={areaAtuacao}
                  value={areaAtuacao.find(c => c.value === value)}
                  onChange={(val: any) => onChange(val.value)}
                />
              </Section>
            )}
          />
          <Controller
            name="remote"
            defaultValue=""
            control={control}
            render={({ field: { onChange, value } }) => (
              <Section>
                <Title>Remoto/Presencial</Title>
                <SelectBasic
                  {...register('remote')}
                  className="basic-select"
                  classNamePrefix="react-select"
                  placeholder="Selecione"
                  styles={customStyles}
                  options={options}
                  value={options.find(c => c.value === value)}
                  onChange={(val: any) => onChange(val.value)}
                />
              </Section>
            )}
          />
          <Controller
            name="work_journey"
            defaultValue=""
            control={control}
            render={({ field: { onChange, value } }) => (
              <Section>
                <Title>Jornada de trabalho</Title>
                <SelectBasic
                  {...register('work_journey')}
                  className="basic-select"
                  classNamePrefix="react-select"
                  placeholder="Selecione"
                  styles={customStyles}
                  options={journey}
                  value={journey.find(c => c.value === value)}
                  onChange={(val: any) => onChange(val.value)}
                />
              </Section>
            )}
          />

          <Controller
            name="options.scholarity_id"
            defaultValue=""
            control={control}
            render={({ field: { onChange, value } }) => (
              <Section>
                <Title>Nível de escolaridade</Title>
                <SelectBasic
                  className="basic-select"
                  classNamePrefix="react-select"
                  placeholder="Selecione"
                  styles={customStyles}
                  options={escolaridade}
                  value={escolaridade.find(c => c.value === value)}
                  onChange={(val: any) => onChange(val.value)}
                />
              </Section>
            )}
          />

          <Section>
            <Title>Salário disponível?</Title>
            <Input
              type="text"
              placeholder="Digite o salário"
              {...register('salary')}
            />
          </Section>
        </SectionWrap>

        <SectionWrap>
          <Controller
            control={control}
            name="state"
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Section>
                <Title>Estado</Title>
                <SelectBasic
                  className="basic-select"
                  classNamePrefix="react-select"
                  placeholder="Selecione"
                  styles={customStyles}
                  options={ufs}
                  value={ufs.find(c => c.value === value) || null}
                  onChange={(option: any) => {
                    if (option) {
                      onChange(option.value);
                    }
                  }}
                  noOptionsMessage={() => 'Nenhum resultado encontrado'}
                />
              </Section>
            )}
          />

          <Controller
            control={control}
            name="city"
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Section>
                <Title>Cidade</Title>
                <SelectBasic
                  className="basic-select"
                  classNamePrefix="react-select"
                  placeholder="Selecione"
                  styles={customStyles}
                  options={cities}
                  value={cities.find(c => c.value === value) || null}
                  onChange={(option: any) => {
                    if (option) {
                      onChange(option.value);
                    }
                  }}
                  noOptionsMessage={() => 'Selecione um estado'}
                />
              </Section>
            )}
          />
        </SectionWrap>

        <SectionWrap>
          <Section>
            <Title>Benefícios</Title>
            <Textarea
              {...register('options.benefits')}
              placeholder="Escreva os benefícios"
            />
          </Section>
          <Section>
            <Title>Habilidades/Requisitos</Title>
            <Textarea
              {...register('abilities')}
              placeholder="Escreva as habilidades"
            />
          </Section>
          {/* <Section>
            <Title>Exigências</Title>
            <Textarea
              {...register('requirements')}
              placeholder="Escreva as exigências requeridas"
            />
          </Section> */}
          <Section>
            <Title>Descrição da Vaga</Title>
            <Textarea
              placeholder="Escreva a descrição da vaga"
              {...register('description')}
            />
          </Section>
        </SectionWrap>
      </Content>
      <Button>
        {state.currentStepRegistration < steps.length + 1 && (
          <button type="button" id="voltar">
            <ArrowIconRight id="voltar" />
            <span id="voltar">Voltar</span>
          </button>
        )}
        {state.currentStepRegistration < steps.length - 1 && (
          <button type="button" onClick={handleNext}>
            <span id="proximo">Próximo</span>

            <ArrowIconLeft />
          </button>
        )}
      </Button>
    </Form>
  );
};
