import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.9375rem;
  border-radius: 8px;
  min-height: 2.5rem;
  padding: 0.5rem;

  color: #fff;
  background-color: var(--pink);
  border: none;

  transition: filter 0.2s;

  &:disabled {
    cursor: not-allowed;
    background: #dcdcdc;
    color: #1a1a1a4c;
  }

  &:hover {
    filter: brightness(0.9);
  }
`;
