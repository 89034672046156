// React
import React, { Dispatch, SetStateAction, useState } from 'react';
import Modal from 'react-modal';
import api from '../../../../../../services/api';
import { messageError, messageSuccess } from '../../../../../../utils/message';

import {
  ContainerModal,
  ModalStyle,
  Message,
  ContainerButton,
  ButtonCreate,
  ButtonCancel,
  TextArea,
} from './_modalrefused';

// Type
interface RegisterSportProps {
  openModalDelete: boolean;
  setOpenModalDelete: any;
  idDelete: string;
  getCandidates: () => void;
}

export const ModalRefused = ({
  openModalDelete,
  setOpenModalDelete,
  idDelete,
  getCandidates,
}: RegisterSportProps) => {
  function handleCloseModal() {
    setOpenModalDelete(false);
  }

  const [messageRefused, setMessageRefused] = useState('');

  const removeCandidates = async (idTeste: string) => {
    try {
      await api.put(`/job/application/${idTeste}`, {
        status_id: 2,
        reason: messageRefused,
      });
      messageSuccess('Candidato recusado com sucesso!');
      getCandidates();
      handleCloseModal();
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <Modal
      isOpen={openModalDelete}
      onRequestClose={handleCloseModal}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ContainerModal>
        <ModalStyle>
          <Message>Deseja Reprovar este candidato?</Message>
          <TextArea
            placeholder="Digite aqui o motivo da reprovação"
            onChange={e => setMessageRefused(e.target.value)}
          />
          <ContainerButton>
            <ButtonCancel onClick={handleCloseModal}>Cancelar</ButtonCancel>
            <ButtonCreate onClick={() => removeCandidates(idDelete)}>
              Reprovar
            </ButtonCreate>
          </ContainerButton>
        </ModalStyle>
      </ContainerModal>
    </Modal>
  );
};
