import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;
  gap: 3rem;
`;

export const StyledLink = styled(NavLink)`
  background-color: transparent;
  text-decoration: none;
  color: #707070;
  margin: 0;

  padding: 0rem 0.25rem 0.25rem;

  border-bottom: 2px solid #fbfbfb;
  border-radius: 0px;

  &.active {
    border-color: var(--pink);
    color: var(--black);
    font-weight: 500;
  }
`;
