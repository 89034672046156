import { forwardRef } from 'react';
import {
  InputContainer,
  InputLabel,
  StyledInput,
  ErrorMessage,
} from './_input';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  error?: string;
  labelClassName?: string;
  containerClassName?: string;
  errorClassName?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      label,
      error,
      labelClassName,
      containerClassName,
      errorClassName,
      ...rest
    },
    ref,
  ) => {
    return (
      <InputContainer className={containerClassName}>
        {label && (
          <InputLabel className={labelClassName} htmlFor={id}>
            {label}
          </InputLabel>
        )}
        <StyledInput id={id} {...rest} ref={ref} />
        {error && (
          <ErrorMessage className={errorClassName}>{error}</ErrorMessage>
        )}
      </InputContainer>
    );
  },
);

export default Input;
