// React
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Icons React
import { AiOutlineLock } from 'react-icons/ai';

// Components
import { Navbar } from '../../components/Navbar/Navbar';
import { Profile } from '../../components/Profile/Profile';
import { IModeratorProfileForm, IPerfilUser } from '../../types/PerfilUser';

// Image
import imageUser from '../../assets/Header/userPerfil.png';

// Style
import { useAuth } from '../../hooks/useAuth';
import api from '../../services/api';
import { messageError, messageSuccess } from '../../utils/message';
import {
  ButtonSave,
  Container,
  ContainerImage,
  ContainerSave,
  ErrorText,
  Field,
  FieldIconWrapper,
  FieldInput,
  FieldLabel,
  Form,
  FormSection,
  Header,
  ImageInput,
  ImageLabel,
  ImageUser,
  Label,
  MainArea,
  SectionColumn,
  SectionFlex,
  Title,
  TitleSection,
  TypeImage,
} from './_editarPerfilModerador';

export const EditarPerfilModerador = () => {
  const { user, setUser } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPerfilUser>();

  const [image, setImage] = useState<File>();
  const imageUrl: string = image ? URL.createObjectURL(image) : '';

  const onSubmit: SubmitHandler<IModeratorProfileForm> = async data => {
    const { new_password, old_password, confirm_password, username } = data;

    const formData = new FormData();
    if (image) formData.append('avatar', image || '');
    if (new_password) formData.append('password', new_password);
    if (old_password) formData.append('old_password', old_password);
    if (confirm_password) formData.append('confirm_password', confirm_password);
    if (username) formData.append('username', username);

    try {
      const response = await api.put(`/user`, formData);
      messageSuccess('Alterado com Sucesso!');
      setUser(prev => ({
        ...prev,
        ...response.data,
      }));

      localStorage.setItem(
        '@ClickPetroleoEmpresas: user',
        JSON.stringify({
          ...user,
          ...response.data,
        }),
      );
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <Container>
      <Navbar />
      <MainArea>
        <Header>
          <Profile />
        </Header>
        <TitleSection>Informações Pessoais</TitleSection>

        <Form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          id="edit-profile-form"
        >
          <FormSection>
            <SectionColumn>
              <Field>
                <FieldLabel>Nome de exibição</FieldLabel>
                <FieldInput
                  type="text"
                  {...register('username')}
                  defaultValue={user?.username}
                />
                {errors.username && (
                  <ErrorText>{errors.username.message}</ErrorText>
                )}
              </Field>

              <Field>
                <FieldLabel>E-mail</FieldLabel>
                <FieldInput type="email" disabled defaultValue={user.email} />
                <FieldIconWrapper>
                  <AiOutlineLock />
                </FieldIconWrapper>
              </Field>
            </SectionColumn>

            <ContainerImage
              style={{
                gridColumn: 'auto / span 2',
              }}
            >
              <SectionFlex>
                <ImageLabel>Foto de perfil</ImageLabel>
                <ImageUser src={imageUrl || user?.avatar_url || imageUser} />
                <TypeImage>JPG,PNG</TypeImage>
              </SectionFlex>
              <Label htmlFor="files">Carregar</Label>
              <ImageInput
                type="file"
                accept="image/*"
                id="files"
                {...register('avatar')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { files } = e.target;

                  if (!files || files.length < 1) {
                    return;
                  }

                  const selectedFile = files[0];
                  setImage(selectedFile);
                }}
              />
            </ContainerImage>
          </FormSection>
          <Title>Modificar senha</Title>
          <FormSection>
            <SectionColumn>
              <Field>
                <FieldLabel htmlFor="new_password">Nova Senha</FieldLabel>
                <FieldInput
                  placeholder="Nova Senha"
                  type="password"
                  id="new_password"
                  autoComplete="new-password"
                  {...register('new_password')}
                />
              </Field>

              <Field>
                <FieldLabel htmlFor="confirm_password">
                  Confirmar Senha
                </FieldLabel>
                <FieldInput
                  placeholder="Confirmar Senha"
                  type="password"
                  id="confirm_password"
                  autoComplete="new-password"
                  {...register('confirm_password')}
                />
              </Field>
            </SectionColumn>

            <Field>
              <FieldLabel htmlFor="old_password">Senha atual</FieldLabel>
              <FieldInput
                type="password"
                placeholder="Senha atual"
                id="old_password"
                autoComplete="new-password"
                {...register('old_password')}
              />
            </Field>

            <div />
          </FormSection>
          <ContainerSave>
            <ButtonSave type="submit">Salvar</ButtonSave>
          </ContainerSave>
        </Form>
      </MainArea>
    </Container>
  );
};
