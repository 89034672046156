import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../services/api';
import { messageError, messageSuccess } from '../../utils/message';
import { Container, Loading } from './_confirmSignUp';

export const ConfirmSignUp = () => {
  const navigate = useNavigate();

  const [queryParams] = useSearchParams();

  const token = queryParams.get('token');

  const confirmAccount = async () => {
    try {
      await api.get('/user/activate-email', {
        params: {
          token,
        },
      });

      messageSuccess('Conta confirmada com sucesso! Faça o login!');
    } catch (error: any) {
      messageError(error?.response?.data?.message);
    } finally {
      navigate('/Login');
    }
  };

  useEffect(() => {
    confirmAccount();
  }, [token]);

  return (
    <Container>
      <Loading />
    </Container>
  );
};
