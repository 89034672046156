import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import BackgroundImg from '../../assets/Cadastro/womanImg.png';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Logo = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 2rem;

  @media (max-width: 850px) {
    background: none;
    display: none;
  }
`;

export const Imagem = styled.div`
  display: flex;
  align-items: flex-end;
  min-height: 100vh;
  flex: 1;
  background: linear-gradient(
      360deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(59, 59, 59, 1) 0%,
      rgba(255, 255, 255, 0%) 100%
    ),
    url(${BackgroundImg});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: 850px) {
    background: none;
    display: none;
  }
`;

export const ImageTextWrapper = styled.div`
  padding: 1rem;
`;

export const ImageTitle = styled.h1`
  color: #ffffff;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ImageText = styled.span`
  font-size: 1.25rem;
  color: #ffffff;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  width: 58%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 36rem;
  max-height: 100%;
  padding: 1.5rem 0.5rem;

  text-align: center;

  border: 1px solid #dcdcdc;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
`;

export const FormTitle = styled.h2`
  font-weight: 600;
  color: var(--black);
  font-size: 1.5rem;
`;

export const Input = styled.input`
  margin-top: 0.25rem;

  width: 100%;

  height: 2.875rem;
  border-radius: 0.5rem;
  border: 1px solid #1a1a1a4c;
  margin-bottom: 0.3125rem;
  padding: 0 0.75rem;

  transition: 0.2s ease-in-out;

  &:focus {
    border-color: #acacac;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  text-align: left;
`;

export const ErrorMessage = styled.span`
  width: fit-content;
  text-align: left;
  color: #fd1c50;
  font-size: 0.75rem;
`;

export const FormFields = styled.div`
  margin-top: 1rem;

  width: 100%;
  max-width: 29rem;

  display: flex;
  flex-direction: column;
`;

export const FormRow = styled.div`
  margin-top: 0.25rem;

  width: 100%;

  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

export const SubmitButton = styled.button`
  border: none;

  margin-top: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 3.125rem;
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  transition: 300ms;

  :hover {
    transition: 300ms;
    background-color: #ff3664;
  }
`;

export const CheckboxContainer = styled.div`
  margin-top: 1rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-size: 0.9375rem;
  color: #1a1a1a7f;
  font-weight: 500;

  span {
    color: var(--pink);
    font-weight: 600;
  }

  input[type='checkbox'] {
    display: inline-block;
  }
`;

export const LoginLinkWrapper = styled.div`
  font-size: 0.9375rem;
  color: #1a1a1a;
`;

export const LoginLink = styled(NavLink)`
  text-decoration: none;
  color: var(--pink);
  font-weight: bold;
  font-size: 0.9375rem;
`;
