import styled from 'styled-components';

export const ContainerFilter = styled.div`
  position: absolute;
  z-index: 2;
  margin-left: 0.625rem;
  background-color: #fff;
  padding: 0.875rem 1rem;
  width: 18.75rem;
  box-shadow: 0px 3px 6px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  @media screen and (max-width: 1000px) {
    right: 0px;
  }
`;

export const TitleFilter = styled.p`
  font-size: 1.125rem;
  font-weight: 500;
`;

export const ContainerFilterMain = styled.div``;

export const OpenFilter = styled.button`
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #dcdcdc;
  border-radius: 0px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;

  padding-bottom: 0.5rem;

  font-size: 1rem;

  margin-top: 15px;
`;

export const Form = styled.form`
  width: 100%;
`;

export const FormRow = styled.div``;

export const SectionInput = styled.div`
  margin: 10px auto 0;
`;

export const ContainerInput = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const Input = styled.input`
  width: 100%;

  border-radius: 5px;
  border: 1px solid #dcdcdc;
  padding: 0 8px;

  height: 38px;
`;

export const ButtonAdd = styled.button`
  border: none;

  padding: 0 8px;
  border-radius: 5px;

  height: 38px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.95);
  }
`;

export const ContainerButton = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: end;
`;

export const ContainerCheck = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const Value = styled.p`
  font-size: 0.9375rem;
  color: #707070;
`;
