import styled from 'styled-components';
import Select from 'react-select';

export const Container = styled.div`
  flex: 1;
  overflow: auto;

  display: flex;
  flex-direction: column;
`;

export const SearchHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;

  @media screen and (max-width: 1100px) {
    justify-content: center;

    gap: 1rem;
  }
`;

export const SearchContainer = styled.form`
  width: 100%;
  max-width: 24rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border: #dcdcdc 1px solid;
  border-radius: 5px;
  background: #fff;

  padding: 0 0.5rem;
`;

export const SearchInput = styled.input`
  border: none;
  height: 40px;
  width: 100%;
  border-radius: 5px;
`;

export const SearchButton = styled.button`
  border: none;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #dcdcdc;
`;

export const customStyles = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,

    color: state.isSelected ? 'white' : 'black',
    background: state.isSelected ? '#FD1C50D1' : 'white',

    ':hover': {
      backgroundColor: '#fd1c5033',
    },
  }),
};

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const SelectBasic = styled(Select)`
  min-width: 20rem;
  width: max-content;
  font-family: 'Noto Sans', sans-serif;
  font-size: 0.875rem;

  z-index: 10;

  .react-select__menu-list {
    font-size: 0.875rem;

    &::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      background: #e9e9e9;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--pink);
      border-radius: 10px;
      height: 120px;
    }
  }

  .react-select__control {
    min-height: 2.625rem;

    padding-left: 0.25rem;

    border-radius: 5px;

    color: var(--lotion);
    border: 1px solid #dcdcdc;
    box-shadow: none;
  }

  .react-select,
  .react-select__control--is-focused.react-select__control--menu-is-open {
    border-color: var(--pink);
    box-shadow: var(--pink);
  }
`;

export const Title = styled.p`
  margin-top: 1rem;
`;

export const TableWrapper = styled.div`
  margin-top: 1rem;
  flex: 1;
  height: 100%;
  overflow: auto;

  padding-right: 1rem;

  position: relative;

  &::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 2rem;
    margin-bottom: 8rem;
    background: #e9e9e9;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--pink);
    border-radius: 10px;
    height: 120px;
  }
`;

export const Table = styled.table`
  width: 100%;

  border-collapse: collapse;
`;

export const TableHead = styled.thead``;

export const TableHeading = styled.th`
  padding: 0.5rem 0;
  text-align: left;
  position: sticky;
  top: 0;
  background: #fbfbfb;
  z-index: 1;
`;

export const TableBody = styled.tbody``;

export const EmptyTableContent = styled.div`
  width: 100%;
  flex: 1;
  text-align: center;
  padding: 1rem;

  font-weight: bold;
  font-size: 1.1rem;
`;

export const TableData = styled.td`
  padding: 0.5rem 0;
  border-bottom: 1px solid #dcdcdc;
  color: #707070;
`;

export const Pages = styled.div`
  // margin: 1rem 0;
  display: flex;
  justify-content: end;
`;

export const SaveButton = styled.button`
  border: none;

  width: 100%;
  max-width: 11rem;
  height: 2.875rem;

  background: var(--pink);

  font-weight: 600;
  font-size: 1rem;
  color: #fff;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
