import styled from 'styled-components';
import BackgroundImg from '../../assets/Login/c.png';
import { ReactComponent as Logoimg } from '../../assets/Login/logo.svg';
import { ReactComponent as Redes } from '../../assets/Login/redesSociais.svg';
import { ReactComponent as Password } from '../../assets/Login/observePassword.svg';

export const Imagem = styled.div`
  display: flex;
  align-items: flex-end;
  min-height: 100vh;
  flex: 1;
  background: linear-gradient(
      360deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(59, 59, 59, 1) 0%,
      rgba(255, 255, 255, 0%) 100%
    ),
    url(${BackgroundImg});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  .textElement {
    padding: 1rem;

    h1 {
      color: #ffffff;
      // word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      font-size: 1.25rem;
      color: #ffffff;
      // word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  #titulo {
    position: absolute;
    top: 570px;
    left: 32px;
    color: var(--lotion);
    font-size: 32px;
    font-weight: 600;
    text-align: left;
  }

  #womanImg {
    position: fixed;
    top: 0rem;
    left: 0rem;
    height: 100vh;
  }
  #gradienteImg {
    position: absolute;
    top: 0rem;
    left: 0rem;
    height: 100vh;
  }

  @media (max-width: 850px) {
    background: none;
    display: none;
  }
`;

export const Eye = styled(Password)`
  width: auto;
  height: auto;
  cursor: pointer;
`;

export const Logo = styled.div`
  text-align: center;
  margin-top: 40px;
`;

export const LoginPageLogo = styled(Logoimg)`
  height: auto;
  width: auto;
`;

export const SocialNetworks = styled(Redes)`
  width: auto;
  height: auto;
`;

export const Container = styled.section`
  display: flex;
  align-items: stretch;
  justify-content: space-around;

  .alignment {
    display: flex;
    width: 58%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #mensagem {
    text-align: center;
    font-style: medium;
    font-weight: bold;
    font-size: 16px;
    color: var(--light-gray);
    margin-bottom: 30px;
  }
`;

export const Form = styled.form`
  text-align: center;
  width: 100%;
  max-width: 30.625rem;
  min-height: 31.875rem;
  border: 1px solid #dcdcdc;
  margin-top: 1rem;

  @media (max-width: 500px) {
    width: 25rem;
    border: none;
  }

  .conta {
    margin-top: 0.3125rem;
    font-weight: normal;
    color: var(--dark-gray);
    font-size: 0.875rem;
  }
`;

export const NavigationAnchor = styled.div`
  .LinkPasswordRecovery {
    position: relative;
    text-decoration: none;
    color: var(--dark-gray);
    font-weight: 300;
  }

  .Link {
    text-decoration: none;
    color: var(--pink);
    font-weight: bold;
  }
`;

export const Fields = styled.div`
  margin: 0 auto;

  width: 100%;
  max-width: 25rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  text-align: left;

  @media (max-width: 400px) {
    max-width: 20.625rem;
  }
`;

export const Input = styled.input`
  // margin: 20px auto 5px auto;
  width: 100%;
  max-width: 25rem;
  height: 3.125rem;
  border: 1px solid #dcdcdc;
  border-radius: 0.5rem;
  opacity: 1;
  padding: 0 0.625rem;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;

  @media (max-width: 400px) {
    max-width: 20.625rem;
  }
`;

export const PasswordInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ButtonEyeLogin = styled.button`
  border: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.5rem;
  color: #707070;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
`;

export const Button = styled.div`
  text-align: center;
  margin-top: 0.625rem;

  button {
    width: 100%;
    max-width: 25rem;
    height: 3.125rem;
    font-weight: 600;
    font-size: 1rem;

    @media (max-width: 400px) {
      width: 20.625rem;
    }
  }
`;

export const Section = styled.div`
  width: 100%;
  max-width: 25rem;
  margin: 1rem auto 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8125rem;
  color: var(--light-gray);

  #forgotPassword {
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }

  .section__checkbox-container {
    color: rgba(26, 26, 26, 50%);
    font-size: 0.75rem;
  }

  .section__checkbox-input {
    cursor: pointer;
    margin-top: 1px;
    &:before {
      margin-right: 0.25rem;
      font-size: 0.625rem;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-width: 1px;
    }
  }
`;

export const Checkbox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-left: 2.8rem;

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
  }
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .container input:checked ~ .checkmark {
    background-color: var(--pink);
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .container .checkmark:after {
    left: 4px;
    top: 2px;
    width: 3px;
    height: 5px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #checkbox {
    width: auto;
    height: auto;
    align-items: center;
  }
  label {
    position: relative;
    bottom: 11px;
    right: 17px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: var(--light-gray);
    margin-left: 2px;
  }
`;

export const RedesSociais = styled.div`
  margin-top: 10px;
  width: 300px;

  #redesSociais {
    text-align: center;
    margin-bottom: 10px;
    color: #707070;
  }
`;

export const Icones = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ErrorMessage = styled.span`
  // display: flex;
  // justify-content: start;
  // margin-left: 50px;
  margin-left: 0.5rem;

  font-size: 0.75rem;
  color: #d80026;
`;

export const ErrorMessagePassword = styled.span`
  margin-left: 0.5rem;
  font-size: 0.75rem;
  color: #d80026;

  @media (max-width: 500px) {
    margin-left: 0;
  }

  @media (max-width: 400px) {
    margin-left: 40px;
  }
`;

export const LinkSocial = styled.a`
  align-items: center;
  background-color: #fff;
  border: none;
`;
