import React, {
  createContext,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import api from '../services/api';
import { ModeratorPermissions } from '../types/ModeratorPermissions';

// Generated by https://quicktype.io

export interface IResponseLogin {
  user: User;
  access_token: string;
  refresh_token: string;
}

export interface User {
  id: string;
  email: string;
  username: string;
  avatar_url: string;
  role: number;

  company: {
    street: string;
    id: string;
    name: string;
    phone: string;
    cnpj: string;
    logo: null;
    description: string;
    address_id: string;
    user_id: string;
    logo_url: string;
    external_link: string;

    address: {
      id: string;
      cep: string;
      street: string;
      number: number;
      complement: string;
      country: string;
      state: string;
      city: string;
      neighborhood: string;
      addressStreet: string;
    };

    responsible: {
      name: string;
      email: string;
      phone: string;
    };
  };
  moderator?: {
    id: string;
    permission: ModeratorPermissions[];
    company_id: string;
    user_id: string;
  };
}

export interface IUserProvider {
  user: User;
  setUser: React.Dispatch<SetStateAction<User>>;
  isAuthenticated: () => boolean;
  logout: () => void;
  login: (data: LoginRequest) => Promise<IResponseLogin>;
}

interface ChildrenProps {
  children: ReactNode;
}

interface LoginRequest {
  email: string;
  password: string;
  remember_me: boolean;
}

const AuthContext = createContext({} as IUserProvider);

const AuthProvider = ({ children }: ChildrenProps) => {
  const [user, setUser] = useState<User>({} as User);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const dataUser = localStorage.getItem('@ClickPetroleoEmpresas: user');

    if (dataUser) {
      setUser(JSON.parse(dataUser));
    }

    setLoading(false);
  }, []);

  const isAuthenticated = () => {
    return user.id !== undefined;
  };

  const contextLogin = async (data: LoginRequest) => {
    const response = await api.post('/user/session', data);

    if (response.data.user.role !== 2 && response.data.user.role !== 4) {
      throw new Error('Usuário não pode acessar a plataforma');
    }

    localStorage.setItem(
      '@ClickPetroleoEmpresas: accessToken',
      response.data.access_token,
    );
    localStorage.setItem(
      '@ClickPetroleoEmpresas: refreshToken',
      response.data.refresh_token,
    );
    localStorage.setItem(
      '@ClickPetroleoEmpresas: user',
      JSON.stringify(response.data.user),
    );

    setUser(response.data.user);

    return response.data;
  };

  const logout = () => {
    localStorage.removeItem('@ClickPetroleoEmpresas: user');
    localStorage.removeItem('@ClickPetroleoEmpresas: accessToken');
    localStorage.removeItem('@ClickPetroleoEmpresas: refreshToken');
    setUser({} as User);
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ user, setUser, isAuthenticated, logout, login: contextLogin }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
