import styled from 'styled-components';

export const Imagem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    width: 100%;
    max-width: 32.5rem;

    @media (max-width: 850px) {
      display: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
  height: 100vh;

  padding: 2rem;

  @media (max-width: 850px) {
    justify-content: center;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdcdc;

  max-width: 100%;
  width: 100%;
  max-width: 32.5rem;
  min-height: 23.75rem;

  padding: 3rem;
`;

export const Texto = styled.div`
  #titulo {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--black);
    margin: 0;
  }

  #texto {
    font-size: 1rem;
    color: var(--light-gray);
  }

  margin-bottom: 30px;
`;

export const Input = styled.div`
  input {
    display: flex;
    width: 100%;
    height: 3.125rem;
    border: 1px solid #dcdcdc;
    border-radius: 0.5rem;
    opacity: 1;
    text-indent: 0.625rem;
    margin: 0.625rem 0px 0 0;
  }
`;

export const Button = styled.div`
  width: 100%;

  text-align: center;

  button {
    margin-top: 1.875rem;
    width: 100%;
    height: 3.125rem;

    font-weight: 600;
    font-size: 1rem;

    background: var(--pink);
    color: #ffff;

    border: 1px solid #dcdcdc;
  }
`;
export const Section = styled.div`
  width: 100%;
`;

export const ErrorSpan = styled.span`
  color: #fd1c50;
  font-size: 0.9rem;
`;
