import * as yup from 'yup';

export const RegistrationSchema = yup.object().shape({
  options: yup.object().shape({
    types: yup.array().of(yup.string()),
    // type_id: yup.string().required('Preencha o campo adequadamente'),
    // seniority_id: yup.string(),
    // area_id: yup.string(),
    // benefits: yup.string(),
    // scholarity_id: yup.string(),
  }),
  title: yup.string().required('Título é obrigatório'),
  // abilities: yup.string(),
  // requirements: yup.string(),
  // state: yup.string(),
  // city: yup.string(),
  // description: yup.string(),
  // remove_links: yup.boolean(),
  // work_journey: yup.string(),
  // remote: yup.string(),
  // finish_message: yup.string(),
  // selected_message: yup.string(),
  // start_date: yup.date(),
});
