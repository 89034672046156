/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import Checkbox from '@mui/material/Checkbox';
import { pink } from '@mui/material/colors';
import axios from 'axios';
import { endOfDay, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BiArrowBack } from 'react-icons/bi';
import Email from '../../../assets/CadastroVendas/emailIcon.svg';
import Link from '../../../assets/CadastroVendas/linkIcon.svg';
import WhatsApp from '../../../assets/CadastroVendas/whatsappIcon.svg';

import api from '../../../services/api';
import { RegistrationForm } from '../../../types/JobRegistrationForm';
import { messageError } from '../../../utils/message';
import { ClosingDate } from '../components/DatePiker';

import { Navbar } from '../../../components/Navbar/Navbar';
import { Profile } from '../../../components/Profile/Profile';
import { IJob } from '../../../types/Job';
import {
  defaultNotSelectedMessage,
  defaultSelectedMessage,
} from '../../../utils/jobMessages';
import { maskPhone } from '../../../utils/masks';
import { RegistrationSchema } from '../../../validators/VacancyRegistrationSchema';
import {
  BackButton,
  ButtonEdit,
  CheckboxSession,
  Container,
  ContainerButton,
  Content,
  ContentTwo,
  DateComponent,
  Form,
  Header,
  Hr,
  IconImage,
  IconInput,
  IconInputContainer,
  IconInputField,
  Input,
  InputArea,
  Label,
  MainArea,
  Message,
  MessagesArea,
  OtherMethods,
  Section,
  SectionWrap,
  SelectBasic,
  SessionTitle,
  SpanError,
  Textarea,
  Title,
  customStyles,
} from './_editarjobs';

export interface JobOptions {
  areas: Areas;
  types: Areas;
  seniorities: Areas;
  scholarities: Areas;
  abilities: Areas;
}

export interface Areas {
  results: Result[];
  total: number;
  page: number;
  limit: number;
}

export interface Result {
  id: string;
  name: string;
}

interface Option {
  value: string;
  label: string;
}

type IBGEUFResponse = {
  sigla: string;
  nome: string;
};
type IBGECITYResponse = {
  id: number;
  nome: string;
};

const journey = [
  { value: 'FULL-TIME', label: 'Tempo integral' },
  { value: 'PART-TIME', label: 'Tempo Parcial' },
  { value: 'CONTRACTOR', label: 'Prestador de serviços' },
  { value: 'INTERN', label: 'Estágiario' },
  { value: 'OTHER', label: 'Outros' },
];

const options = [
  { value: 'Remoto', label: 'Remoto' },
  { value: 'Presencial', label: 'Presencial' },
  { value: 'Híbrido', label: 'Híbrido' },
];

export const EditarJobs = () => {
  const [jobOption, setJobOptions] = useState<JobOptions>();
  const [jobTypeOptions, setJobTypeOptions] = useState<Option[]>([]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<RegistrationForm>({
    resolver: yupResolver(RegistrationSchema),
    mode: 'onChange',
  });

  const [ufs, setUfs] = useState<Option[]>([]);
  const [cities, setCities] = useState<Option[]>([]);

  const [removerValor, setRemoverValor] = useState(true);

  const navigate = useNavigate();

  const params = useParams();
  const editarId = params.id;

  const selectedUf = watch('state');

  const getUfs = async () => {
    try {
      const { data } = await axios.get<IBGEUFResponse[]>(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
      );

      const sortedData = data.sort((a, b) => (a.nome > b.nome ? 1 : -1));

      setUfs(
        sortedData.map(({ sigla, nome }) => ({ value: sigla, label: nome })),
      );
    } catch (error: any) {
      messageError(error);
    }
  };

  const getCities = async () => {
    try {
      const { data } = await axios.get<IBGECITYResponse[]>(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`,
      );

      setCities(data.map(({ nome }) => ({ value: nome, label: nome })));
    } catch (error: any) {
      messageError(error);
    }
  };

  useEffect(() => {
    if (!selectedUf) {
      return;
    }
    getCities();
  }, [selectedUf]);

  useEffect(() => {
    getUfs();
  }, []);

  const getJob = async () => {
    try {
      const response = await api.get<JobOptions>('/job/option/any');
      setJobOptions(response.data);
      setJobTypeOptions(
        response.data.types.results.map(option => ({
          label: option.name,
          value: option.id,
        })),
      );
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getJob();
  }, []);

  const senioridade =
    jobOption?.seniorities.results.map(seniority => ({
      value: seniority.id,
      label: seniority.name,
    })) || [];

  const escolaridade =
    jobOption?.scholarities.results.map(scholaritie => ({
      value: scholaritie.id,
      label: scholaritie.name,
    })) || [];

  const areaAtuacao =
    jobOption?.areas.results.map(area => ({
      value: area.id,
      label: area.name,
    })) || [];

  const onSubmit: SubmitHandler<RegistrationForm> = async data => {
    const { start_date, finish_date } = data;
    try {
      await api.put(`/job/${editarId}`, {
        ...data,
        options: {
          types: data.options.types,
          seniority_id: data.options.seniority_id || undefined,
          area_id: data.options.area_id || undefined,
          scholarity_id: data.options.scholarity_id || undefined,
          benefits: data.options.benefits || undefined,
        },
        abilities: data.abilities || undefined,
        city: data.city || undefined,
        remote: data.remote || undefined,
        state: data.state || undefined,
        work_journey: data.work_journey || undefined,
        start_date: start_date ? start_date.toISOString() : undefined,
        finish_date: finish_date
          ? endOfDay(finish_date).toISOString()
          : undefined,
        link_whatsapp: data.link_whatsapp
          ? `https://wa.me/55${data.link_whatsapp.replace(/\D/g, '')}`
          : undefined,
        salary: data.salary || undefined,
      });
      navigate('/VagasCadastradas');
      toast.success('Editado com Sucesso!');
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const jobsEdit = async () => {
    try {
      const { data } = await api.get<IJob>(`/job/${editarId}`);
      reset({
        start_date: parseISO(data.start_date),
        finish_date: parseISO(data.finish_date),
        options: {
          seniority_id: data.seniority_id,
          types: data.types.map(type => type.id),
          scholarity_id: data.scholarity_id,
          area_id: data.area_id,
          benefits: data.benefits,
        },
        remote: data.remote,
        state: data.state,
        city: data.city,
        abilities: data.abilities,
        description: data.description,
        routine: data.routine,
        finish_message: data.finish_message,
        selected_message: data.selected_message,
        link_email: data.link_email,
        link_external: data.link_external,
        link_whatsapp: data.link_whatsapp
          ? `${maskPhone(data.link_whatsapp.replace('https://wa.me/55', ''))}`
          : '',
        // requirements: data.requirements,
        work_journey: data.work_journey,
        // link_company: data.link_company,
        salary: data.salary,
        title: data.title,
      });
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const title = watch('title');

  useEffect(() => {
    jobsEdit();
  }, [editarId]);

  const startDate = watch('start_date');
  const finishDate = watch('finish_date');

  return (
    <Container>
      <Navbar />

      <MainArea>
        <Header>
          <BackButton type="button" onClick={() => navigate(-1)}>
            <BiArrowBack />
            Voltar
          </BackButton>
          <Profile />
        </Header>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Content>
            <SectionWrap>
              <Section>
                <Title>
                  Título<span>*</span>
                </Title>
                <Input
                  type="text"
                  {...register('title')}
                  placeholder="Digite o título da vaga"
                />
              </Section>

              <Controller
                name="options.types"
                defaultValue={[]}
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Section>
                    <Title>
                      Cargo<span>*</span>
                    </Title>
                    <SelectBasic
                      className="hard-select"
                      classNamePrefix="react-select"
                      placeholder="Selecione ou Escreva"
                      isMulti
                      styles={customStyles}
                      options={jobTypeOptions}
                      value={jobTypeOptions.filter(c =>
                        value.includes(c.value),
                      )}
                      {...register('options.types')}
                      onChange={(val: any) =>
                        onChange(val.map((item: Option) => item.value))
                      }
                    />
                    {errors?.options?.types && (
                      <SpanError>{errors.options.types.message}</SpanError>
                    )}
                  </Section>
                )}
              />
              <Controller
                name="options.seniority_id"
                defaultValue=""
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Section>
                    <Title>Senioridade</Title>
                    {errors?.options?.seniority_id && (
                      <SpanError>
                        {errors.options.seniority_id.message}
                      </SpanError>
                    )}
                    <SelectBasic
                      className="basic-select"
                      classNamePrefix="react-select"
                      placeholder="Selecione"
                      styles={customStyles}
                      options={senioridade}
                      value={senioridade.find(c => c.value === value)}
                      onChange={(val: any) => onChange(val.value)}
                    />
                  </Section>
                )}
              />
              <Controller
                name="options.area_id"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Section>
                    <Title>Área de atuação</Title>
                    {errors?.options?.area_id && (
                      <SpanError>{errors.options.area_id.message}</SpanError>
                    )}
                    <SelectBasic
                      {...register('options.area_id')}
                      className="basic-select"
                      classNamePrefix="react-select"
                      placeholder="Selecione ou Escreva "
                      styles={customStyles}
                      options={areaAtuacao}
                      value={areaAtuacao.find(c => c.value === value)}
                      onChange={(val: any) => onChange(val.value)}
                    />
                  </Section>
                )}
              />
              <Controller
                name="remote"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Section>
                    <Title>Remoto/Presencial</Title>
                    <SelectBasic
                      {...register('remote')}
                      className="basic-select"
                      classNamePrefix="react-select"
                      placeholder="Selecione"
                      styles={customStyles}
                      options={options}
                      value={options.find(c => c.value === value)}
                      onChange={(val: any) => onChange(val.value)}
                    />
                  </Section>
                )}
              />
              <Controller
                name="work_journey"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Section>
                    <Title>Jornada de trabalho</Title>
                    <SelectBasic
                      {...register('work_journey')}
                      className="basic-select"
                      classNamePrefix="react-select"
                      placeholder="Selecione"
                      styles={customStyles}
                      options={journey}
                      value={journey.find(c => c.value === value)}
                      onChange={(val: any) => onChange(val.value)}
                    />
                  </Section>
                )}
              />

              <Controller
                name="options.scholarity_id"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Section>
                    <Title>Nível de escolaridade</Title>

                    <SelectBasic
                      className="basic-select"
                      classNamePrefix="react-select"
                      placeholder="Selecione"
                      styles={customStyles}
                      options={escolaridade}
                      value={escolaridade.find(c => c.value === value)}
                      onChange={(val: any) => onChange(val.value)}
                    />
                    {errors?.options?.scholarity_id && (
                      <SpanError>
                        {errors.options.scholarity_id.message}
                      </SpanError>
                    )}
                  </Section>
                )}
              />
              {/* <Controller
                name="link_company"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Section>
                    <Title>Link do site da empresa</Title>
                    <Input
                      {...field}
                      type="text"
                      placeholder="Site da empresa"
                    />
                  </Section>
                )}
              /> */}
              <Section>
                <Title>Salário disponível?</Title>
                <Input type="text" {...register('salary')} />
                {errors.salary && (
                  <SpanError>{errors.salary.message}</SpanError>
                )}
              </Section>
            </SectionWrap>
            <SectionWrap>
              <Controller
                control={control}
                name="state"
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Section>
                    <Title>Estado</Title>
                    <SelectBasic
                      className="basic-select"
                      classNamePrefix="react-select"
                      placeholder="Selecione"
                      styles={customStyles}
                      options={ufs}
                      value={ufs.find(c => c.value === value) || null}
                      onChange={(option: any) => {
                        if (option) {
                          onChange(option.value);
                        }
                      }}
                      noOptionsMessage={() => 'Nenhum resultado encontrado'}
                    />
                  </Section>
                )}
              />

              <Controller
                control={control}
                name="city"
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Section>
                    <Title>Cidade</Title>
                    <SelectBasic
                      className="basic-select"
                      classNamePrefix="react-select"
                      placeholder="Selecione"
                      styles={customStyles}
                      options={cities}
                      value={cities.find(c => c.value === value) || null}
                      onChange={(option: any) => {
                        if (option) {
                          onChange(option.value);
                        }
                      }}
                      noOptionsMessage={() => 'Selecione um estado'}
                    />
                  </Section>
                )}
              />
            </SectionWrap>

            <SectionWrap>
              <Section>
                <Title>Benefícios</Title>
                {errors?.options?.benefits && (
                  <SpanError>{errors.options.benefits?.message}</SpanError>
                )}
                <Textarea
                  {...register('options.benefits')}
                  placeholder="Escreva os benefícios"
                />
              </Section>

              <Section>
                <Title>Habilidades/Requisitos</Title>
                {errors?.abilities && (
                  <SpanError>{errors.abilities?.message}</SpanError>
                )}

                <Textarea
                  {...register('abilities')}
                  placeholder="Escreva as habilidades"
                />
              </Section>
              {/* <Section>
                <Title>
                  Exigências<span>*</span>
                </Title>
                {errors.requirements && (
                  <SpanError>{errors.requirements.message}</SpanError>
                )}
                <Textarea
                  {...register('requirements')}
                  placeholder="Escreva as exigências requeridas"
                />
              </Section> */}

              <Section>
                <Title>
                  Descrição da Vaga
                  {errors.description && (
                    <SpanError>{errors.description.message}</SpanError>
                  )}
                </Title>
                <Textarea {...register('description')} />
                {/* <p id="caracatere">0/300</p> */}
              </Section>
            </SectionWrap>
          </Content>
          <Hr />
          <ContentTwo>
            <DateComponent>
              <Message>Data de encerramento</Message>
              <ClosingDate
                value={[startDate, finishDate]}
                onChange={dates => {
                  const [inicialDate, finalDate] = dates;

                  setValue('start_date', inicialDate);
                  setValue('finish_date', finalDate);
                }}
              />
              {errors.start_date && (
                <SpanError>{errors.start_date.message}</SpanError>
              )}
            </DateComponent>
            <CheckboxSession>
              <SessionTitle>Métodos de candidatura interna</SessionTitle>
              <Checkbox
                onClick={() => setRemoverValor(!removerValor)}
                checked={removerValor}
                sx={{
                  color: pink[800],
                  '&.Mui-checked': {
                    color: pink[600],
                  },
                }}
              />

              <span>Padrão</span>
            </CheckboxSession>
            <SessionTitle>Outros métodos</SessionTitle>
            <OtherMethods
              style={{
                opacity: removerValor === true ? '0.5' : '1',
              }}
            >
              <IconInputField>
                <Label htmlFor="externalLink">Link externo de inscrição</Label>
                <IconInputContainer>
                  <IconImage src={Link} />
                  <IconInput
                    disabled={removerValor}
                    id="externalLink"
                    {...register('link_external')}
                  />
                </IconInputContainer>
                {errors.link_external && (
                  <SpanError>{errors.link_external.message}</SpanError>
                )}
              </IconInputField>
              <IconInputField>
                <Label htmlFor="emailToSend">
                  E-Mail para envio de currículo
                </Label>
                <IconInputContainer>
                  <IconImage src={Email} />
                  <IconInput
                    id="emailToSend"
                    disabled={removerValor}
                    type="text"
                    {...register('link_email')}
                  />
                </IconInputContainer>
                {errors.link_email && (
                  <SpanError id="information">
                    {errors.link_email.message}
                  </SpanError>
                )}
              </IconInputField>
              <IconInputField
                style={{
                  maxWidth: '18.5rem',
                }}
              >
                <Label htmlFor="whatsappLink">
                  Número de inscrição por WhatsApp
                </Label>
                <IconInputContainer>
                  <IconImage src={WhatsApp} />
                  <IconInput
                    maxLength={15}
                    disabled={removerValor}
                    type="text"
                    id="whatsappLink"
                    {...register('link_whatsapp', {
                      onChange: e => {
                        e.target.value = maskPhone(e.target.value);
                      },
                    })}
                  />
                </IconInputContainer>
                {errors.link_whatsapp && (
                  <SpanError>{errors.link_whatsapp.message}</SpanError>
                )}
              </IconInputField>
            </OtherMethods>
            <MessagesArea>
              <div>
                <div>
                  <Message>Mensagem de encerramento </Message>
                </div>
                <InputArea>
                  <Textarea
                    className="largeInput"
                    {...register('finish_message')}
                    placeholder={defaultNotSelectedMessage()}
                  />
                </InputArea>
                {errors.finish_message && (
                  <SpanError>{errors.finish_message.message}</SpanError>
                )}
              </div>
              <div id="selecionados">
                <div>
                  <Message>Mensagem para selecionados</Message>
                </div>
                <InputArea>
                  <Textarea
                    className="largeInput"
                    {...register('selected_message')}
                    placeholder={defaultSelectedMessage(title)}
                  />
                </InputArea>
                {errors.selected_message && (
                  <SpanError>{errors.selected_message.message}</SpanError>
                )}
              </div>
            </MessagesArea>
            <ContainerButton>
              <ButtonEdit type="submit">Salvar</ButtonEdit>
            </ContainerButton>
          </ContentTwo>
        </Form>
      </MainArea>
    </Container>
  );
};
