import * as Yup from 'yup';

export type CreateModeratorForm = Yup.InferType<typeof CreateModeratorSchema>;

export const CreateModeratorSchema = Yup.object({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  password: Yup.string().required('Senha é obrigatória'),
  confirm_password: Yup.string()
    .required('Confirmação de senha é obrigatória')
    .oneOf([Yup.ref('password'), null], 'As senhas não conferem'),
});

export type UpdateModeratorForm = Yup.InferType<typeof UpdateModeratorSchema>;

export const UpdateModeratorSchema = Yup.object({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  password: Yup.string().optional(),
  confirm_password: Yup.string().optional(),
}).shape(
  {
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    password: Yup.string().when('confirm_password', confirmPassword => {
      if (confirmPassword) {
        return Yup.string().required('Senha é obrigatória');
      }

      return Yup.string().optional();
    }),
    confirm_password: Yup.string().when('password', password => {
      if (password) {
        return Yup.string()
          .required('Confirmação de senha é obrigatória')
          .oneOf([Yup.ref('password'), null], 'As senhas não conferem');
      }

      return Yup.string().optional();
    }),
  },
  [['password', 'confirm_password']],
);
