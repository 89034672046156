import { useState } from 'react';
import { Dropdown } from './Dropdown';
import { Container } from './ProfileStyles';

export const Profile = () => {
  return (
    <Container>
      <Dropdown />
    </Container>
  );
};
