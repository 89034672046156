import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { Navbar } from '../../components/Navbar/Navbar';
import { Profile } from '../../components/Profile/Profile';
import Toggle from '../../components/Toggle/Toggle';
import api from '../../services/api';
import handleError, { messageSuccess } from '../../utils/message';
import {
  UpdateModeratorForm,
  UpdateModeratorSchema,
} from '../../validators/ModeratorSchema';
import {
  Container,
  Form,
  FormRow,
  Header,
  MainArea,
  PermissionItem,
  PermissionName,
  PermissionsList,
  PermissionsSection,
  SectionTitle,
  Title,
} from './_editarModerador';
import { permissionOptions } from '../../types/ModeratorPermissions';
import { User } from '../../hooks/useAuth';

const EditarModerador = () => {
  const [enabledPermissions, setEnabledPermissions] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UpdateModeratorForm>({
    resolver: yupResolver(UpdateModeratorSchema),
  });

  const getModeratorById = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get<User>(`/user/moderator/${id}`);

      reset({
        email: data.email,
        name: data.username,
      });
      setEnabledPermissions(data?.moderator?.permission || []);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getModeratorById();
  }, [id]);

  const onSubmit: SubmitHandler<UpdateModeratorForm> = async form => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();

      formData.append('username', form.name);
      formData.append('email', form.email);
      if (form.password && form.confirm_password) {
        formData.append('password', form.password);
        formData.append('password_confirm', form.confirm_password);
      }
      formData.append('terms_accepted', 'true');

      if (enabledPermissions.length > 0) {
        enabledPermissions.forEach((permission, index) => {
          formData.append(
            `moderator_permissions[${index}]`,
            String(permission),
          );
        });
      }

      await api.put(`/user/moderator/${id}`, formData);

      messageSuccess('Moderador alterado com sucesso!');
      navigate(-1);
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Navbar />
      <MainArea>
        <Header>
          <Title>Editar Moderador</Title>
          <Profile />
        </Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <Input
              id="name"
              label="Nome"
              placeholder="Insira o nome"
              error={errors?.email?.message}
              {...register('name')}
            />
            <Input
              id="email"
              label="E-mail"
              placeholder="Insira o e-mail"
              error={errors?.email?.message}
              {...register('email')}
            />
          </FormRow>
          <FormRow>
            <Input
              id="password"
              type="password"
              label="Senha"
              placeholder="Insira a senha"
              autoComplete="new-password"
              error={errors?.password?.message}
              {...register('password')}
            />
            <Input
              id="confirm_password"
              type="password"
              label="Confirme a senha"
              placeholder="Confirme a senha"
              autoComplete="new-password"
              error={errors?.confirm_password?.message}
              {...register('confirm_password')}
            />
          </FormRow>

          <PermissionsSection>
            <SectionTitle>Permissões</SectionTitle>
            <PermissionsList>
              {permissionOptions.map(option => (
                <PermissionItem key={option.value}>
                  <PermissionName>{option.label}</PermissionName>
                  <Toggle
                    checked={enabledPermissions.includes(option.value)}
                    onChange={e => {
                      if (e.target.checked) {
                        setEnabledPermissions(prev => [...prev, option.value]);
                      } else {
                        setEnabledPermissions(prev =>
                          prev.filter(value => value !== option.value),
                        );
                      }
                    }}
                  />
                </PermissionItem>
              ))}
            </PermissionsList>
          </PermissionsSection>

          <Button
            style={{
              marginLeft: 'auto',
              marginTop: '2rem',
              width: '18rem',
            }}
            disabled={isSubmitting}
          >
            Salvar alterações
          </Button>
        </Form>
      </MainArea>
    </Container>
  );
};

export default EditarModerador;
