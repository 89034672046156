/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { BiSearchAlt2 } from 'react-icons/bi';

import { Navbar } from '../../components/Navbar/Navbar';
import { Profile } from '../../components/Profile/Profile';

import { messageError } from '../../utils/message';
import api from '../../services/api';

import {
  Container,
  MainArea,
  Header,
  ContainerSearch,
  ButtonSearch,
  SearchBar,
  ContainerTables,
  Tr,
  Th,
  Td,
  TdNivel,
  Title,
  ButtonDownload,
  SectionTable,
} from './InicioStyles';

import { IJob, Home } from '../../types/Job';
import { IAplication } from '../../types/Aplication';

export const Inicio = () => {
  const [buscar, setBuscar] = useState('');
  const [applications, setApplications] = useState<IAplication[]>([]);
  const [jobs, setJobs] = useState<IJob[]>([]);

  const getJobsAndApplications = async () => {
    try {
      const { data } = await api.get<Home>(`/job/home`, {
        params: {
          search: buscar || undefined,
        },
      });

      setApplications(data.application.results);
      setJobs(data.jobs.results);
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getJobsAndApplications();
  }, [buscar]);

  return (
    <Container>
      <Navbar />
      <MainArea>
        <Profile />
        <Header>
          <ContainerSearch>
            <ButtonSearch>
              <BiSearchAlt2 fontSize={25} color="#DCDCDC" />
            </ButtonSearch>
            <SearchBar
              type="text"
              placeholder="Buscar"
              value={buscar}
              onChange={e => {
                setBuscar(e.target.value);
              }}
            />
          </ContainerSearch>
        </Header>

        <Title>Painel de candidato</Title>
        <SectionTable>
          <ContainerTables>
            <Tr>
              <Th>ID</Th>
              <Th>Nome</Th>
              <Th>Currículo</Th>
              <Th>Nível</Th>
            </Tr>
            {applications.map((application, index) => (
              <Tr>
                <Td>{`#${index + 1}`}</Td>
                <Td>{application.person.name || ''}</Td>
                <Td>
                  {application.person?.resume_url && (
                    <ButtonDownload
                      href={application.person?.resume_url || ''}
                      target="_blank"
                      download
                    >
                      Baixar
                    </ButtonDownload>
                  )}
                </Td>
                <TdNivel>100%</TdNivel>
              </Tr>
            ))}
          </ContainerTables>
        </SectionTable>

        <Title>Painel público de vagas</Title>
        <SectionTable>
          <ContainerTables>
            <Tr>
              <Th>ID</Th>
              <Th>Vaga</Th>
              <Th>Candidaturas</Th>
            </Tr>
            {jobs.map((itens: any, index) => (
              <Tr>
                <Td>{`#${index + 1}`}</Td>
                <Td>{itens.title || ''}</Td>
                <Td>{itens.application_count || 0}</Td>
              </Tr>
            ))}
          </ContainerTables>
        </SectionTable>
      </MainArea>
    </Container>
  );
};
