/* eslint-disable jsx-a11y/label-has-associated-control */

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Esqueceu from '../../assets/EsqueceuSenha/Esqueceu.svg';
import api from '../../services/api';
import { RecoverPasswordData } from '../../types/RecoverPassword';
import { messageError } from '../../utils/message';
import { ForgotPasswordSchema } from '../../validators/ForgotPasswordSchema';
import {
  Imagem,
  Container,
  Form,
  Button,
  Input,
  Texto,
  Section,
  ErrorSpan,
} from './esqueceuSenhaStyles';

export const EsqueceuSenha = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<RecoverPasswordData>({
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const navigate = useNavigate();
  // Submit
  const onSubimitHandler: SubmitHandler<RecoverPasswordData> = async data => {
    try {
      await api.post('/user/password/forgot', {
        ...data,
        sendPassword: true,
      });
      toast.success('Email enviado com sucesso!');
      navigate('/Login');
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <Container>
      <Imagem>
        <img
          id="esqueceuImg"
          src={Esqueceu}
          alt="mulher sorrindo tomando café"
        />
      </Imagem>
      <Form onSubmit={handleSubmit(onSubimitHandler)}>
        <Section>
          <Texto>
            <p id="titulo">Esqueceu sua senha ?</p>
            <label id="texto">Insira seu e-mail já cadastrado</label>
          </Texto>
          <Input>
            <input
              type="email"
              id="email"
              placeholder="E-mail"
              {...register('email')}
            />
            {errors.email && <ErrorSpan>{errors.email.message}</ErrorSpan>}
          </Input>
        </Section>
        <Button>
          <button type="submit" disabled={!isDirty}>
            Enviar
          </button>
        </Button>
      </Form>
    </Container>
  );
};
