import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;

  background: #fbfbfb;
`;

export const MainArea = styled.div`
  width: 100%;
  flex: 1;

  padding: 0 2rem;

  display: flex;
  flex-direction: column;

  height: 100vh;
`;

export const Header = styled.header`
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.625rem;
`;

export const Title = styled.div`
  margin-top: 2rem;

  white-space: nowrap;

  font-size: 1.5rem;
  font-weight: 600;
  color: var(--black);
`;

export const Form = styled.form`
  flex: 1;

  padding: 2rem 0;
  border-radius: 8px;

  // max-width: 56.25rem;
  width: 95%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

export const PermissionsSection = styled.section`
  margin-top: 1.5rem;
  padding-top: 1.75rem;

  border-top: 1px solid #bababa;

  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const SectionTitle = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  white-space: nowrap;
  color: var(--black);
`;

export const PermissionsList = styled.ul`
  list-style: none;

  display: flex;
  flex-direction: column;

  margin-top: 1rem;
  overflow: auto;

  padding-right: 1rem;

  &::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--pink);
    border-radius: 10px;
    height: 120px;
  }
`;

export const PermissionItem = styled.li`
  width: 100%;

  padding: 1rem 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #dcdcdc;
`;

export const PermissionName = styled.p``;
