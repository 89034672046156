export const maskCnpj = (value: string) => {
  if (!value) return '';

  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const maskPhone = (value?: string) => {
  if (!value) return '';

  const onlyDigits = value.startsWith('55')
    ? value.replace(/\D/g, '').substring(2)
    : value.replace(/\D/g, '');

  if (onlyDigits.length <= 9) {
    return onlyDigits;
  }

  if (onlyDigits.length <= 10) {
    return onlyDigits
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }

  return onlyDigits
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2');
};

export const maskCep = (value?: string) => {
  if (!value) return '';

  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};
