import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { CadastroVagas } from '../pages/CadastroVagas/CadastroVagas';
import { EsqueceuSenha } from '../pages/EsqueceuSenha/EsqueceuSenha';
import { Inicio } from '../pages/Inicio/Inicio';
import { Login } from '../pages/Login/Login';
import { Candidaturas } from '../pages/Vagas/components/Candidaturas/Candidaturas';
import { VagasCadastradas } from '../pages/Vagas/components/VagasCadastradas/VagasCadastradas';
import { VisualizarVagas } from '../pages/Vagas/components/VagasCadastradas/components/VisualizarVagas/VisualizarVagas';
import { Selecionados } from '../pages/Vagas/components/Selecionados/Selecionados';
import { VisualizarSelecionados } from '../pages/Vagas/components/Selecionados/components/VisualizarSelecionados';
import { EditarPerfil } from '../pages/EditarPerfil/EditarPerfil';
import { EditarJobs } from '../pages/CadastroVagas/EditJobs/EditarJobs';
import { useAuth } from '../hooks/useAuth';
import PrivateRoute from './PrivateRoutes';
import { Signup } from '../pages/Signup/Signup';
import { ConfirmSignUp } from '../pages/ConfirmSignUp/ConfirmSignUp';
import Similares from '../pages/Similares/Similares';
import SimilaresEdit from '../pages/SimilaresEdit/SimilaresEdit';
import Moderadores from '../pages/Moderadores/Moderadores';
import CadastroModerador from '../pages/CadastroModerador/CadastroModerador';
import EditarModerador from '../pages/EditarModerador/EditarModerador';
import { EditarPerfilModerador } from '../pages/EditarPerfilModerador/EditarPerfilModerador';

export const NavigateRoutes = () => {
  const { isAuthenticated, user } = useAuth();

  const { role } = user;

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated() ? (
              <Navigate to={role === 2 ? '/Inicio' : '/VagasCadastradas'} />
            ) : (
              <Login />
            )
          }
        />
        <Route path="/Login" element={<Login />} />
        <Route path="/EsqueceuSenha" element={<EsqueceuSenha />} />
        <Route path="/Cadastro" element={<Signup />} />
        <Route path="/confirmarcadastro" element={<ConfirmSignUp />} />

        <Route element={<PrivateRoute />}>
          <Route path="/Inicio" element={<Inicio />} />
          <Route path="/CadastroVagas" element={<CadastroVagas />} />
          <Route path="/Candidaturas" element={<Candidaturas />} />
          <Route path="/VagasCadastradas" element={<VagasCadastradas />} />
          <Route
            path="/VagasCadastradas/VisualizarVagas/:id"
            element={<VisualizarVagas />}
          />
          <Route path="/Selecionados" element={<Selecionados />} />
          <Route
            path="/Selecionados/VisualizarSelecionados/:id"
            element={<VisualizarSelecionados />}
          />
          <Route
            path="/EditarPerfil"
            element={role === 2 ? <EditarPerfil /> : <EditarPerfilModerador />}
          />
          <Route path="/EditarJobs/:id" element={<EditarJobs />} />
          <Route path="/Similares" element={<Similares />} />
          <Route path="/Similares/:id" element={<SimilaresEdit />} />
          <Route path="/Moderadores" element={<Moderadores />} />
          <Route
            path="/Moderadores/Cadastrar"
            element={<CadastroModerador />}
          />
          <Route path="/Moderadores/Editar/:id" element={<EditarModerador />} />
          <Route path="/Moderadores/:id" element={<Moderadores />} />
        </Route>
      </Routes>
    </Router>
  );
};
