import React, { useState } from 'react';
import UnopDropdown, { DropDowndirections } from 'unop-react-dropdown';
import DatePiker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import {
  CalenderIcon,
  DateComponent,
  CalendarContainer,
} from '../FormFinalização/FormFinalizao';

type prop = {
  onChange: (dates: Array<Date | null>) => void;
  value: Array<Date | null>;
};
registerLocale('pt-BR', ptBR);

export const ClosingDate = ({ onChange, value }: prop) => {
  const [open, setOpen] = useState(false);
  const handleChange = (dates: Array<Date>) => {
    const [start, end] = dates;
    onChange(dates);
  };
  const handler = () => {
    setOpen(!open);
    console.log(open);
  };
  const salvar = () => {
    return true;
  };

  return (
    <UnopDropdown
      onAppear={handler}
      onDisappearStart={handler}
      closeOnClickOut
      // closeOnDropdownClicked
      align={DropDowndirections.LEFT}
      trigger={
        <DateComponent>
          <div className="endDateComponent">
            <div>
              <span>
                <CalenderIcon
                  style={{
                    position: 'relative',
                    top: '2px',
                  }}
                />
                {value[0] ? value[0].toLocaleDateString() : 'Começar'}
              </span>
            </div>
            |
            <div>
              <span>
                {value[1] ? value[1].toLocaleDateString() : 'Terminar'}
              </span>
            </div>
          </div>
        </DateComponent>
      }
      delay={300}
    >
      <div
        className={`AnimatedDropdownStyles openAnimation${
          !open ? ' closeAnimation' : ''
        }`}
      >
        <CalendarContainer>
          <DatePiker
            locale="pt-BR"
            wrapperClassName="styleDatePicker"
            onChange={onChange}
            startDate={value[0]}
            endDate={value[1]}
            selectsRange
            popperPlacement="left-end"
            inline
          />
          {/* <div className="alignment">
            <button type="button" onClick={salvar}>
              salvar
            </button>
          </div> */}
        </CalendarContainer>
      </div>
    </UnopDropdown>
  );
};
