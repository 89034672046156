import Select from 'react-select';
import Creatable from 'react-select/creatable';
import styled from 'styled-components';

import { ReactComponent as ArrowLeft } from '../../../../assets/Cadastro/arrowIcon.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/CadastroVendas/arrowRight.svg';

export const Form = styled.form`
  background: #ffffff;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const SpanError = styled.span`
  font-size: 0.8rem;
  display: block;
  color: var(--pink);
  font-weight: normal;
`;

export const Title = styled.div`
  font-weight: 600;
  margin-bottom: 5px;
  span {
    color: var(--pink);
  }
`;

export const ArrowIconLeft = styled(ArrowLeft)`
  width: auto;
  height: auto;
`;

export const ArrowIconRight = styled(ArrowRight)`
  width: auto;
  height: auto;
`;

export const Input = styled.input`
  height: 40px;
  width: 230px;
  font-size: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  opacity: 1;

  text-indent: 9px;

  & + & {
    margin-top: 0.5rem;
  }
  outline: none;

  &:focus {
    border-color: var(--pink);
    box-shadow: var(--pink);
  }
`;

export const Section = styled.div`
  margin-top: 1.5rem;
  margin-left: 2rem;

  .multi-select {
    width: 380px;
  }

  .basic-select {
    width: 230px;
  }

  .hard-select {
    width: 490px;
  }

  #salario {
    width: 70px;
  }

  #descricao {
  }

  #caracatere {
    display: flex;
    justify-content: flex-end;

    font-size: 0.7rem;
    font-weight: 500;
    color: #1a1a1a4d;
  }
`;

export const SectionWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const customStyles = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,

    color: state.isSelected ? 'white' : 'black',
    background: state.isSelected ? '#FD1C50D1' : 'white',

    ':hover': {
      backgroundColor: '#fd1c5033',
    },
  }),
};

export const SelectBasic = styled(Select)`
  .react-select__menu-list {
    font-size: 14px;

    &::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      background: #e9e9e9;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--pink);
      border-radius: 10px;
      height: 120px;
    }
  }

  .react-select__control {
    min-height: 40px;
    border-radius: 8px;

    color: var(--lotion);
    border: 1px solid #dcdcdc;
    box-shadow: none;
  }

  .react-select,
  .react-select__control--is-focused.react-select__control--menu-is-open {
    border-color: var(--pink);
    box-shadow: var(--pink);
  }
`;

export const SelectCreate = styled(Creatable)`
  .react-select__menu-list {
    font-size: 14px;

    &::-webkit-scrollbar {
      width: 2px;
      height: 120px;
    }

    ::-webkit-scrollbar-track {
      background: #e9e9e9;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--pink);
      border-radius: 10px;
    }
  }

  .react-select__control {
    border-radius: 8px;
    min-height: 40px;
    color: var(--lotion);
    border: 1px solid #dcdcdc;
    box-shadow: none;
  }

  .react-select,
  .react-select__control--is-focused.react-select__control--menu-is-open {
    border-color: var(--pink);
    box-shadow: var(--pink);
  }
  .react-select__multi-value {
    background-color: #fd1c5033;
    border-radius: 8px;
  }

  .react-select__multi-value__remove {
    color: var(--black);
    &:focus {
      background-color: transparent;
    }
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: flex-end;

  padding-top: 0.8rem;

  background: #fbfbfb;

  button {
    width: 147px;
    height: 48px;

    display: flex;
    align-items: center;

    justify-content: center;

    background-color: #ffffff;

    border: 1px solid #dcdcdc;
    border-radius: 8px;

    & + button {
      margin-left: 1rem;
    }
  }
  #voltar {
    padding-left: 16px;

    font-size: 1rem;
    font-weight: 600;
    color: var(--medium-gray);
  }
  #proximo {
    padding-right: 16px;

    font-size: 1rem;
    font-weight: 600;
    color: var(--medium-gray);
  }
`;
export const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

export const SelectEstado = styled.select`
  height: 40px;
  width: 170px;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  color: var(--medium-gray);
  padding: 5px;
  font-size: 1rem;
`;

export const SelectUf = styled.select`
  height: 40px;
  font-size: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  opacity: 1;
  margin-top: 45px;
  margin-left: 30px;
  background-color: transparent;
  color: var(--medium-gray);

  option {
    background-color: #fff;
  }
`;

export const SelectCity = styled.select`
  height: 40px;
  font-size: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  opacity: 1;
  margin-top: 45px;
  margin-left: 30px;
  background-color: transparent;
  color: var(--medium-gray);

  option {
    background-color: #fff;
  }
`;

export const Textarea = styled.textarea`
  width: clamp(200px, 50vw, 400px);
  min-height: 113px;

  border: 1px solid #dcdcdc;
  border-radius: 8px;

  opacity: 1;
  padding: 10px;

  font-size: 0.8rem;

  outline: none;
`;
