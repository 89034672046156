import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  inset: 0;

  z-index: 2;

  background: #20202077;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 50rem;

  padding: 2rem;

  min-height: 70vh;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;

  background: #fff;
  border-radius: 1.5rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h3``;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;

  border-radius: 0px;

  color: #1a1a1a;
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PermissionsList = styled.ul`
  list-style: none;
`;

export const PermissionsItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem 0;
  border-bottom: 1px solid #cacaca;
`;

export const PermissionName = styled.p``;

interface LabelProps {
  isActive: boolean;
}

export const ToggleLabel = styled.label<LabelProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: ${({ isActive }) => (isActive ? 'var(--pink)' : '#1a1a1a')};
`;

export const PermissionToggle = styled.input`
  appearance: none;
  width: 2.375rem;
  height: 1.375rem;
  background: #cfcfcf;
  border-radius: 2rem;

  cursor: pointer;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);

  &:after {
    content: '';
    width: 1rem;
    height: 1rem;
    margin: 3px;

    display: inline-block;

    margin-left: 0.25rem;

    border-radius: 50%;

    background-color: #fafafa;
    border: none;

    transition: transform 0.2s ease;
  }

  &:checked {
    background-color: var(--pink);

    &:after {
      width: 1rem;
      height: 1rem;
      background-color: #fafafa;
      transform: translateX(95%);
    }
  }
`;

export const SaveButton = styled.button`
  border: none;
  background: var(--pink);

  margin: 0 auto;
  margin-top: auto;

  height: 3rem;
  width: 100%;
  max-width: 32rem;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
