import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: #fbfbfb;
  color: var(--black);
`;

export const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  flex: 1;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
`;

export const TitleSection = styled.p`
  color: #707070;
  font-size: 1rem;

  margin-bottom: 1.25rem;

  @media screen and (max-width: 1000px) {
    text-align: center;
    margin: 20px;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 70rem;

  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  padding: 5px 20px;

  @media screen and (max-width: 800px) {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
`;

export const ContainerInput = styled.div``;

export const SectionName = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.5rem;
  text-indent: 0;
  font-size: 1rem;
`;

export const Input = styled.input`
  height: 2.5rem;
  width: 18.75rem;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  opacity: 1;
  text-indent: 0.5rem;
`;

export const Name = styled.p`
  padding: 0 0.25rem;
`;

export const NameEmail = styled.p`
  padding: 0 0.25rem;
  width: 18.75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ContainerImage = styled.div`
  margin: 0 auto;
  margin-bottom: auto;

  display: flex;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const ImageLabel = styled.label``;

export const SectionFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  font-size: 1rem;

  @media screen and (max-width: 800px) {
    justify-content: center;
  }
`;

export const ImageUser = styled.img`
  width: 8.125rem;
  height: 8.125rem;
  border-radius: 5.625rem;
  object-fit: cover;
`;

export const ImageInput = styled.input`
  visibility: hidden;
  display: none;
`;

export const TypeImage = styled.p`
  font-size: 0.75rem;
`;

export const ContainerRight = styled.div`
  margin-bottom: 20px;
`;

export const ButtonAlterar = styled.button`
  color: #fd1c50;
  background-color: #dcdcdc;
  width: 80px;
  height: 40px;
  margin-top: 20px;
  transition: 200ms;

  :hover {
    transition: 200ms;
    background-color: #cacaca;
  }
`;

export const ContainerPassword = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px 20px;

  @media screen and (max-width: 1000px) {
    justify-content: center;
  }
`;

export const Title = styled.p`
  // margin: 1.25rem 0;
  color: #707070;
  font-size: 1rem;

  @media screen and (max-width: 1000px) {
    text-align: center;
  }
`;

// ***Button Label for change Image Use***
export const Label = styled.label`
  background-color: #dcdcdcaa;
  width: 5.875rem;
  height: 2.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 0.9375rem;
  font-weight: 500;
  color: #fd1c50;
  transition: 200ms;

  :hover {
    transition: 200ms;
    background-color: #cacaca;
  }
`;

export const ContainerSave = styled.div`
  display: flex;
  justify-content: end;
`;

export const ButtonSave = styled.button`
  background-color: #fd1c50;
  color: #fff;
  border-radius: 5px;
  width: 120px;
  height: 50px;
`;

export const ErrorText = styled.span`
  display: block;
  color: #fd1c50;
  font-size: 0.7rem;
`;

export const Required = styled.span`
  color: #fd1c50;
`;

export const Icon = styled.span`
  position: absolute;
  top: 2.625rem;
  right: 0.625rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerAbsolute = styled.div`
  position: relative;
`;

export const ContainerMain = styled.div``;

export const ContainerMoreValue = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 5px 20px;

  @media screen and (max-width: 1000px) {
    justify-content: center;
  }
`;

export const InputTextArea = styled.textarea`
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  opacity: 1;
  padding: 0.5rem 0.75rem;
  height: 100%;
  min-height: 7rem;
  width: 100%;
  resize: none;
`;

export const TextArea = styled.div`
  width: 65%;
`;

export const FormSection = styled.div`
  padding: 0.3125rem 1.25rem;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;

  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Field = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const FieldLabel = styled.label`
  font-size: 1rem;
  margin-left: 0.25rem;
`;

export const FieldInput = styled.input`
  width: 100%;
  height: 2.5rem;
  border: 1px solid #dcdcdc;
  border-radius: 0.5rem;
  text-indent: 0.5rem;
`;

export const FieldIconWrapper = styled.div`
  position: absolute;
  top: 2.375rem;
  right: 1rem;
`;

export const SectionColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
