import styled from 'styled-components';

export const ContainerModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(73, 80, 87, 0.7);
  /* Sobrepor o modal */
  z-index: 1;
`;

export const ModalStyle = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 35px;
  font-family: var(--font-rubik);
  width: 500px;
`;

export const Message = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 30px;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
`;

export const ButtonCancel = styled.button`
  width: 120px;
  margin-right: 10px;
  background-color: #fff;
  color: var(--green-main);
  border: 1px solid #707070;
  border-radius: 5px;
  transition: 300ms;

  :hover {
    background-color: #e7e7e7;
    transition: 300ms;
  }
`;

export const ButtonCreate = styled.button`
  width: 120px;
  height: 30px;
  background-color: #fd1c50;
  border: none;
  border-radius: 5px;
  color: #fff;
  transition: 300ms;

  :hover {
    transition: 300ms;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 10rem;
  resize: none;
`;
