import styled from 'styled-components';

import { ReactComponent as SearchIconRaw } from '../../assets/Inicio/searchIcon.svg';

export const Container = styled.div`
  position: relative;
  border-radius: 5px;
  padding: 0 0.75rem;

  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;

  background: #ffffff;
  border: 1px solid #dcdcdc;

  &:focus-within {
    outline: none;
    border-color: #acacac;
  }
`;

export const Input = styled.input`
  border: none;
  background: transparent;

  font-family: Noto Sans, sans-serif;

  height: 2.5rem;

  width: 95%;

  /* &::placeholder {
    color: #1a1a1a80;
  } */

  &:focus {
    outline: none;
  }
`;

export const SearchIcon = styled(SearchIconRaw)`
  width: 1rem;
  height: 1rem;

  fill: #1a1a1a19;
`;
